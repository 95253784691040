import { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import { Step } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';

import type { FC } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import type { MathOps } from 'Models/index.ts';
import type { MinMax } from 'Utils/ranged.ts';

export type Params = {
  inputMin?: (templ: TemplateFunction<void>) => JSX.Element;
  inputMax?: (templ: TemplateFunction<void>) => JSX.Element;
  setCustomRange: () => void;
};

type Props = {
  template: TemplateFunction<Params>;
  bounds?: MinMax;
  step?: Step;
  selectedValues?: MinMax;
  mode?: string;
  handleCustomRange: (selection: MinMax) => void;
};

const inputErrorClass = 'cm_input-error';

const RangedInputs: FC<Props> = ({
  template,
  bounds: [min, max] = ['0', '*'],
  step: rawStep,
  selectedValues,
  mode,
  handleCustomRange,
}) => {
  const inputsRef = useRef<[HTMLInputElement | null, HTMLInputElement | null]>([null, null]);
  const step = useMemo(() => new Step(rawStep), [rawStep]);

  const [currentBounds, setCurrentBounds] = useState<MinMax>(['', '']);
  const [errorIndicator, setErrorIndicator] = useState<boolean>(false);

  useEffect(() => {
    if (selectedValues) {
      inputsRef.current.forEach((input) => input?.classList.remove(inputErrorClass));
      const cleanSelection = selectedValues.map((sel) => (sel === '*' ? '' : sel)) as MinMax;
      setCurrentBounds(cleanSelection);
    }
  }, [selectedValues]);

  if (min === max && max !== '*') {
    return null;
  }

  const setCustomRange = () => {
    if (errorIndicator) {
      return;
    }

    const rawSelection = currentBounds.map((bound, i) => {
      if (bound === '' || (i === 0 && min === '*') || (i === 1 && max === '*')) {
        return bound || '*';
      }
      return +bound < +min ? min : +bound > +max ? max : bound;
    }) as MinMax;

    const selection = inputsRef.current.map((input, i) =>
      input && input.step && rawSelection[i] !== '*'
        ? step.fit(+rawSelection[i], ['floor', 'ceil'][i] as MathOps)
        : rawSelection[i],
    ) as MinMax;

    handleCustomRange(selection);
  };

  const onInputChange = (inputValue: string, changingBoundIndex: number) => {
    const newBounds = [...currentBounds] as MinMax;
    newBounds[changingBoundIndex] = inputValue;
    setCurrentBounds(newBounds);

    inputsRef.current[0]?.classList.toggle(inputErrorClass, newBounds[0] !== '' && isNaN(+newBounds[0]));
    inputsRef.current[1]?.classList.toggle(inputErrorClass, newBounds[1] !== '' && isNaN(+newBounds[1]));

    const isChagingBoundValid = !newBounds.includes('') && +newBounds[0] > +newBounds[1];
    inputsRef.current[changingBoundIndex]?.classList.toggle(inputErrorClass, isChagingBoundValid);
    setErrorIndicator(isChagingBoundValid);
  };
  const [inputMin, inputMax] = ['min', 'max'].map((bound, i) => (templ: TemplateFunction<void>) => {
    const inputProps = templ()?.props;
    const props = {
      placeholder: [min, max][i],
      ...inputProps,
      type: 'number',
      value: currentBounds[i],
      step: step.numeric,
      min,
      max,
      onChange: ({ target }) => onInputChange(target.value, i),
      ['data-cm-role']: `input-${bound}`,
      key: `input-${bound}`,
      ref: (el: HTMLInputElement | null) => (inputsRef.current[i] = el),
    };

    return <input {...props} />;
  });

  const onKeyDown: KeyboardEventHandler<HTMLElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      setCustomRange();
    }
  };

  if (mode === 'LowerBound') {
    return cloneSafe(template.call({ inputMin, setCustomRange }), null, { onKeyDown });
  }

  if (mode === 'UpperBound') {
    return cloneSafe(template.call({ inputMax, setCustomRange }), null, { onKeyDown });
  }

  return cloneSafe(template.call({ inputMin, inputMax, setCustomRange }), null, { onKeyDown });
};

export default RangedInputs;
