import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createSubFacetsSelector } from 'Core/selectors/search.js';
import { Facet } from 'Models/index.ts';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import RangedFacet from './rangedFacet.tsx';

const {
  configs: { sizeFacet },
} = facetsConfig;

export default function SizeFacet({
  facet: { field },
  config: { slider = {}, step = sizeFacet.step },
  templateFunc,
  facetRef,
  commonParams,
  commonRoles,
}) {
  const subFacetsSelector = useMemo(() => createSubFacetsSelector(field), [field]);
  const subFacets = useSelector(subFacetsSelector);

  if (!subFacets.some((f) => f instanceof Facet)) {
    return null;
  }

  const onClick = simpleHandler(commonRoles);

  const subFacetComponents = subFacets.reduce((map, subFacet) => {
    const { field: subField } = subFacet;
    const SubFacetFunc =
      subFacet instanceof Facet
        ? (templ) => {
            const props = {
              facet: subFacet,
              field: subField,
              allowEqualBounds: true,
              templateFunc: templ,
              facetRef: null,
              key: subField,
              commonParams: { ...subFacet },
              commonRoles: {},
              config: { step, slider: slider[subField] },
            };
            return <RangedFacet {...props} />;
          }
        : () => null;

    return { ...map, [`${subField}Facet`]: SubFacetFunc };
  }, {});

  const component = templateFunc.call({
    ...commonParams,
    ...subFacetComponents,
  });
  return cloneSafe(component, facetRef, { onClick });
}
