import { FC, FormEventHandler, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dialogName as searchBoxDialogName } from 'Components/dialog/searchBoxDialog.tsx';
import { clearAutocompleteQuery, sendAutocompleteRequest } from 'Core/actions/autocomplete.ts';
import { setQueriesHistory } from 'Core/actions/localStorage.js';
import { replaceRequest } from 'Core/actions/request.js';
import { useDropdown } from 'Core/hooks/index.js';
import {
  autocompleteDefaultSuggetionsSelector,
  autocompleteRequestQuerySelector,
  autocompleteResponseItemsSelector,
} from 'Core/selectors/autocomplete.ts';
import { autocompletePreselectionSelector } from 'Core/selectors/preselection.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { cloneSafe, preventFormSubmission } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import SearchDropdown, { Params as SearchDropdownParams } from './searchDropdown.tsx';
import SearchInput, { Params as SearchInputParams } from './searchInput.tsx';

import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import type { FacetValueBase } from 'Models/index.ts';

// this class is meant to be used as common root class for all SearchBox widgets
const rootClass = 'cm_search-box-root';

type ExtraParams = Record<string, unknown>;

export type Params = {
  searchInput: TemplateFunctionInvoker<SearchInputParams>;
  dropdown: TemplateFunctionInvoker<SearchDropdownParams>;
  preventFormSubmission: FormEventHandler<'form'>;
  inputNotEmpty: boolean;
  clearInput: () => void;
} & ExtraParams;

export type Props = {
  template: TemplateFunction<Params>;
  name: string;
  disableDropdown?: true;
  redirectUrl?: string;
  disableAutoRequest?: boolean;
  onSubmit?: () => void;
  onInputFocus?: () => void;
  onDropdownItemsReceived?: () => void;
  extraParams?: ExtraParams;
};

const SearchBox: FC<Props> = ({
  template,
  name,
  disableDropdown,
  redirectUrl,
  disableAutoRequest,
  onSubmit,
  onInputFocus,
  onDropdownItemsReceived,
  extraParams,
}) => {
  const dispatch = useDispatch();
  const rootRef = useRef<HTMLElement>(null);

  const autocompleteResponse = useSelector(autocompleteResponseItemsSelector);
  const autocompleteQuery = useSelector(autocompleteRequestQuerySelector);
  const defaultSuggestion = useSelector(autocompleteDefaultSuggetionsSelector);
  const preselection = useSelector(autocompletePreselectionSelector);

  const [activeQuery, setActiveQuery] = useState(autocompleteQuery);
  const [previoulySubmittedQuery, setPrevioulySubmittedQuery] = useState('');

  const showDefaultSuggestions = !(activeQuery && activeQuery.length >= 3);
  const autocompleteItems = showDefaultSuggestions ? defaultSuggestion : autocompleteResponse;

  const { hideDropdown, showDropdown, keyboardHandler, resetNav } = useDropdown(
    disableDropdown || name === searchBoxDialogName ? { current: null } : rootRef,
    `.${rootClass}`,
    { useClick: true },
  );

  const sendRequest = useCallback(
    (request: { query: string; checkAutocompleteRedirect: true } | { selection: FacetValueBase[] }) => {
      dispatch(
        replaceRequest({
          ...request,
          goToSearchPage: true,
          ignoreLocalPreselection: true,
          pathname: redirectUrl,
        }),
      );
      resetNav();
      hideDropdown();
    },
    [dispatch, hideDropdown, redirectUrl, resetNav],
  );

  const submit = useCallback(
    (requestQuery: string = autocompleteQuery) => {
      onSubmit?.();
      setPrevioulySubmittedQuery(requestQuery);
      setQueriesHistory(requestQuery);
      sendRequest({ checkAutocompleteRedirect: true, query: requestQuery });
    },
    [autocompleteQuery, onSubmit, sendRequest],
  );

  const clearInput = () => {
    dispatch(clearAutocompleteQuery());
    setActiveQuery('');
  };

  const onClick = simpleHandler({ addQuery: () => submit(autocompleteQuery) });
  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    keyboardHandler(e);
    if (e.key === 'Enter') {
      submit();
    }
  };

  const onFocus = () => {
    onInputFocus?.();

    if (!autocompleteQuery && !disableAutoRequest) {
      if (!defaultSuggestion) {
        dispatch(sendAutocompleteRequest({ query: activeQuery, selection: preselection }));
      }

      showDropdown();
    }
  };

  const searchInput = (templ: TemplateFunction<SearchInputParams>) => {
    const props = {
      template: templ,
      name,
      onChange: (query: string) => {
        showDropdown();
        setActiveQuery(query);
        setPrevioulySubmittedQuery('');
      },
      onFocus,
      rootRef,
      key: 'input',
    };
    return (<SearchInput {...props} />) as TemplateResult;
  };

  const setQuery = useCallback(
    (query: string) => {
      setQueriesHistory(query);
      setActiveQuery(query);
      if (requestConfig.autocomplete.filterProductsOnQueryClick) {
        dispatch(sendAutocompleteRequest({ query, selection: preselection }));
      } else {
        sendRequest({ checkAutocompleteRedirect: true, query });
      }
    },
    [dispatch, preselection, sendRequest],
  );

  useEffect(
    function () {
      if (rootRef?.current) {
        rootRef.current.addEventListener(
          'cm_set_query',
          (e: CustomEvent) => {
            setQuery(e.detail);
          },
          false,
        );
      }
    },
    [setQuery, rootRef],
  );

  const dropdown = (templ: TemplateFunction<SearchDropdownParams>) => {
    if (disableDropdown) {
      return null;
    }

    // hide the dropdown if there aren't item
    // or if the user started to enter a query (show only default suggestion or the actual items)
    // or if the focus is on input and the value has not been changed
    const hideDropdown =
      !autocompleteItems?.length ||
      (activeQuery && activeQuery.length < 3) ||
      (!!activeQuery && previoulySubmittedQuery === activeQuery);

    const props = {
      template: templ,
      hideDropdown,
      items: autocompleteItems || [],
      query: activeQuery,
      showDefaultSuggestions,
      setQuery,
      setSelection: (selection: FacetValueBase[]) => sendRequest({ selection }),
      submit,
      onDropdownItemsReceived,
      key: 'dropdown',
    };
    return (<SearchDropdown {...props} />) as TemplateResult;
  };

  const component = template.call({
    searchInput,
    setQuery,
    dropdown,
    preventFormSubmission,
    inputNotEmpty: !!autocompleteQuery,
    clearInput: () => {
      clearInput();
      rootRef.current?.querySelector('input')?.focus();
    },
    ...extraParams,
  });

  return cloneSafe(component, rootRef, { onClick, onKeyDown, appendedClasses: rootClass });
};

export default SearchBox;
