import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import useDropdownNav from './dropdownNav.js';

const hiddenClass = 'cm_hide';

export default function useDropdown(
  rootRef,
  focusSelector,
  { dropdownName = 'dropdown', toggle = false, useClick = false } = {},
) {
  const focusRef = useRef();
  const toggleRef = useRef();
  const dropdownRef = useRef();

  const [visible, setVisibility] = useState(false);

  const navCallbacks = useDropdownNav(rootRef, setVisibility);

  useLayoutEffect(function setRefs() {
    const root = rootRef.current;
    if (root) {
      trySetRef(root, focusSelector, focusRef, 'focusable');
      focusRef.current.tabIndex = '-1';

      trySetRef(root, `.cm_${dropdownName}`, dropdownRef, 'dropdown');

      if (toggle) {
        trySetRef(root, '.cm_toggle-dropdown', toggleRef, 'toggle');
      }
    }
  });

  useLayoutEffect(function visibilityAndHiddenClassSync() {
    if (rootRef.current) {
      const currentlyVisible = !dropdownRef.current?.classList.contains(hiddenClass);
      if (currentlyVisible !== visible) {
        dropdownRef.current?.classList.toggle(hiddenClass, !visible);
      }
    }
  });

  useEffect(function addEventListeners() {
    if (rootRef.current) {
      const handlers = [
        toggle &&
          toggleRef.current && [
            toggleRef.current,
            'click',
            () => {
              setVisibility(!visible);
              if (focusRef.current !== window.document.activeElement) {
                focusRef.current.focus();
              }
            },
          ],

        !toggle && focusRef.current && [focusRef.current, 'focusin', () => setVisibility(true)],

        visible && [
          rootRef.current,
          'keydown',
          ({ key }) =>
            // setTimout is necessary so that this handler fires later than the Esc-press handler from dropdownNav
            key === 'Escape' && setTimeout(() => setVisibility(false), 0),
        ],

        !useClick &&
          focusRef.current && [
            focusRef.current,
            'focusout',
            ({ target, relatedTarget }) => {
              setVisibility(
                target?.tagName === 'SELECT' && !relatedTarget
                  ? true
                  : relatedTarget && focusRef.current.contains(relatedTarget),
              );
            },
          ],

        useClick && [
          window.document,
          'click',
          ({ target }) => {
            setVisibility((prevVisible) =>
              prevVisible && !focusRef.current.contains(target) ? false : prevVisible,
            );
          },
        ],
      ].filter(Boolean);

      handlers.forEach(([elem, event, handler]) => elem.addEventListener(event, handler));
      return () => handlers.forEach(([elem, event, handler]) => elem.removeEventListener(event, handler));
    }
  });

  const hideDropdown = useCallback(() => setVisibility(false), []);
  const showDropdown = useCallback(() => setVisibility(true), []);
  // eslint-disable-next-line camelcase
  return { ...navCallbacks, hideDropdown, showDropdown, DO_NOT_USE_visible: visible };
}

function trySetRef(root, selector, ref, name) {
  const elem = root.matches(selector) ? root : root.querySelector(selector);

  if (ref.current !== elem) {
    ref.current = elem;
  }
  if (!elem) {
    // eslint-disable-next-line no-console
    console.log(`Can't find ${name} element with ${selector} selector within root element`, root);
  }
}
