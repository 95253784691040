import { useDispatch, useSelector } from 'react-redux';

import { dialogClosed } from 'Core/actions/dialog.ts';
import { totalHitsSelector } from 'Core/selectors/search.js';
import { cloneSafe } from 'Utils/components.ts';

export default function ShowResultsButton({ template }) {
  const dispatch = useDispatch();
  const totalHits = useSelector(totalHitsSelector);

  const onClick = () => dispatch(dialogClosed());

  const component = template.call({ totalHits });
  return cloneSafe(component, null, { onClick });
}
