import { GET } from './common/httpClient.js';

import type { ServerModel } from 'Modules/serverApi/types.ts';

export default async function fitments(productId: string) {
  return await GET('ymm/fitments.json', { productId })
    .then((response) => {
      return response as ServerModel.FitmentsResponseType;
    })
    .catch((err) => {
      throw `[Convermax]: The fitments request failed: ${err?.message}`;
    });
}
