const widgetProcessors = [
  widgetTypeResolve,
  widgetIsRootResolve,
  widgetVisibleResolve,
  widgetHasSearchResultResolve,
  widgetNeedInitRequestResolve,
  widgetConsideredInScrollResolve,
];

export default function processWidgets(widgets) {
  const widgetsResolved = widgets.map((widget) => widgetProcessors.reduce((w, proc) => proc(w), widget));

  const createWidgetName = (widget) => widget.name || `${widget.type}:${widget.template}`;

  for (let i = 0; i < widgetsResolved.length; i++) {
    for (let j = 0; j < widgetsResolved.length; j++) {
      if (i === j) {
        continue;
      }
      if (
        widgetsResolved[i].visible &&
        widgetsResolved[j].visible &&
        widgetsResolved[i].element &&
        widgetsResolved[i].element === widgetsResolved[j].element
      ) {
        console.warn(
          `${createWidgetName(widgetsResolved[i])} widget uses the same render element ` +
            `as ${createWidgetName(widgetsResolved[j])} widget; ` +
            `element is ${String(widgetsResolved[j].element)}; ` +
            'to resolve it use the same name, or different IDs',
          widgetsResolved[i],
          widgetsResolved[j],
        );
      }
    }
  }

  return widgetsResolved;
}

function widgetTypeResolve(widget) {
  return { type: widget.name, ...widget };
}

function widgetIsRootResolve(widget) {
  const hasFixedPosition = [
    'Dialog',
    'ContextDialog',
    'FacetDialog',
    'SearchBoxDialog',
    'SimpleColorPaletteDialog',
    'ColorExtractorDialog',
    'Comparer',
    'AutoSyncVisualizationDialog',
  ].includes(widget.type);
  const isRoot = !!(widget.template && (widget.location || hasFixedPosition));
  return { ...widget, hasFixedPosition, isRoot };
}

function widgetVisibleResolve(widget) {
  let element = null;

  if (!widget.name) {
    const message = `The widget must have \`name\`${
      widget.hasFixedPosition
        ? ' and `template`.'
        : !widget.isRoot
          ? '. If you want the widget to be a root one, add `location` & `template`.'
          : '.'
    }`;
    console.warn(message, widget);
  }

  const isVisible = isWidgetVisible(widget);

  if (isVisible) {
    if (widget.hasFixedPosition) {
      element = document.createElement('DIV');
      if (widget.type.includes('Dialog')) {
        // can't use classList.add() with multiple arguments because IE doesn't support it
        ['cm_dialog', widget.name, widget.type === 'Dialog' && 'cm_hide']
          .concat(widget.location?.class.split(' '))
          .filter(Boolean)
          .forEach((c) => element.classList.add(c));
      }
      element.setAttribute('id', `cm_${widget.name}`);
      if ('body' in document) {
        document.body.appendChild(element);
      }
    } else if (typeof widget.location === 'string') {
      element = document.querySelector(widget.location);
    } else if (typeof widget.location === 'object' && widget.location !== null) {
      const { location } = widget;
      const wrapper = document.createElement((location.wrapper || 'div').toUpperCase());
      wrapper.id = `cm_${widget.name}`;

      if (location.selector) {
        element = document.querySelector(location.selector);
        // firstChildOf
      } else if (location.firstChildOf) {
        const parentElement = document.querySelector(location.firstChildOf);
        if (parentElement) {
          element = wrapper;
          parentElement.insertBefore(element, parentElement.firstChild);
        }
        // lastChildOf
      } else if (location.lastChildOf) {
        const parentElement = document.querySelector(location.lastChildOf);
        if (parentElement) {
          element = wrapper;
          parentElement.appendChild(element);
        }
        // insertBefore
      } else if (location.insertBefore) {
        const nextSibling = document.querySelector(location.insertBefore);
        const { parentElement } = nextSibling || {};
        if (nextSibling && parentElement) {
          element = wrapper;
          parentElement.insertBefore(element, nextSibling);
        }
        // replace
      } else if (location.replace) {
        const replace = document.querySelector(location.replace);
        const { parentElement } = replace || {};
        if (replace && parentElement) {
          element = wrapper;
          parentElement.replaceChild(element, replace);
        }
        // insertAfter
      } else if (location.insertAfter) {
        const referenceNode = document.querySelector(location.insertAfter);
        if (referenceNode) {
          element = wrapper;
          referenceNode.parentNode.insertBefore(element, referenceNode.nextSibling);
        }
      }

      if (element) {
        if (location.class) {
          element.classList.add(...location.class.split(' '));
        }
        if (location.id) {
          element.id = location.id;
        }
      }
    }
  }

  return { ...widget, element, visible: isVisible && !(widget.isRoot && !element) };
}

function isWidgetVisible(widget) {
  if (widget.visibleIf) {
    if (typeof widget.visibleIf === 'function' && !widget.visibleIf()) {
      return false;
    }
    const { selector, urls, ignoreUrls } = widget.visibleIf;
    const checkUrls = (urlsArr) => urlsArr.some((u) => location.pathname === u);

    const selectorMatch = !selector || !!document.querySelector(selector);
    const urlsMatch = !urls || checkUrls(urls);
    const ignoreUrlsMatch = ignoreUrls && checkUrls(ignoreUrls);

    return selectorMatch && urlsMatch && !ignoreUrlsMatch;
  }
  return true;
}

function widgetHasSearchResultResolve(widget) {
  let hasSearchResults = false;
  switch (widget.type) {
    case 'SearchResult':
      hasSearchResults = !!widget.element;
      break;
    case 'SearchPage':
      hasSearchResults = !widget.noSearchResults;
      delete widget.noSearchResults;
      break;
    case 'Dialog':
      hasSearchResults = !!widget.hasSearchResults;
      break;
  }
  return { ...widget, hasSearchResults };
}

function widgetNeedInitRequestResolve(widget) {
  // TODO: must be rethought hardly
  const visible = widget.element && widget.visible;
  let needInitRequest = false;
  switch (widget.type) {
    case 'Dialog':
    case 'SearchPage':
      needInitRequest = visible && (widget.needInitRequest || widget.hasSearchResults);
      break;
    case 'SearchResult':
    case 'FacetPanel':
      needInitRequest = visible && widget.needInitRequest;
      break;
  }
  return { ...widget, needInitRequest };
}

function widgetConsideredInScrollResolve(widget) {
  let consideredInScroll = false;
  switch (widget.type) {
    case 'FacetPanel':
    case 'SearchPage':
      // consider only root widgets visible on this page
      consideredInScroll = widget.visible && widget.element && widget.isRoot;
      break;
  }
  return { ...widget, consideredInScroll };
}
