import { vehicleExtraFieldsDiscardingRequested, vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { replaceLocation } from 'Core/actions/redirect.ts';

export const defaultEpicHandler = Symbol('default');
export function epicFromHandlers(handlers) {
  return (arg) => (handlers[arg.action.type] || handlers[defaultEpicHandler])?.(arg);
}

export function tryResponseRedirect({ action, dispatch }, { Actions: [{ Type, RedirectUrl } = {}] = [] }) {
  if (
    action.type === vehicleSelected.type ||
    action.type === vehicleExtraFieldsDiscardingRequested.type ||
    Type !== 'Redirect' ||
    !RedirectUrl ||
    location.pathname === RedirectUrl
  ) {
    return false;
  }

  dispatch(replaceLocation(RedirectUrl));
  return true;
}
