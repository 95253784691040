import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { createFilteredFacetsSelector } from 'Core/selectors/search.js';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import isMobile from 'Vendor/isMobile.js';
import BaseFacet from './baseFacet.tsx';

import type { FunctionComponent } from 'react';
import type { RepeaterFunctionInvoker, TemplateFunction, TemplateResult } from 'Components/types.ts';
import type { FacetCollection } from 'Models/index.ts';
import type { Params as FacetParams } from './baseFacet.tsx';
import { DisplayMode } from 'Modules/serverApi/types.ts';

export interface Params {
  facets: RepeaterFunctionInvoker<FacetParams>;
  isMobile: boolean;
  hasSelection: boolean;
}

interface Props {
  template: TemplateFunction<Params>;
  fields?: string[];
  ignoreFields?: string[];
}

const Facets: FunctionComponent<Props> = ({ template, fields, ignoreFields }) => {
  const displayedFacets = useSelector(
    useMemo(() => {
      const { subFacetFields } = facetsConfig;
      const fullIgnoreFields = ignoreFields
        ? ignoreFields.concat(subFacetFields as string[])
        : subFacetFields;
      return createFilteredFacetsSelector(fields, fullIgnoreFields);
    }, [fields, ignoreFields]),
  ) as FacetCollection;

  if (!displayedFacets.length) {
    return null;
  }

  const facets = displayedFacets.map((facet) => (templ: TemplateFunction<FacetParams>) => {
    if (facet.displayMode === DisplayMode.None) {
      return null;
    }

    const props = { template: templ, facet, key: facet.field };
    return (<BaseFacet {...props} />) as TemplateResult;
  });

  return (
    <SelectContextProvider>
      {template.call({ facets, isMobile, hasSelection: displayedFacets.selection.length > 0 })}
    </SelectContextProvider>
  );
};

export default Facets;
