import { unprocessedConfig } from 'Templates/templates.js';
import widgetProcessor from 'Components/widgetProcessor.js';

import type { AnyWidget } from 'Templates/widgets.ts';

const processedWidgets: AnyWidget[] = widgetProcessor(unprocessedConfig.Widgets);
export const mainConfig = {
  ...unprocessedConfig,
  Widgets: processedWidgets,
};
export default processedWidgets;
