import { combineReducers } from 'redux';

import {
  garageCleanupRequested,
  removalFromGarageRequested,
  replaceVehicleValue,
  vehicleDiscardingRequested,
} from 'Core/actions/fitmentSearch/index.js';
import { createReducersFromShorthands } from 'Core/reducers/common.ts';
import { loadSelectedVehicle } from 'Utils/userStorage.js';
import { updateVehicleSelection } from '../common.js';

const initStates = {
  selection: loadSelectedVehicle().selection,
};

export default combineReducers(
  createReducersFromShorthands(
    {
      selection: {
        [garageCleanupRequested]: () => [],
        [vehicleDiscardingRequested]: () => [],
        [removalFromGarageRequested]: (state, { meta }) => (meta.wasSelected ? [] : state),
        [replaceVehicleValue]: (state, { payload, meta: { fitmentFields } }) =>
          updateVehicleSelection(state, payload, fitmentFields),
      },
    },
    initStates,
  ),
);
