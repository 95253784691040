import { createSelector } from '@reduxjs/toolkit';

import { vehicleLandingFacetField } from 'Addons/fitmentSearch/vehicleLandingFacetField.ts';
import { garageDataSelector, selectedVehicleSelector } from './index.js';

export const isVehicleLandingFacetSelectedSelector = (state) =>
  state.search.request.selection.some((v) => v.field === vehicleLandingFacetField);

export function createVehicleIsSelectedSelector(vehicle) {
  return createSelector(selectedVehicleSelector, (selectedVehicle) => vehicle.equals(selectedVehicle));
}
export const createSelectedVehicleSelectionSelector = createSelector(
  selectedVehicleSelector,
  (vehicle) => vehicle.selection,
);
export const createVehicleFromUriSelector = createSelector(
  (state) => state.fitmentSearch,
  (fitmentSearch) => fitmentSearch.vehicleFromUri,
);

export const garageSizeSelector = (state) => garageDataSelector(state).size;

export const garageEmptySelector = (state) => !garageSizeSelector(state);
