import _transform from 'lodash-es/transform.js';

import type { PayloadAction, Reducer } from '@reduxjs/toolkit';

export function fromPayload<T>(_state: T, { payload }: PayloadAction<T>) {
  return payload;
}

export function createIsolatedReducer<S>(isolatedKey: string | null, reducer: Reducer<S>): Reducer<S> {
  return (state = reducer(undefined, { type: '__INIT_ACTION' }), action) => {
    // action must have type, but not the meta property
    const { meta = {} } = action;
    // if action has no isolatedKey, we just let it through
    const actionMatchesKey = 'isolatedKey' in meta ? meta.isolatedKey === isolatedKey : true;
    return actionMatchesKey ? reducer(state, action) : state;
  };
}

// deprecated
export function fromAction(key, defaultValue) {
  return (state, action) =>
    key in action ? action[key] : typeof defaultValue !== 'undefined' ? defaultValue : state;
}

// deprecated
export function createReducersFromShorthands(shorthands, initStates) {
  return _transform(
    shorthands,
    (reducers, shorthand, key) => {
      reducers[key] = createReducer(shorthand, initStates[key]);
    },
    {},
  );
}

// deprecated
export function createReducer(typeMap, initState) {
  return (state = initState, action) => {
    const reducer = typeMap[action.type] || typeMap.default || ((st) => st);
    return reducer(state, action);
  };
}
