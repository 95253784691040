import useEventListener from '@use-it/event-listener';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dialogClosed } from 'Core/actions/dialog.ts';
import { createDialogOpenedSelector } from 'Core/selectors/dialog.js';
import isMobile from 'Vendor/isMobile.js';

type PopupParams = {
  isMobile: boolean;
  name: string;
};
type PopupRoles = { close: () => void };
type UsePopup = {
  close: () => void;
  opened: boolean;
  popupParams: PopupParams;
  popupRoles: PopupRoles;
};

export default function usePopup(name: string, closeOnClickOutside = true): UsePopup {
  const dispatch = useDispatch();

  const opened = useSelector(useMemo(() => createDialogOpenedSelector(name), [name])) as boolean;

  useEffect(
    function disableScrollOnBody() {
      if (!opened) {
        return;
      }
      window.document.body.classList.add('cm_no-scroll');
      window.document.documentElement.classList.add('cm_no-scroll'); // html elem
      return () => {
        window.document.documentElement.classList.remove('cm_no-scroll');
        window.document.body.classList.remove('cm_no-scroll');
      };
    },
    [opened],
  );

  const close = useCallback(() => dispatch(dialogClosed(name)), [dispatch, name]);

  useEventListener(
    'click',
    useCallback(
      (e) => closeOnClickOutside && opened && e.target?.matches(`.cm_dialog.${name}`) && close(),
      [closeOnClickOutside, opened, name, close],
    ),
    document.body, // theme jq code can call stopPropagation on window.document.body, so the event will not pop up to the window
  );

  const popupParams = {
    isMobile,
    name,
  };
  const popupRoles = { close };

  return { opened, popupParams, popupRoles, close };
}
