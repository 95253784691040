import isLight from 'Modules/colorLightness.js';

import type { FunctionComponent } from 'react';

import './colorTile.scss';

interface Props {
  color: string;
  isSelected?: boolean;
  showSwatch?: boolean;
  onClick?: () => void;
  name?: string;
  className?: string;
}

const ColorTile: FunctionComponent<Props> = ({
  color,
  isSelected,
  showSwatch,
  onClick,
  name,
  className: extraClassName,
}) => {
  const onKeyPress = ({ key }) => key === 'Enter' && onClick?.();

  const className = [
    'color-tile',
    'grid-square',
    extraClassName,
    isSelected && 'selected',
    showSwatch && 'show-swatch',
    isLight(color) && 'light',
  ]
    .filter(Boolean)
    .join(' ');

  const props = {
    className,
    style: color ? { backgroundColor: `#${color}` } : undefined,
    title: name,
    onClick,
    onKeyPress,
  };
  return <div {...props} />;
};

export default ColorTile;
