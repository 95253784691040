import { setResponse } from 'Core/actions/response.ts';
import { keysSelector } from 'Modules/converter/index.js';
import { createReducer } from 'Core/reducers/common.ts';

const searchOptionKeys = [
  'allowSearchSameAsUri' as const,
  'fromUri' as const,
  'goToSearchPage' as const,
  'ignoreLocalPreselection' as const,
  'mayDiscardValue' as const,
  'onInit' as const,
  'stayOnPage' as const,
  'pathname' as const,
];
export type SearchOptions = Partial<Record<(typeof searchOptionKeys)[number], boolean>>;

export const searchOptionsSelector = keysSelector<SearchOptions, SearchOptions>(...searchOptionKeys);

const initState = {};

export default createReducer(
  {
    [setResponse.type]: () => initState,
    SKIP_REQUEST: () => initState,
    default: (state, action) =>
      action.sendRequest
        ? searchOptionsSelector(action)
        : // TODO: remove this branch when sendRequest will be fully moved to action.meta
          action.meta?.sendRequest
          ? searchOptionsSelector(action.meta)
          : state,
  },
  initState,
);
