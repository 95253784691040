import { useContext } from 'react';

import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import AddButton from '../addButton.jsx';
import { vehicleWidgetName } from '../garage.jsx';
import GarageContext from '../garageContext.ts';
import VehicleWidget from '../../vehicleWidget/index.ts';

export default function EmptyDropdown({ template }) {
  const {
    closeGarage,
    doNotRedirectOnVehicleSelect,
    isAutoVehicleSelectionDisabled,
    redirectUrl,
    showVehicleDropdowns,
    useNativeDropdown,
    useVehicleWidget,
  } = useContext(GarageContext);

  const addButton = (templ) => <AddButton template={templ} key="add" />;

  const onClick = simpleHandler({ showVehicleDropdowns });

  const vehicleWidget = (templ) => {
    if (!useVehicleWidget) {
      return null;
    }

    const props = {
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      isAutoVehicleSelectionDisabled,
      name: vehicleWidgetName,
      doNotRedirectOnVehicleSelect,
      redirectUrl,
      useNativeDropdown,
      template: templ,
    };
    return <VehicleWidget {...props} key="VehicleWidget" />;
  };

  const component = template.call({ addButton, closeGarage, vehicleWidget, template: 'empty' });
  return cloneSafe(component, null, { onClick });
}
