const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;
const activeCurrency = window.Shopify.currency?.active;

export default {
  fields: {
    id: 'id',
    image: 'image',
    title: 'title',
    url: 'url',
  },
  getItemId() {
    const sa = window.ShopifyAnalytics;
    if (sa) {
      return `${sa.meta.product.id}`;
    }
    console.warn('window.ShopifyAnalytics not found');
    return '';
  },
  dropdownImageWidth: 100,
  dropdownImageHeight: 100,
  searchResultsImageWidth: 300,
  searchResultsImageHeight: 300,
  priceFormatter: activeCurrency
    ? new Intl.NumberFormat(locale, { style: 'currency', currency: activeCurrency })
    : null,
};
