import { WeightedColorsArray } from 'Models';
import { GET } from 'Modules/serverApi/common/httpClient.js';

import type { ServerModel } from 'Modules/serverApi/types';

export default async function item(key: string) {
  return await GET('item.json', { key })
    .then((response) => {
      const item = { ...response } as ServerModel.SearchItem;

      if (response._Color) {
        item._Color = new WeightedColorsArray(response._Color);
      }

      return item;
    })
    .catch(async (err) => {
      throw `[Convermax]: Failed to get item by key ${key}: ${err?.message}`;
    });
}
