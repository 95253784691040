import useEventListener from '@use-it/event-listener';
import { useCallback, useMemo, useRef } from 'react';

import { cloneSafe } from 'Utils/components.ts';

export default function Tooltip({ template, name, field }) {
  const rootRef = useRef(null);
  const content = useMemo(() => {
    const tipsContainer = document.querySelector('.cm_tooltips-content');
    if (!tipsContainer) {
      return null;
    }
    const elem = tipsContainer.querySelector(`[data-cm-name="${name}"], [data-cm-name="${field}"]`);
    return elem?.innerHTML;
  }, [name, field]);

  useEventListener(
    'touchmove',
    useCallback(
      (e) =>
        rootRef.current && !rootRef.current.contains(e.target) && rootRef.current.classList.remove('touched'),
      [],
    ),
  );

  if (!content) {
    return null;
  }

  const contentElem = (
    <div className="tooltip-content" key="tooltip" dangerouslySetInnerHTML={{ __html: content }}></div>
  );
  const component = template.call({ name });
  return cloneSafe(component, rootRef, { onTouchStart, appendedChildren: contentElem });
}

function onTouchStart({ currentTarget }) {
  currentTarget.classList.add('touched');
}
