import { sendInitIsolatedRequest } from 'Core/actions/fitmentSearch/index.js';
import { selectedVehicleSelector } from 'Core/reducers/fitmentSearch/storage.ts';
import { getUriFromRequest } from 'Modules/converter/index.js';

import type { ReduxStoreType } from 'Core/store.ts';

export default function initialize(store: ReduxStoreType): void {
  const { Convermax, document } = window;
  Convermax.fitmentSearch = Convermax.fitmentSearch || {};

  store.dispatch(sendInitIsolatedRequest());

  document.addEventListener('keydown', (e) => {
    if (e.ctrlKey && (e.altKey || e.metaKey) && (e.key === 'l' || e.code === 'KeyL')) {
      const state = store.getState();
      const vehicle = selectedVehicleSelector(state);
      const { href } = getUriFromRequest(
        {
          ...state.search.request,
          selection: [...vehicle.selection, ...state.search.request.selection],
        },
        { stayOnPage: true },
      );
      window.navigator.clipboard.writeText(href).catch(() => {
        window.prompt('Here is the search URL, ', href);
      });
    }
  });
}
