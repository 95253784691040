enum pageType {
  search = 'search',
  home = 'home',
  category = 'category',
  parts = 'parts',
  brand = 'brand',
  product = 'product',
  unknown = 'unknown',
}

export default pageType;
