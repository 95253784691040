import equal from 'lodash-es/isEqual.js';

import requestConfig from 'Models/uiConfig/requestConfig.js';
import { convertibleFromObject } from 'Modules/converter/index.js';
import { facetsToConvertible, makeGetOrDefault } from './common/convert.js';
import { GET } from './common/httpClient.js';
const {
  autocomplete: { requestDefaults },
  autocompleteDefaultOrder,
} = requestConfig;

const autocompleteTypeMap = {
  text: ['freetext', 'popularsearch'],
  product: ['product'],
  facetValue: ['category'],
};

export default async function autocomplete(request, abortSignal) {
  const getOrDefault = makeGetOrDefault(request, requestDefaults);
  const params = {
    catalog: getOrDefault('catalog'),
    query: encodeURIComponent(getOrDefault('query')),
    type: getAutocompleteTypes(request),
    querycorrectionlevel: getOrDefault('QueryCorrectionLevel') || '',
    facet: facetsToConvertible(request),
    extra: await convertibleFromObject(getOrDefault('extra')),
  };

  try {
    return await GET('autocomplete.json', params, { signal: abortSignal });
  } catch (err) {
    throw err || 'Autocomplete get request rejected with no error passed';
  }
}

function getAutocompleteTypes({ types }) {
  const useDefaultTypes = equal(types, autocompleteDefaultOrder);
  return useDefaultTypes ? null : types.flatMap((t) => autocompleteTypeMap[t]);
}
