import { useDispatch } from 'react-redux';

import Select from 'Components/smallComponents/select.tsx';
import { discardField, replaceValue, toggle } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';

export default function SelectFacet({ facet, templateFunc, config, facetRef, commonParams, commonRoles }) {
  const dispatch = useDispatch();

  if (!facet.facetedValues.length) {
    return null;
  }

  const { isSingleValue } = facet;

  const select = (templ) => {
    const props = {
      template: templ,
      entries: facet.facetedValues.map((v) => ({
        value: v.value,
        term: v.term,
        selected: !!v.isSelected,
        hitCount: v.hitCount,
        url: v.url,
      })),
      extraClassName: 'cm_facet_select',
      field: facet.field,
      title: facet.name,
      hideNullOption: !isSingleValue,
      showCheckboxes: !isSingleValue,
      useNativeDropdown: config.useNativeDropdown ?? isSingleValue,
      showHitCount: config.showHitCount ?? true,
      key: 'select',
      onChange: (term) =>
        dispatch(
          !term
            ? discardField(facet.field, { mayDiscardValue: true })
            : !isSingleValue
              ? toggle({ field: facet.field, term }, { isSingleValue, mayDiscardValue: true })
              : replaceValue({ field: facet.field, term }, { mayDiscardValue: true }),
        ),
    };
    return <Select {...props} />;
  };

  const onClick = simpleHandler({ ...commonRoles });

  const component = templateFunc.call({ ...commonParams, select });

  return cloneSafe(component, facetRef, { onClick });
}
