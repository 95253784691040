import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = '10secondracing-com';

const facetWidget = shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets');

window.Convermax.showTooltip = (e) => {
  e.preventDefault();
  e.stopPropagation();

  const tooltipContent = e.target.nextSibling;

  if (tooltipContent) {
    tooltipContent.classList.remove('cm_hide');
  }
};

window.Convermax.hideTooltip = (e) => {
  const tooltipContent = e.target.nextSibling;

  if (tooltipContent) {
    tooltipContent.classList.add('cm_hide');
  }
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  facets: {
    ...shopifyGenericDefaults.facets,
    simpleFacet: { fields: ['vendor', 'product_type'], name: 'tilesFacet' },
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['Facets'].includes(w.name)),
    {
      ...facetWidget,
      template: 'Facets',
    },
    {
      name: 'AutoSyncVisualization',
      apiKey: AutoSyncKey,
      config: {
        height: '150px',
        vehicleImageSize: 640,
        vehicleAngles: [32],
        showColorPicker: false,
      },
      classModificator: 'search-result',
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
