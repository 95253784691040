import {
  addToGarageRequested,
  forceVehicleSelectionLater,
  garageCleanupRequested,
  ignoreInexactFields,
  removalFromGarageRequested,
  replaceVehicleValue,
  setGarageRequested,
  vehicleDiscardingRequested,
  vehicleExtraFieldsDiscardingRequested,
  vehicleSelected,
  vehicleSpecificationNeeded,
} from 'Core/actions/fitmentSearch/index.js';
import { resetRequest } from 'Core/actions/request.js';
import { viewChangeRequested } from 'Core/actions/show.ts';
import { garageDataSelector, selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { keys, setVehicleData, removeVehicleData, setForceVehicleSelectionLater } from 'Utils/userStorage.js';
import { epicFromHandlers } from './common.js';

export default epicFromHandlers({
  [forceVehicleSelectionLater]: setForceVehicleSelectionLater,
  [garageCleanupRequested]: () => {
    removeVehicleData(keys.vehicle);
    removeVehicleData(keys.garageData);

    globalThis.Convermax.onVehicleRemoved?.([]);
  },
  [replaceVehicleValue]: ({ action }) => !action.meta.isolatedKey && removeVehicleData(keys.vehicle),
  [vehicleDiscardingRequested]: () => {
    removeVehicleData(keys.vehicle);
  },
  [resetRequest().type]: ({ action: { discardUserPreselection } }) =>
    discardUserPreselection && removeVehicleData(keys.vehicle),
  [vehicleExtraFieldsDiscardingRequested]: ({ state }) =>
    setVehicleData(keys.vehicle, selectedVehicleSelector(state).serializable),
  [ignoreInexactFields]: putVehicleAndGarageToStorage,
  [setGarageRequested]: ({
    action: {
      payload: { vehicles, selectVehicle },
    },
  }) => {
    setVehicleData(keys.garageData, vehicles.serializable);
    if (selectVehicle) {
      setVehicleData(keys.vehicle, vehicles.last.serializable);
    }
  },
  [removalFromGarageRequested]: ({
    state,
    action: {
      meta: { wasSelected },
    },
  }) => {
    const garageData = garageDataSelector(state);
    if (garageData.size) {
      setVehicleData(keys.garageData, garageData.serializable);
    } else {
      removeVehicleData(keys.garageData);
    }
    if (wasSelected) {
      removeVehicleData(keys.vehicle);
    }

    globalThis.Convermax.onVehicleRemoved?.(garageData.map((vehicle) => vehicle.filteredFieldMap));
  },
  [addToGarageRequested]: ({ state }) => {
    const garageData = garageDataSelector(state);
    setVehicleData(keys.garageData, garageData.serializable);
  },
  [vehicleSelected]: (state) => {
    putVehicleAndGarageToStorage(state);
  },
  [vehicleSpecificationNeeded]: putVehicleAndGarageToStorage,
  [viewChangeRequested]({ action: { payload } }) {
    if (payload === uiConfig.defaultView) {
      removeVehicleData(keys.view);
    } else {
      setVehicleData(keys.view, payload);
    }
  },
});

function putVehicleAndGarageToStorage({ state }) {
  setVehicleData(keys.vehicle, selectedVehicleSelector(state).serializable);
  const garageData = garageDataSelector(state);
  if (garageData.size) {
    setVehicleData(keys.garageData, garageData.serializable);
  }
}
