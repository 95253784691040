import { createSelectorCreator, defaultMemoize } from 'reselect';
import { FacetValue } from 'Models/index.ts';
import { comparerBy, equalByMany } from 'Utils/array.js';

export const createFacetCollectionSelector = createSelectorCreatorForComparable((prev, next) =>
  prev.sameFacetsAsIn(next),
);

export const createSearchItemDataCollectionSelector = createSelectorCreatorForComparable((prev, next) => {
  equalByMany(prev, next, (o) => Object.keys(o).length);
});

export const createFacetValueArraySelector = createSelectorCreatorForComparable(
  comparerBy(FacetValue.valueKey),
);

function createSelectorCreatorForComparable(comparer) {
  return createSelectorCreator((selector) => {
    let lastResult = null;
    const memoizedSelector = defaultMemoize(selector);
    return function () {
      // apply arguments instead of spreading for performance as in 'reselect' source code
      // eslint-disable-next-line prefer-spread,prefer-rest-params
      const newResult = memoizedSelector.apply(null, arguments);
      if (!lastResult || (newResult !== lastResult && !comparer(lastResult, newResult))) {
        lastResult = newResult;
      }
      return lastResult;
    };
  });
}

export function createIsolatedSelectorCreator(...args) {
  const isolatedSelectorCreators = Array.isArray(args[0]) ? args[0] : args.slice(0, -1);
  const combiner = args[args.length - 1];
  const selectors = {};
  return (isolatedKey = null) => {
    if (!selectors[isolatedKey]) {
      selectors[isolatedKey] = combiner(
        isolatedSelectorCreators.map((creator) => creator(isolatedKey)),
        isolatedKey,
      );
    }
    return selectors[isolatedKey];
  };
}
