import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import pageType from 'Addons/search/pageType';
import {
  removalFromGarageRequested,
  sendVerifyFitmentRequest,
  vehicleSelected,
} from 'Core/actions/fitmentSearch/index.js';
import { createVehicleIsSelectedSelector } from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { VehicleValue } from 'Models/vehicle.ts';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import GarageContext from '../garageContext.ts';

import type { FunctionComponent } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import type { Vehicle } from 'Models/index.ts';

export interface Params {
  isSelected: boolean;
  vehicleString: string;
  vehicle: Record<string, string>;
  vehicleValues: VehicleValue[];
}

type Props = {
  template: TemplateFunction<Params>;
  vehicle: Vehicle;
};

const GarageItem: FunctionComponent<Props> = ({ template, vehicle }) => {
  const dispatch = useDispatch();
  const isSelectedSelector = useMemo(() => createVehicleIsSelectedSelector(vehicle), [vehicle]);
  const isSelected = useSelector(isSelectedSelector);

  const { closeGarage, doNotRedirectOnVehicleSelect, redirectUrl } = useContext(GarageContext);

  const removeVehicle = () => {
    dispatch(removalFromGarageRequested(vehicle, isSelected));
    fitmentSearchConfig.onVehicleDiscarded();
  };

  const selectVehicle = () => {
    closeGarage?.();
    if (uiConfig.pageType === pageType.product && doNotRedirectOnVehicleSelect) {
      // vehicle will be selected inside
      dispatch(sendVerifyFitmentRequest(vehicle));
    } else {
      dispatch(
        vehicleSelected(vehicle, {
          doNotRedirectOnVehicleSelect,
          isSelectionFromGarage: true,
          redirectUrl,
          sendIsolatedRequest: fitmentSearchConfig.isOnCategorySelectionPage,
        }),
      );
    }
    fitmentSearchConfig.onVehicleSelected(vehicle.filteredFieldMap);
  };

  const onClick = simpleHandler({ removeVehicle, selectVehicle });

  const component = template.call({
    isSelected,
    vehicleString: vehicle.toString(),
    vehicle: vehicle.filteredFieldMap,
    vehicleValues: vehicle.serializable,
  });
  return cloneSafe(component, null, { onClick });
};

export default GarageItem;
