import { useSelector } from 'react-redux';

import { autocompleteTotalHitsSelector } from 'Core/selectors/autocomplete.ts';
import { cloneSafe } from 'Utils/components.ts';

export default function BrowseAllButton({ template, submit, query }) {
  const totalHits = useSelector(autocompleteTotalHitsSelector);

  if (!totalHits) {
    return null;
  }

  const onKeyDown = (e) => e.key === 'Enter' && submit(query);

  const component = template.call({ totalHits, query });
  return cloneSafe(component, null, {
    onClick: () => submit(query),
    onKeyDown,
    tabIndex: -1,
    appendedClasses: 'nav-item',
  });
}
