import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import {
  allItemsReceivedSelector,
  lastPageNumberSelector,
  noItemsReceivedSelector,
  searchRequestSelector,
} from 'Core/selectors/search.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import PaginationButton from './paginationButton.js';
import { makeButtonProps } from './propsHelper.js';

const hidePaginationSelector = createSelector(
  noItemsReceivedSelector,
  allItemsReceivedSelector,
  (noItemsReceived, allItemsReceived) => noItemsReceived || allItemsReceived,
);

export default function Pagination({ template }) {
  const hidePagination = useSelector(hidePaginationSelector);
  const pageNumber = useSelector((state) => state.search.request.pageNumber);
  const lastPageNumber = useSelector(lastPageNumberSelector);
  const searchRequest = useSelector(searchRequestSelector);

  if (hidePagination) {
    return null;
  }

  const buttons = makeButtonProps(pageNumber, lastPageNumber).map((buttonProps) => (templ) => {
    const { pathname, search } = getUriFromRequest({ ...searchRequest, pageNumber: buttonProps.pageNumber });

    const props = {
      ...buttonProps,
      pageLink: `${pathname}${search}`,
      template: templ,
      isActive: buttonProps.pageNumber === pageNumber,
    };
    return <PaginationButton {...props} />;
  });

  return template.call({ buttons });
}
