export default function PageSizeSelect({ pageSize, pageSizes, setPageSize, className }) {
  const onChange = (e) => setPageSize(e.target.value);

  const props = { onChange, className, value: pageSize };

  return (
    <select {...props}>
      {pageSizes.map((configValue) => (
        <option value={isNaN(configValue) ? 'all' : configValue} key={configValue}>
          {configValue}
        </option>
      ))}
    </select>
  );
}
