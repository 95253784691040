import { useDispatch, useSelector } from 'react-redux';

import { setSort } from 'Core/actions/request.js';

export default function SortSelect({ className, sortEntries }) {
  const dispatch = useDispatch();
  const sort = useSelector((state) => state.search.request.sort);

  const onChange = (e) => dispatch(setSort(e.target.value));

  const props = {
    className,
    onChange,
    value: sort,
  };
  return (
    <select {...props} aria-label="Sort by">
      {Object.entries(sortEntries).map(([value, name]) => (
        <option value={value} key={value}>
          {name}
        </option>
      ))}
    </select>
  );
}
