import { useContext } from 'react';

import DialogButton from 'Components/smallComponents/dialogButton.js';
import GarageContext from './garageContext.ts';

export default function AddButton({ template }) {
  const { closeGarage } = useContext(GarageContext);

  const props = { template, onClick: closeGarage, dialogName: 'vehicle-dialog' };
  return <DialogButton {...props} />;
}
