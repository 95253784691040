import { createAction } from '@reduxjs/toolkit';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { FacetValueFull, Vehicle } from 'Models/index.ts';
import type { VehicleValue } from 'Models/vehicle.ts';

export const sendInitIsolatedRequest = createAction('SEND_INIT_ISOLATED_REQUEST');

export const sendVehicleDialogRequest = createAction('SEND_VEHICLE_DIALOG_REQUEST');
export const setVehicleDialogSelection = createAction<VehicleValue[]>('SET_VEHICLE_DIALOG_SELECTION');
export const clearVehicleDialogRequest = createAction('CLEAR_VEHICLE_DIALOG_REQUEST');

export const replaceVehicleValue = createAction(
  'REPLACE_VEHICLE_VALUE',
  (
    value: FacetValueFull,
    isolatedKey: IsolatedKey = null,
    fitmentFields: string[],
    meta: {
      isAutoVehicleSelectionDisabled?: boolean;
      isPartialMode?: boolean;
      sendIsolatedRequest?: boolean;
    } = {},
  ) => ({
    payload: value,
    meta: {
      sendRequest: !isolatedKey,
      allowSearchSameAsUri: true,
      stayOnPage: true,
      isolatedKey,
      fitmentFields,
      responseMeta: {
        isAutoVehicleSelectionDisabled: meta.isAutoVehicleSelectionDisabled,
        isPartialMode: meta.isPartialMode,
      },
      extra: { vehicleValueJustReplaced: true },
      sendIsolatedRequest: meta.isAutoVehicleSelectionDisabled,
    },
  }),
);
export const discardVehicleValue = createAction('DISCARD_VEHICLE_VALUE', (value: FacetValueFull) => ({
  payload: value,
  meta: {
    sendRequest: true,
    allowSearchSameAsUri: true,
    stayOnPage: true,
    extra: { vehicleValueJustReplaced: true },
  },
}));

export const sendVerifyFitmentRequest = createAction(
  'SEND_VERIFY_FITMENT_REQUEST',
  (vehicle: Vehicle, productId?: number) => ({
    payload: undefined,
    meta: {
      vehicle,
      productId,
    },
  }),
);

export const clearVerifyFitmentRequest = createAction('CLEAR_VERIFY_FITMENT_REQUEST');
