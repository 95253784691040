import FocusLock from 'react-focus-lock';

import { usePopup } from 'Core/hooks/index.js';
import SearchBox from '../searchBox/searchBox.tsx';

import type { FC } from 'react';
import type { Props as SearchBoxProps } from 'Components/searchBox/searchBox.tsx';
import type { TemplateResult } from 'Components/types.ts';

export const dialogName = 'SearchBoxDialog';

const SearchBoxDialog: FC<SearchBoxProps> = (props) => {
  const { opened, close } = usePopup(dialogName);

  if (!opened) {
    return null;
  }

  const onSubmit = () => {
    close();
    props.onSubmit?.();
  };

  const searchBoxProps = {
    ...props,
    name: dialogName,
    onSubmit,
    extraParams: { close },
    isDialogOpened: opened,
    disableAutoRequest: true,
  };

  return (
    <FocusLock>
      <SearchBox {...searchBoxProps} />
    </FocusLock>
  ) as TemplateResult;
};

export default SearchBoxDialog;
