import _groupBy from 'lodash-es/groupBy.js';
import _transform from 'lodash-es/transform.js';

export function makeGetOrDefault(request, defaults) {
  return (name) => request[name] || defaults[name] || '';
}

export function facetsToConvertible(
  { maxCountByField, selection: userSelection },
  { selection: defaultSelection } = {},
) {
  const selection = [].concat(userSelection).concat(defaultSelection).filter(Boolean);
  const fieldsWithExtraParams = Object.keys({ ...maxCountByField });
  if (!selection.length && !fieldsWithExtraParams.length) {
    return;
  }
  const fieldFacetMap = _groupBy(selection, 'field');
  const fieldIndexes = {};
  let i = 0;

  const convertible = _transform(
    fieldFacetMap,
    (res, values, field) => {
      fieldIndexes[field] = i;
      res[i++] = {
        field: encodeURIComponent(field),
        selection: values.map((v) => v.term && encodeURIComponent(v.term)),
        op: values.map((v) => v.op),
        expand: values.map((v) => v.expand),
        hidden: values.map((v) => v.hidden),
      };
    },
    {},
  );

  for (const field of fieldsWithExtraParams) {
    const { [field]: j = -1 } = fieldIndexes;

    if (j < 0) {
      convertible[i++] = {
        field: encodeURIComponent(field),
        maxCount: maxCountByField[field],
      };
    } else {
      convertible[j].maxCount = maxCountByField[field];
    }
  }

  return convertible;
}

export function sortToConvertible(sort) {
  if (!sort || sort === 'relevance') {
    return;
  }
  return _transform(
    sort.split(','),
    (res, sort, i) => {
      const [fieldname, desc] = sort.split(':');
      res[i] = { fieldname, descending: !!desc };
    },
    {},
  );
}
