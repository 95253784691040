import uiConfig from 'Models/uiConfig/uiConfig.js';

const {
  pagination: { edgeRange, centralRange, disableDelimeters },
} = uiConfig;

function makeButtonProps(activePageNumber, lastPageNumber, withPrevNextStubs) {
  const buttonProps = [];

  if (activePageNumber > 0) {
    buttonProps.push({ type: 'prev', key: 'prev', pageNumber: activePageNumber - 1 });
  } else if (withPrevNextStubs) {
    buttonProps.push({ type: 'prevStub', key: 'prevStub' });
  }

  for (let pageNumber = 0; pageNumber < lastPageNumber + 1; pageNumber++) {
    if (pageNumber < edgeRange) {
      buttonProps.push(pageProps(pageNumber, lastPageNumber));
    } else if (pageNumber === edgeRange && pageNumber < activePageNumber) {
      if (pageNumber >= activePageNumber - centralRange - 1) {
        buttonProps.push(pageProps(pageNumber, lastPageNumber));
      } else {
        if (!disableDelimeters) {
          buttonProps.push(delimiterProps('first'));
        }

        pageNumber = activePageNumber - centralRange - 1;
      }
    } else if (
      pageNumber >= activePageNumber - centralRange &&
      pageNumber <= activePageNumber + centralRange
    ) {
      buttonProps.push(pageProps(pageNumber, lastPageNumber));
    } else if (pageNumber === activePageNumber + centralRange + 1) {
      if (pageNumber >= lastPageNumber - edgeRange) {
        buttonProps.push(pageProps(pageNumber, lastPageNumber));
      } else {
        if (!disableDelimeters) {
          buttonProps.push(delimiterProps('first'));
        }

        pageNumber = lastPageNumber - edgeRange;
      }
    } else {
      buttonProps.push(pageProps(pageNumber, lastPageNumber));
    }
  }

  if (activePageNumber < lastPageNumber) {
    buttonProps.push({ type: 'next', key: 'next', pageNumber: activePageNumber + 1 });
  } else if (withPrevNextStubs) {
    buttonProps.push({ type: 'nextStub', key: 'nextStub' });
  }

  return buttonProps;
}

function pageProps(pageNumber, totalPages) {
  return { type: 'page', key: pageNumber.toString(), pageNumber, totalPages: totalPages + 1 };
}

function delimiterProps(key) {
  return { type: 'delimiter', key: `delimiter ${key}` };
}

export { makeButtonProps, pageProps };
