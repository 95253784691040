import { useState, useEffect, type FC } from 'react';
import { useDispatch } from 'react-redux';

import { dialogOpened } from 'Core/actions/dialog.ts';
import { usePopup } from 'Core/hooks/index.js';
import AutoSyncVisualization, { type Props as AutoSyncVisualizationProps } from './autoSyncVisualization.tsx';

const DialogName = 'AutoSyncVisualizationDialog';

const AutoSyncVisualizationDialog: FC<AutoSyncVisualizationProps> = (props) => {
  const dispatch = useDispatch();
  const [wheelMpn, setWheelMpn] = useState<string>();
  const { opened, close } = usePopup(DialogName);

  useEffect(() => {
    globalThis.Convermax.openVisualizationDialog = (wheelMpn: string) => {
      setWheelMpn(wheelMpn);
      dispatch(dialogOpened(DialogName));
    };
  }, [dispatch]);

  if (!opened) {
    return null;
  }

  const visualizationProps = {
    ...props,
    config: {
      height: window.innerWidth > 1024 ? 'calc(80vh - 80px)' : 'calc(100vh - 80px)',
      vehicleImageSize: 2400,
      widthAdjustment: 'scale',
      ...(props?.config ?? {}),
    },
    wheelMpn,
    classModificator: 'dialog',
    extraParams: {
      close,
    },
  } as AutoSyncVisualizationProps;

  return <AutoSyncVisualization {...visualizationProps} />;
};

export default AutoSyncVisualizationDialog;
