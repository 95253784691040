import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SliderStrip from 'Components/slider/sliderStrip.jsx';
import ColorTile from 'Components/smallComponents/colorTile.tsx';
import { replaceColor } from 'Core/actions/request.js';
import { createSelectedColorSelector } from 'Core/selectors/colorSearch.js';
import isLight from 'Modules/colorLightness.js';
import facetsPanelConfig from './facetsPanelConfig.ts';
import './colorSlider.scss';

export default function ColorSlider({ color: color_pr, field }) {
  const dispatch = useDispatch();

  const colorSelector = useMemo(() => createSelectedColorSelector(color_pr), [color_pr]);
  const color = useSelector(colorSelector, colorComparer);

  const activeRef = useRef(false);
  const [selected, setSelected] = useState(color);
  const selectedRef = useRef(selected);

  const { searchOptions } = useContext(facetsPanelConfig);

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  if (!color) {
    return null;
  }

  if (!activeRef.current && color.weight !== selected.weight) {
    setSelected(color);
  }

  const onChangeStarted = () => (activeRef.current = true);
  const onChange = ([, newWeight]) => setSelected(selected.clone({ weight: newWeight }));
  const onChangeFinished = () => {
    activeRef.current = false;
    const { term } = selectedRef.current;
    dispatch(replaceColor({ field, term }, { ...searchOptions, mayDiscardValue: true }));
  };

  const sliderProps = {
    field: color.color,
    selected: [0, selected.weight],
    mode: 'UpperBound',
    color: selected.colorStyle,
    onChange,
    onChangeStarted,
    onChangeFinished,
    key: `sliderstrip-for-${field}`,
  };
  const slider = <SliderStrip {...sliderProps} />;

  const discardColor = () => {
    dispatch(
      replaceColor(
        { field, term: selected.term, isSelected: false },
        { ...searchOptions, mayDiscardValue: true },
      ),
    );
  };

  return (
    <div className={`cm_color-slider${isLight(color.color) ? ' light' : ''}`}>
      <div className="discard" onClick={discardColor} />
      <div className="strip">{slider}</div>
      <ColorTile color={color.color} />
    </div>
  );
}

function colorComparer(prev, curr) {
  return prev && curr && prev.equals(curr);
}
