import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { garageEmptySelector } from 'Core/selectors/fitmentSearch/index.js';
import EmptyDropdown from './emptyDropdown.jsx';
import GarageContext from '../garageContext.ts';
import ListDropdown from './listDropdown.jsx';
import VehicleDropdowns from './vehicleDropdowns.jsx';

export default function BaseDropdown(props) {
  const isEmpty = useSelector(garageEmptySelector);
  const { isShowVehicleDropdowns } = useContext(GarageContext);

  const Component = isShowVehicleDropdowns ? VehicleDropdowns : isEmpty ? EmptyDropdown : ListDropdown;

  return <Component {...props} />;
}
