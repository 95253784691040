import { createContext, useContext, useMemo, useRef, useState, useEffect } from 'react';

import { useDropdown } from 'Core/hooks/index.js';
import { cloneSafe } from 'Utils/components.ts';
import isMobile from 'Vendor/isMobile.js';

import type { ChangeEvent, FocusEvent, KeyboardEvent, PropsWithChildren, InputHTMLAttributes } from 'react';
import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';

const stub = () => {
  /* stub */
};
const selectContext = createContext({
  activate: stub as (key: string) => void,
  reset: stub as () => void,
  getDisabled: (() => false) as (key: string) => boolean,
});

export interface Entry {
  value: string;
  term: string;
  selected: boolean;
  payload?: string | null;
  hitCount?: number;
  url?: string;
}

export interface Params {
  activeIndex: number;
  disabled?: boolean;
  entries: Entry[];
  onChange: (e: string | ChangeEvent<HTMLSelectElement>) => void;
  extraClassName: string;
  firstSelectedTerm?: string;
  hideNullOption?: boolean;
  loading?: boolean;
  selectedEntries: Entry[];
  showCheckboxes?: boolean;
  showHitCount?: boolean;
  index?: number;
  title: string;
  field: string;
  useNativeDropdown?: boolean;
  isDropdownVisible: boolean;
  isSearchable: boolean;
  filterInput: TemplateFunctionInvoker<void>;
}

export interface Props {
  template: TemplateFunction<Params>;
  field: string;
  title: string;
  entries: Entry[];
  disabled?: boolean;
  extraClassName?: string;
  hideNullOption?: boolean;
  loading?: boolean;
  onChange: (value: string) => void;
  showCheckboxes?: boolean;
  showHitCount?: boolean;
  index?: number;
  useNativeDropdown?: boolean;
  isSearchable?: boolean;
}

export default function Select({
  template,
  disabled: disabled_pr,
  entries,
  extraClassName = '',
  hideNullOption,
  loading,
  showCheckboxes,
  showHitCount,
  index,
  field,
  title,
  onChange: onChange_pr,
  useNativeDropdown: useNative,
  isSearchable = false,
}: Props) {
  const rootRef = useRef<HTMLElement>(null);
  const { activate, reset, getDisabled } = useContext(selectContext);
  const [filterValue, setFilterValue] = useState('');
  const [isFilterInputFocused, setIsFilterInputFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const disabled = disabled_pr || getDisabled(title);

  const useNativeDropdown = useNative ?? true;

  const {
    showDropdown,
    hideDropdown,
    DO_NOT_USE_visible: isDropdownVisible,
  } = useDropdown(disabled || useNativeDropdown ? { current: null } : rootRef, '.cm_select', {
    toggle: !isSearchable,
  });

  const selectedEntries = entries.filter((e) => e.selected) || [];
  const firstSelectedTerm = selectedEntries[0]?.term;

  const filteredEntries =
    isSearchable && filterValue
      ? entries.filter((e) => e.value.toLowerCase().includes(filterValue.toLowerCase()))
      : entries;

  useEffect(() => {
    if (selectedEntries.length) {
      setFilterValue('');
    }
  }, [selectedEntries.length]);

  useEffect(
    function scrollToActiveOption() {
      const optionToScroll = rootRef.current?.querySelector(`.option:nth-of-type(${activeIndex + 1})`);
      optionToScroll?.scrollIntoView({ block: 'nearest' });
    },
    [activeIndex],
  );

  const onFocus = (e: FocusEvent<HTMLElement>) => {
    if (isMobile && e.target instanceof HTMLSelectElement) {
      activate(title);
    }
  };

  const onBlur = (e: FocusEvent<HTMLElement>) => {
    if (isMobile && e.target instanceof HTMLSelectElement) {
      reset();
    }
  };

  const onChange = (e: string | ChangeEvent<HTMLSelectElement>) => {
    if (typeof e === 'string') {
      onChange_pr(e);
      hideDropdown();
      return;
    }
    onChange_pr(e.target.value);
    e.target.blur();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        setActiveIndex((prevIndex) => (prevIndex + 1) % filteredEntries.length);
        e.preventDefault();
        break;
      case 'ArrowUp':
        setActiveIndex((prevIndex) => (prevIndex - 1 + filteredEntries.length) % filteredEntries.length);
        e.preventDefault();
        break;
      case 'Enter':
        onChange_pr(filteredEntries[activeIndex].term);
        hideDropdown();
        setTimeout(() => {
          const currentInput = rootRef.current?.querySelector('input');
          currentInput?.blur();

          const nextInput = rootRef.current?.nextElementSibling?.querySelector('input');
          nextInput?.focus();
        }, 300);
        break;
      default:
        setActiveIndex(0);
        break;
    }
  };

  const filterInput = (template: TemplateFunction<void>) => {
    if (!isSearchable) {
      return null;
    }

    const selectedEntryIndex = entries.findIndex((entry) => entry.selected);

    const templateProps = template()?.props as InputHTMLAttributes<HTMLInputElement>;

    const props = {
      ...templateProps,
      type: 'text',
      autoComplete: 'off',
      id: `cm_filter-input-${field}`,
      className: `cm_select__pretty ${templateProps?.className}`,
      placeholder: templateProps?.placeholder ?? title,
      value: isFilterInputFocused ? filterValue : selectedEntries?.map((e) => e.value).join(', ') || '',
      disabled,
      key: field,
      onChange: (e: ChangeEvent<HTMLInputElement>) => setFilterValue(e.target.value),
      onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e),
      onFocus: () => {
        if (selectedEntryIndex >= 0) {
          setActiveIndex(selectedEntryIndex);
        }

        setIsFilterInputFocused(true);
        showDropdown();
      },
      onBlur: () => {
        if (selectedEntryIndex < 0) {
          setActiveIndex(0);
        }

        setIsFilterInputFocused(false);
        setTimeout(() => setFilterValue(''), 200);
      },
    };

    return (<input {...props} />) as TemplateResult;
  };

  const component = template.call({
    activeIndex,
    disabled,
    entries: filteredEntries,
    extraClassName,
    firstSelectedTerm,
    hideNullOption,
    loading,
    selectedEntries,
    showCheckboxes,
    showHitCount,
    index,
    field,
    title,
    onChange,
    useNativeDropdown,
    isDropdownVisible,
    isSearchable,
    filterInput,
  });
  return cloneSafe(component, rootRef, {
    appendedClasses: 'cm_select',
    onFocus,
    onBlur,
  });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function SelectContextProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const [active, setActive] = useState<string | null>(null);
  const context = useMemo(
    () => ({
      activate: (key: string) => setActive(key),
      reset: () => setActive(null),
      getDisabled: (key: string) => !!active && key !== active,
    }),
    [active],
  );
  return <selectContext.Provider value={context}>{children}</selectContext.Provider>;
}

type PropsWithoutTemplate = Omit<Props, 'template'> | null;

export function createSelectRepeater(propsArray: PropsWithoutTemplate[]): RepeaterFunctionInvoker<Params> {
  return propsArray.map(
    (props: PropsWithoutTemplate): TemplateFunctionInvoker<Params> =>
      props ? (templ) => (<Select template={templ} {...props} />) as TemplateResult : () => null,
  );
}
