import _groupBy from 'lodash-es/groupBy.js';
import _transform from 'lodash-es/transform.js';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { assignLocation } from 'Core/actions/redirect.ts';
import { autocompleteTotalHitsSelector } from 'Core/selectors/autocomplete.ts';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import BrowseAllButton from './dropdownItems/browseAllButton.jsx';
import Section, { Params as SectionParams, Props as SectionProps } from './dropdownItems/section.tsx';

import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { AutocompleteItem, FacetValueBase } from 'Models/index.ts';
import type { AutocompleteItemType } from 'Models/autocompleteItem.ts';

import 'Core/hooks/dropdown.scss';

const { order, facetValueOrder } = requestConfig.autocomplete as {
  order: AutocompleteItemType[];
  facetValueOrder: string[];
};

export type Params = {
  sections: RepeaterFunctionInvoker<unknown>;
  hasProductResults: boolean;
  browseAllButton: TemplateFunctionInvoker<unknown>;
};

type Props = {
  template: TemplateFunction<Params>;
  hideDropdown: boolean;
  items: AutocompleteItem[];
  query: string;
  showDefaultSuggestions: boolean;
  setQuery: (query: string) => void;
  setSelection: (selection: FacetValueBase[]) => void;
  submit: () => void;
  onDropdownItemsReceived?: () => void;
};

const SearchDropdown: FC<Props> = ({
  template,
  hideDropdown,
  items,
  query,
  showDefaultSuggestions,
  setQuery,
  setSelection,
  submit,
  onDropdownItemsReceived,
}) => {
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(selectedVehicleSelector);
  const totalHits = useSelector(autocompleteTotalHitsSelector);

  useEffect(() => {
    if (items.length) {
      onDropdownItemsReceived?.();
    }
  });

  if (hideDropdown) {
    const component = template.call({ sections: [], hasProductResults: false, browseAllButton: () => null });
    return cloneSafe(component, null, {
      appendedClasses: ['cm_autocomplete', 'cm_autocomplete__empty', 'empty'],
    });
  }

  const browseAllButton: TemplateFunctionInvoker<unknown> = showDefaultSuggestions
    ? () => null
    : (templ) =>
        (<BrowseAllButton {...{ template: templ, query, submit, key: 'browse-all' }} />) as TemplateResult;

  const hasProductResults = !!items.filter((item) => item.type === 'product').length;

  const sections: RepeaterFunctionInvoker<SectionParams> = order
    .reduce(
      (propsArr, sectionsGroup) => {
        const itemsData = items.filter((item) => item.type === sectionsGroup);
        switch (sectionsGroup) {
          case 'text': {
            return [
              ...propsArr,
              {
                query,
                itemsData,
                templateName: sectionsGroup,
                title: !query ? 'Trending' : '',
                itemToClickHandler:
                  ({ textValue }) =>
                  () =>
                    textValue && setQuery(textValue.text),
              },
            ];
          }
          case 'product': {
            return [
              ...propsArr,
              ...itemsToGroupedProps(itemsData, {
                vehicleString: selectedVehicle.toString(),
                totalHits,
                templateName: sectionsGroup,
                itemToClickHandler:
                  ({ searchItem: { redirectUrl } = {} }) =>
                  () =>
                    redirectUrl && dispatch(assignLocation(redirectUrl)),
                browseAllButton,
              }),
            ];
          }
          case 'facetValue': {
            const { ordered, rest } = orderedFacetSectionProps(itemsData, {
              templateName: sectionsGroup,
              itemToClickHandler:
                ({ facetValue }) =>
                () => {
                  if (facetValue) {
                    facetValue.redirectUrl
                      ? dispatch(assignLocation(facetValue.redirectUrl))
                      : setSelection([facetValue]);
                  }
                },
            });
            return [
              ...propsArr,
              ...facetValueOrder.map((title) => ordered[title] || { title, itemsData: [] }),
              ...rest,
            ];
          }
          default:
            console.error(`AutocompleteOrder includes '${sectionsGroup}' that is unknown`);
            return propsArr;
        }
      },
      [] as Omit<SectionProps, 'template'>[],
    )
    .map(
      (sectionProps) => (template) =>
        (
          <Section
            {...{
              template,
              key: sectionProps.title || 'text',
              ...sectionProps,
            }}
          />
        ) as TemplateResult,
    );

  return cloneSafe(template.call({ sections, hasProductResults, browseAllButton }), null, {
    appendedClasses: 'cm_autocomplete',
  });
};

export default SearchDropdown;

function itemsToGroupedProps(
  items: AutocompleteItem[],
  commonProps: Omit<SectionProps, 'template' | 'itemsData' | 'title'>,
) {
  const groupedItems = _groupBy(items, 'suggestionGroup');
  return _transform(
    groupedItems,
    (result, itemsData, title) => {
      result.push({ ...commonProps, itemsData, title });
    },
    [] as Omit<SectionProps, 'template'>[],
  );
}

function orderedFacetSectionProps(
  items: AutocompleteItem[],
  commonProps: Omit<SectionProps, 'template' | 'itemsData' | 'title'>,
) {
  return itemsToGroupedProps(items, commonProps).reduce(
    (result, props) => {
      if (props.title && facetValueOrder.includes(props.title)) {
        result.ordered[props.title] = props;
      } else {
        result.rest.push(props);
      }
      return result;
    },
    {
      ordered: {} as Record<string, Omit<SectionProps, 'template'>>,
      rest: [] as Omit<SectionProps, 'template'>[],
    },
  );
}
