import { createAction } from '@reduxjs/toolkit';

import { keysSelector } from 'Modules/converter/index.js';

import type { PartialPath, To } from 'history';

export const assignLocation = createAction<string>('ASSIGN_LOCATION');

export const replaceLocation = createAction<string>('REPLACE_LOCATION');

export const goBackInHistory = createAction('GO_BACK_IN_HISTORY');

export const pushToHistory = createAction('PUSH_TO_HISTORY', prepareHistoryTo);

export const replaceInHistory = createAction('REPLACE_IN_HISTORY', prepareHistoryTo);

// History package uses object spread internally, but if we pass URL instance as a `to` argument,
// it spreads to an empty object. Thus we need to pick needed properties manually
const selectPartialPath = keysSelector<PartialPath, PartialPath>('pathname', 'search', 'hash');
function prepareHistoryTo(to: To, state: string | null) {
  return { payload: { state, location: typeof to === 'string' ? to : selectPartialPath(to) } };
}
