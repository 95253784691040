import { createAction } from '@reduxjs/toolkit';

import type { ServerModel } from 'Modules/serverApi/types.ts';

export const setFitmentSearchResponseFacets = createAction(
  'SET_FITMENT_SEARCH_RESPONSE_FACETS',
  (facets: ServerModel.FacetCategory[], isAutoVehicleSelectionDisabled: boolean, isPartialMode: boolean) => ({
    payload: facets,
    meta: { isAutoVehicleSelectionDisabled, isPartialMode },
  }),
);

export const setFitmentTableResponse = createAction<ServerModel.FitmentsResponseType>(
  'SET_FITMENT_TABLE_RESPONSE',
);
