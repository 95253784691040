import { combineReducers } from 'redux';

import { replaceVehicleValue } from 'Core/actions/fitmentSearch/index.js';
import { viewChangeRequested } from 'Core/actions/show.ts';
import { setResponse } from 'Core/actions/response.ts';
import { createReducersFromShorthands, fromPayload } from 'Core/reducers/common.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { loadView } from 'Utils/userStorage.js';

const initStates = {
  facetsLoading: false,
  infiniteScrollLoading: false,
  resultsLoading: false,
  view: loadView() || uiConfig.defaultView,
};

const makeDefaultLoadingReducer =
  ({ sendRequestIgnoreTypes, discardTypes }) =>
  (state, action) => {
    // TODO: remove when sendRequest will be fully moved to action.meta
    const sendRequest = action.sendRequest ?? action.meta?.sendRequest;
    if (sendRequest) {
      return sendRequestIgnoreTypes === 'all' || sendRequestIgnoreTypes.includes(action.type) ? state : true;
    }
    return discardTypes.includes(action.type) ? false : state;
  };

const facetsLoadingTypes = {
  discardTypes: [setResponse.type, 'SKIP_REQUEST'],
  sendRequestIgnoreTypes: [
    'LOAD_MORE',
    'SET_PAGE',
    'SET_PAGE_SIZE',
    'SET_SORT',
    fitmentSearchConfig.isVehicleSelectionIsolated && replaceVehicleValue.type,
  ].filter(Boolean),
};

const infiniteScrollLoadingTypes = {
  discardTypes: [setResponse.type, 'SKIP_REQUEST'],
  sendRequestIgnoreTypes: 'all',
};

const loadingTypes = {
  discardTypes: [setResponse.type, 'SKIP_REQUEST'],
  sendRequestIgnoreTypes: [
    'LOAD_MORE',
    fitmentSearchConfig.isVehicleSelectionIsolated && replaceVehicleValue.type,
  ].filter(Boolean),
};

export default combineReducers(
  createReducersFromShorthands(
    {
      facetsLoading: {
        default: makeDefaultLoadingReducer(facetsLoadingTypes),
      },
      infiniteScrollLoading: {
        LOAD_MORE: () => true,
        default: makeDefaultLoadingReducer(infiniteScrollLoadingTypes),
      },
      resultsLoading: {
        default: makeDefaultLoadingReducer(loadingTypes),
      },
      view: {
        [viewChangeRequested.type]: fromPayload,
      },
    },
    initStates,
  ),
);
