import { Vehicle } from 'Models';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { loadSelectedVehicle } from 'Utils/userStorage.js';
import { default as getRequestFromUri } from './getRequestFromUri.js';

export default function getVehicleFromUri() {
  const fitmentFields = fitmentSearchConfig.fields;
  const selectionFromUri = getRequestFromUri(window.location).selection.filter((v) =>
    fitmentFields.includes(v.field),
  );

  let isVehicleNew = false;

  if (selectionFromUri.length) {
    const vehicleFromUri = new Vehicle(selectionFromUri, fitmentFields);
    isVehicleNew = !loadSelectedVehicle().equals(vehicleFromUri);
  }

  return { isVehicleNew, selection: selectionFromUri };
}
