import Cookies from 'universal-cookie';

import { VehicleCollection, Vehicle } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import keys from './userStorageKeys.js';

const cookies = new Cookies();
export { keys };

// ---- history methods ----

export const loadQueriesHistory = () => {
  try {
    const serializedState = getItem(keys.queriesHistory);
    if (!serializedState || !Array.isArray(serializedState)) {
      return [];
    }
    return serializedState;
  } catch (err) {
    console.warn(err);
    return [];
  }
};

export const loadProductsHistory = () => {
  try {
    const serializedState = getItem(keys.productsHistory);
    if (!serializedState || !Array.isArray(serializedState)) {
      return [];
    }
    const url = location.href;
    return serializedState.filter((record) => record.url !== url);
  } catch (err) {
    console.warn(err);
    return [];
  }
};

const productSize = 3;
export const addProductsHistory = (product) => {
  const productsHistory = loadProductsHistory();
  if (productsHistory.length >= productSize) {
    productsHistory.pop();
  }
  const url = location.href;
  productsHistory.unshift({ ...product, url });
  setItem(keys.productsHistory, productsHistory);
};

// ---- compared items methods ----

export const loadCompared = () => {
  try {
    const compared = getItem(keys.compared);
    return compared && Array.isArray(compared) ? compared : [];
  } catch (err) {
    console.warn(err);
    return [];
  }
};

// ---- garage & model methods ----

export function getVehicleData(key) {
  const expires = fitmentSearchConfig.vehicleDataExpires
    ? new Date(Date.now() + fitmentSearchConfig.vehicleDataExpires * 60 * 1000)
    : null;

  return getItem(key, !!fitmentSearchConfig.vehicleDataExpires, expires);
}

export function setVehicleData(key, value) {
  if (!fitmentSearchConfig.doNotSaveSelectedVehicle) {
    const expires = fitmentSearchConfig.vehicleDataExpires
      ? new Date(Date.now() + fitmentSearchConfig.vehicleDataExpires * 60 * 1000)
      : null;

    setItem(key, value, !!fitmentSearchConfig.vehicleDataExpires, expires);
  }
}

export function removeVehicleData(key) {
  removeItem(key, !!fitmentSearchConfig.vehicleDataExpires);
}

export function loadSelectedVehicle() {
  let data;
  try {
    data = getVehicleData(keys.vehicle);
  } catch (err) {
    console.warn('Failed to take vehicle from userStorage', err);
    data = null;
  }
  return data ? deserializeVehicle(data) : Vehicle.null;
}

export function loadGarageData() {
  let data;
  try {
    data = getVehicleData(keys.garageData);
  } catch (err) {
    console.warn('Failed to take garage data from userStorage', err);
    data = null;
  }
  return data && data.length ? new VehicleCollection(data.map(deserializeVehicle)) : VehicleCollection.empty;
}

export function getForceVehicleSelectionLater() {
  return getItem(keys.forceVehicleSelectionLater, true);
}

export function setForceVehicleSelectionLater() {
  const expires = new Date(Date.now() + fitmentSearchConfig.forceVehicleSelectionInterval * 60 * 1000);
  setItem(keys.forceVehicleSelectionLater, true, true, expires);
}

function deserializeVehicle(data) {
  // it's used for init, so we're creating vehicle with fitmentSearchConfig fields
  return new Vehicle(data, fitmentSearchConfig.fields);
}

// ---- customer methods ----

export function isLoggedInStateChanged(isLoggedInNow) {
  const wasLoggedIn = getItem(keys.isLoggedIn);
  setItem(keys.isLoggedIn, isLoggedInNow);
  return wasLoggedIn !== isLoggedInNow;
}

// ---- view methods ----

export function loadView() {
  try {
    return getItem(keys.view);
  } catch (err) {
    console.warn('Failed to take view from userStorage', err);
    return null;
  }
}

// ---- common methods ----

function cookieGet(key) {
  const value = cookies.get(key, { doNotParse: true });
  if (value && value !== 'undefined') {
    return value;
  }
  return null;
}

export function getItem(key, useCookie, expires) {
  if (uiConfig.cookies.use || useCookie) {
    const value = cookieGet(key);
    if (expires && value) {
      setItem(key, value, expires);
    }
    return value ? JSON.parse(value.replace(/\+/g, ' ')) : null; // workaround for php '+' escaping
  }
  const value = window.localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

const defaultCookieExpires = new Date(Date.now() + 5 * 365 * 24 * 60 * 60 * 1000); // 5 years long

export function setItem(key, value, useCookie, expires) {
  if (uiConfig.cookies.use || useCookie) {
    cookies.set(key, value, { expires: expires ?? defaultCookieExpires, path: '/' });
  } else {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export function removeItem(key, useCookie) {
  if (uiConfig.cookies.use || useCookie) {
    cookies.set(key, '', { path: '/', expires: new Date() });
  } else {
    window.localStorage.removeItem(key);
  }
}
