import { dialogClosed } from 'Core/actions/dialog.ts';
import { createReducer } from 'Core/reducers/common.ts';
import { fitmentFieldsReducer } from 'Core/reducers/fitmentSearch/common.js';

const fieldsInitState = fitmentFieldsReducer(undefined, {});

export default createReducer(
  {
    [dialogClosed]: () => fieldsInitState,
    default: fitmentFieldsReducer,
  },
  fieldsInitState,
);
