import { combineReducers } from 'redux';
import { createNextState } from '@reduxjs/toolkit';

import { createIsolatedReducer } from 'Core/reducers/common.ts';
import { FacetValue } from 'Models/index.ts';
import { equalByMany } from 'Utils/array.js';
import lastSearchOptions from './lastSearchOptions.ts';
import request from './request/index.js';
import response from './response.js';
import retriesCount from './retriesCount.js';

const search = createIsolatedReducer(
  null,
  combineReducers({
    lastSearchOptions,
    request,
    response,
    retriesCount,
  }),
);

const initState = {};

export default (state = initState, action) =>
  search(fixRequestDueToResponse(state, action.mayDiscardValue || action.meta?.mayDiscardValue), action);

function fixRequestDueToResponse(state, fix) {
  return createNextState(state, (draft) => {
    if (!fix) {
      return;
    }

    const { selection } = draft.request;
    const responseSelection = draft.response.facets.selection;

    if (!equalByMany(selection, responseSelection, FacetValue.termKey, 'isUnique')) {
      draft.request.selection = responseSelection;
    }
    draft.request.query = draft.response.query;
  });
}
