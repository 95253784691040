import { combineReducers } from 'redux';

import {
  discardVehicleValue,
  garageCleanupRequested,
  removalFromGarageRequested,
  replaceVehicleValue,
  vehicleDiscardingRequested,
  vehicleSelected,
  vehicleExtraFieldsDiscardingRequested,
} from 'Core/actions/fitmentSearch/index.js';
import { resetRequest } from 'Core/actions/request.js';
import { createReducersFromShorthands } from 'Core/reducers/common.ts';
import { getVehicleFromUri } from 'Modules/converter/index.js';
import { loadSelectedVehicle } from 'Utils/userStorage.js';
import { updateVehicleSelection } from './common.js';

const vehicleFromUriSelection = getVehicleFromUri().selection;

const initStates = {
  selection: vehicleFromUriSelection.length ? vehicleFromUriSelection : loadSelectedVehicle().selection,
};

export default combineReducers(
  createReducersFromShorthands(
    {
      selection: {
        [garageCleanupRequested]: () => [],
        [vehicleDiscardingRequested]: () => [],
        [resetRequest().type]: (state, { discardUserPreselection }) => (discardUserPreselection ? [] : state),
        [vehicleExtraFieldsDiscardingRequested]: (state, { meta: { fitmentBaseFields } }) =>
          state.filter((v) => fitmentBaseFields.includes(v.field)),
        [removalFromGarageRequested]: (state, { meta }) => (meta.wasSelected ? [] : state),
        [replaceVehicleValue]: (state, { payload, meta: { fitmentFields } }) =>
          updateVehicleSelection(state, payload, fitmentFields),
        [discardVehicleValue]: (state, { payload }) => state.filter((facet) => facet.field !== payload.field),
        [vehicleSelected]: (_state, { payload: { selection } }) => selection,
      },
    },
    initStates,
  ),
);
