import { useRef } from 'react';

import scheduleIdleCallback from 'Modules/scheduleIdleCallback.js';

export default function useIdleEffect(func, ...effectOpts) {
  const { deps, opts } = resolveOpts(effectOpts);

  const flushRef = useRef();
  const depsRef = useRef([]);

  if (deps && areDepsEqual(depsRef.current, deps)) {
    return;
  }

  depsRef.current = deps;
  const flush = flushRef.current;
  if (typeof flush !== 'undefined') {
    flush();
  }
  scheduleIdleCallback(() => (flushRef.current = func()), opts);
}

function resolveOpts(opts) {
  if (!opts.length) {
    return {};
  }
  const resolved = {};
  if (Array.isArray(opts[0])) {
    resolved.deps = opts[0]; // eslint-disable-line prefer-destructuring
  } else if (typeof opts[0] === 'object') {
    resolved.opts = opts[0]; // eslint-disable-line prefer-destructuring
  } else {
    console.error(
      'Wrong optional arguments for requestIdleCallback: ' +
        'expected array of deps or options for idleCallback or both but received ',
      opts,
    );
  }
  if (resolved.deps && typeof opts[1] === 'object') {
    resolved.opts = opts[1]; // eslint-disable-line prefer-destructuring
  }
  return resolved;
}

function areDepsEqual(prevDeps, newDeps) {
  return newDeps.every((dep, i) => Object.is(dep, prevDeps[i]));
}
