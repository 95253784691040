import useEventListener from '@use-it/event-listener';
import { useCallback, useEffect, useState } from 'react';

const resizeObserverSupported = !!window.ResizeObserver;

const defaultRef = { current: window.document.body };
export default function useResizeState(getState, targetRef = defaultRef, comparer = defaultComparer) {
  const [state, setState] = useState(getState());
  const handler = useCallback(() => {
    const newState = getState(state);
    if (!comparer(state, newState)) {
      setState(newState);
    }
  }, [comparer, getState, state]);

  // initialize state for both ResizeObserver and window.resize
  useEffect(handler, [handler]);

  useEffect(function subscribeToResize() {
    const target = targetRef.current;
    if (!target || !resizeObserverSupported) {
      return;
    }
    const observer = new ResizeObserver(handler);
    observer.observe(target);
    return () => observer.disconnect();
  });

  // IE && Safari <= 13 fallback
  useEventListener(
    'resize',
    useCallback(() => handler(), [handler]),
    resizeObserverSupported ? null : window,
  );

  // we expect that the state may be changed only on window resize and do not expose setState
  return state;
}

function defaultComparer(state, newState) {
  return state === newState;
}
