import { epicFromHandlers } from 'Core/epics/common.js';
import { vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import { Vehicle } from 'Models/index.ts';

export default epicFromHandlers({
  [vehicleSelected]: handleKlaviyo,
});

async function handleKlaviyo({ state, action: { payload } }) {
  const selectedVehicle = payload instanceof Vehicle ? payload : selectedVehicleSelector(state);

  if (window.klaviyo && selectedVehicle.notNull) {
    const vehicleMap = Object.fromEntries(selectedVehicle.selection.map((v) => [v.field, v.value]));
    window.klaviyo.push(['identify', vehicleMap]);
    window.klaviyo.push(['track', 'Vehicle Selected', vehicleMap]);
  }
}
