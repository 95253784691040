import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dialogOpened, dialogClosed } from 'Core/actions/dialog.ts';
import { createDialogOpenedSelector } from 'Core/selectors/dialog.js';
import { cloneSafe } from 'Utils/components.ts';

export default function DialogButton({
  template,
  dialogName,
  metadata = {},
  templateParams = {},
  onClick: onClick_pr = null,
}) {
  const dispatch = useDispatch();
  const opened = useSelector(useMemo(() => createDialogOpenedSelector(dialogName), [dialogName]));

  const onClick = () => {
    if (typeof onClick_pr === 'function') {
      onClick_pr();
    }

    if (!opened) {
      dispatch(dialogOpened(dialogName, metadata));
    } else {
      dispatch(dialogClosed(dialogName));
    }
  };

  const component = template.call({ ...templateParams, isDialogOpened: opened });
  return cloneSafe(component, null, { onClick });
}
