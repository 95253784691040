import { createReducer } from '@reduxjs/toolkit';

import {
  AutocompleteResponse,
  clearAutocompleteQuery,
  setAutocompleteResponse,
} from 'Core/actions/autocomplete.ts';
import { fromPayload } from 'Core/reducers/common.ts';

export const defaultAutocompleteResponseState = {
  items: null,
  query: '',
  totalHits: 0,
} as AutocompleteResponse;

export default createReducer(defaultAutocompleteResponseState, (builder) =>
  builder
    .addCase(clearAutocompleteQuery, () => defaultAutocompleteResponseState)
    .addCase(setAutocompleteResponse, fromPayload),
);
