import advancedColorFacet from './advancedColorFacet.jsx';
import selectFacet from './selectFacet.jsx';
import simpleFacet from './simpleFacet.jsx';
import sizeFacet from './sizeFacet.jsx';
import rangedFacet from './rangedFacet.tsx';
import toggleFacet from './toggleFacet.tsx';

export default {
  advancedColorFacet,
  selectFacet,
  simpleFacet,
  sizeFacet,
  rangedFacet,
  toggleFacet,
};
