import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'Components/smallComponents/loading.tsx';
import { loadMore as loadMoreAction } from 'Core/actions/request.js';
import {
  allItemsReceivedSelector,
  nextPageSizeSelector,
  noItemsReceivedSelector,
} from 'Core/selectors/search.js';
import { infiniteScrollLoadingSelector, resultsLoadingSelector } from 'Core/selectors/show.ts';
import { cloneSafe } from 'Utils/components.ts';

export default function LoadMoreController({
  template,
  isInstant,
  isInfiniteScrollDisabled,
  isLoadMoreFailed,
}) {
  const [isActive, setIsActive] = useState();
  const rootRef = useRef();

  const dispatch = useDispatch();

  const loadMore = useCallback(() => dispatch(loadMoreAction()), [dispatch]);

  const isInfiniteScrollLoading = useSelector(infiniteScrollLoadingSelector);
  const isResultsLoading = useSelector(resultsLoadingSelector);
  const isLoading = isInfiniteScrollLoading || isResultsLoading;

  const isAllItemsReceived = useSelector(allItemsReceivedSelector);
  const isNoItemsReceived = useSelector(noItemsReceivedSelector);

  const pageNumber = useSelector((state) => state.search.request.pageNumber);
  const prevPageNumber = useRef(pageNumber);

  const nextPageSize = useSelector(nextPageSizeSelector);

  // animationFrame loop has to be started just once but we need to update callback in it.
  // using ref we may not to change frame() instance but are still able to vary the code within
  const inFrameCallbackRef = useRef();
  inFrameCallbackRef.current = useCallback(() => {
    const root = rootRef.current;
    // if the root or its parent has `{ display: none }`, root.offsetParent is null
    if (!root?.offsetParent) {
      return;
    }
    const clientRectTop = root.getBoundingClientRect().top;
    const isCloseToViewport = clientRectTop <= window.innerHeight;
    if (
      isActive &&
      !isLoadMoreFailed &&
      !isLoading &&
      !isAllItemsReceived &&
      isCloseToViewport &&
      nextPageSize > 0
    ) {
      loadMore();
    }
  }, [isActive, isAllItemsReceived, isLoading, loadMore, isLoadMoreFailed, nextPageSize]);

  const activate = useCallback(() => {
    if (isInfiniteScrollDisabled) {
      loadMore();
      return;
    }

    // if element has not been rendered yet
    if (!rootRef.current) {
      return;
    }
    setIsActive(true);
    window.requestAnimationFrame(function frame() {
      inFrameCallbackRef.current();
      window.requestAnimationFrame(frame);
    });
  }, [isInfiniteScrollDisabled, loadMore]);

  useEffect(() => {
    if (pageNumber < prevPageNumber.current) {
      setIsActive(false);
    }
    prevPageNumber.current = pageNumber;

    if (isInstant && !isActive) {
      activate();
    }
  }, [activate, isActive, isInstant, pageNumber]);

  const loading = <Loading loading={isLoading} key="loading" />;
  const loadMoreButton = (templ) =>
    cloneSafe(templ.call({ activate, nextPageSize }), null, { key: 'button' });

  const component = template.call({
    loading,
    loadMoreButton,
    isNotAllItemsReceived: !isNoItemsReceived && !isAllItemsReceived,
    isActive,
  });
  return cloneSafe(component, rootRef);
}
