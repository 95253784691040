import { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'Components/smallComponents/loading.tsx';
import Select, { SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { discardField, discardManyFields, replaceValue, toggle } from 'Core/actions/request.js';
import { useCollapsible } from 'Core/hooks/collapsible.ts';
import { createFilteredFacetsSelector } from 'Core/selectors/search.js';
import { resultsLoadingSelector } from 'Core/selectors/show.ts';
import isMobile from 'Vendor/isMobile.js';
import { cloneSafe } from 'Utils/components.ts';

import { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type { RepeaterFunctionInvoker, TemplateFunction } from 'Components/types.ts';
import type { FacetCollection } from 'Models/index.ts';

export type Params = {
  selects: RepeaterFunctionInvoker<SelectParams>; // TODO: make params
  hasSelection: boolean;
  discardFields: () => void;
  isBarCollapsed: boolean;
  collapseBar: () => void;
};

interface Props {
  template: TemplateFunction<Params>;
  fields: string[];
  initCollapsed?: boolean;
  useNativeDropdown?: boolean;
  minFacetCount?: number;
}

const FacetBar: FC<Props> = ({ template, fields, initCollapsed, useNativeDropdown, minFacetCount = 1 }) => {
  const dispatch = useDispatch();

  const rootRef = useRef<HTMLElement>(null);
  const [isBarCollapsed, collapseBar] = useCollapsible(rootRef, null, initCollapsed ?? isMobile);

  const facets = useSelector(createFilteredFacetsSelector(fields)) as FacetCollection;

  if (!facets?.length || facets.length < minFacetCount) {
    return null;
  }

  const discardFields = () => dispatch(discardManyFields(fields));

  const loading = <Loading selector={resultsLoadingSelector} key="loading" />;

  const selects = facets.map((facet) => (templ: TemplateFunction<SelectParams>) => {
    const { isSingleValue } = facet;

    const props = {
      template: templ,
      entries: facet.facetedValues.map((v) => ({
        value: v.value,
        term: v.term,
        selected: !!v.isSelected,
        hitCount: v.hitCount,
      })),
      extraClassName: 'cm_facet_select',
      field: facet.field,
      title: facet.name,
      hideNullOption: !isSingleValue,
      showCheckboxes: !isSingleValue,
      useNativeDropdown: useNativeDropdown ?? isSingleValue,
      showHitCount: false,
      key: `facet-bar-select-${facet.field}`,
      onChange: (term: string) =>
        dispatch(
          !term
            ? discardField(facet.field, { mayDiscardValue: true })
            : !isSingleValue
              ? toggle({ field: facet.field, term }, { isSingleValue, mayDiscardValue: true })
              : replaceValue({ field: facet.field, term }, { mayDiscardValue: true }),
        ),
    };
    return <Select {...props} />;
  }) as RepeaterFunctionInvoker<SelectParams>;

  const component = template.call({
    selects,
    hasSelection: facets.selection.length > 0,
    discardFields,
    isBarCollapsed,
    collapseBar,
  });

  return (
    <SelectContextProvider>
      {cloneSafe(component, rootRef, { appendedChildren: loading })}
    </SelectContextProvider>
  );
};

export default FacetBar;
