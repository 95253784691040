const lightnessThreshold = 0.9;

function getLightness(color) {
  const bigint = parseInt(color, 16);
  const rgb = [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  return (Math.min(...rgb) + Math.max(...rgb)) / 255 / 2;
}

export default function isLight(color) {
  return getLightness(color) > lightnessThreshold;
}
