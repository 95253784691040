import { SearchItemData, SearchItemDataCollection } from 'Models/index.ts';
import { keys, loadCompared, loadQueriesHistory, removeItem, setItem } from 'Utils/userStorage.js';

// ---- search history actions ----

const historySize = 5;

export function setQueriesHistory(query) {
  return (dispatch) => {
    const queriesHistory = loadQueriesHistory().filter((v) => v !== query);

    queriesHistory.unshift(query);
    if (queriesHistory.length > historySize) {
      queriesHistory.pop();
    }

    setItem(keys.queriesHistory, queriesHistory);

    const action = {
      type: 'SET_QUERIES_HISTORY',
      queriesHistory,
    };
    dispatch(action);
  };
}

export const clearQueriesHistory = () => {
  removeItem(keys.queriesHistory);
  return { type: 'CLEAR_QUERIES_HISTORY' };
};

export const clearHistory = () => {
  removeItem(keys.queriesHistory);
  removeItem(keys.productsHistory);
  return { type: 'CLEAR_HISTORY' };
};

// ---- compared items actions ----

export const toggleCompared = (item) => (dispatch) => {
  const compared = loadCompared();

  const isItemsUnequal = (i) => SearchItemData.getFromRaw(i, 'key') !== item.key;
  const newCompared = compared.every(isItemsUnequal)
    ? [...compared, item.plainObject]
    : compared.filter(isItemsUnequal);

  setItem(keys.compared, newCompared);
  dispatch({
    type: 'SET_COMPARED',
    compared: new SearchItemDataCollection(newCompared, 'compared'),
  });
};

export const clearCompared = () => (dispatch) => {
  setItem(keys.compared, null);
  dispatch({ type: 'SET_COMPARED', compared: new SearchItemDataCollection([], 'compared') });
};
