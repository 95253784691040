import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useCollapsible } from 'Core/hooks/index.js';
import {
  createFitmentSearchResponseFacetsSelector,
  isVehicleSelectedSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { createFacetCollectionSelector } from 'Modules/selectors.js';
import ActualDropdowns from './actualDropdowns.tsx';
import layoutType from './layoutType.ts';
import StubDropdowns from './stubDropdowns.tsx';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type {
  Props as FacetToggleProps,
  Params as FacetToggleParams,
} from 'Components/smallComponents/facetToggle.tsx';
import type { RepeaterFunctionInvoker, TemplateFunction, TemplateFunctionInvoker } from 'Components/types.ts';
import type { Selector } from 'Core/store.ts';
import type { FacetCollection } from 'Models/index.ts';
import type { VehicleValue } from 'Models/vehicle.ts';
import type { CloneSafeOptionsType } from 'Utils/components.ts';

export type Params = {
  name: string;
  selects: RepeaterFunctionInvoker<SelectParams>;
  vehicleString: string;
  vehicleValues: VehicleValue[];
  vehicle: Record<string, string>;
  allowToSetVehicle: boolean;
  allowToDiscardVehicle: boolean;
  aboutToChange: boolean;
  selectVehicle: () => void;
  openDialog: () => void;
  changeVehicle: () => void;
  facetToggle: TemplateFunctionInvoker<FacetToggleParams>;
  discardVehicle: () => void;
  discardExtraFields: () => void;
  fitmentSearchTitle: string;
  template: 'active';
  isVehicleSelected: boolean;
  isVehicleSelectionForced: boolean;
  selection: Record<string, string>;
} & ReturnType<typeof useCollapsing>;

type Props = {
  template: TemplateFunction<Params>;
  name: string;
  appendedClasses: CloneSafeOptionsType['appendedClasses'];
  aboutToChange: boolean;
  fields?: string[];
  initCollapsed: boolean;
  isolatedKey: IsolatedKey;
  layoutState: layoutType;
  onSelectVehicle: () => void;
  onChangeVehicle: () => void;
  openDialog: () => void;
  rootRef: RefObject<HTMLElement>;
  useNativeDropdown?: boolean;
  facetToggleConfig?: FacetToggleProps;
  useSearchableDropdown?: boolean;
  globalVehicleDiscardEnabled?: boolean;
  redirectUrl?: string;
  isAutoVehicleSelectionDisabled?: boolean;
  doNotRedirectOnVehicleSelect?: true;
  isVehicleSelectionForced: boolean;
};

export type InnerProps = Omit<Props, 'initCollapsed' | 'layoutState'> & {
  collapsing: ReturnType<typeof useCollapsing>;
  facets: FacetCollection;
  isVehicleSelected: boolean;
};

export default function VehicleDropdowns({ initCollapsed, layoutState, ...props }: Props) {
  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const facets = useFacets(props.isolatedKey, props.fields);

  const collapsing = useCollapsing(
    props.rootRef,
    isVehicleSelected,
    layoutState,
    initCollapsed,
    props.aboutToChange,
  );

  const extraProps = { collapsing, facets, isVehicleSelected };

  const Component = facets.length ? ActualDropdowns : StubDropdowns;
  return <Component {...props} {...extraProps} />;
}

function useFacets(isolatedKey: IsolatedKey, fields?: string[]) {
  return useSelector(
    useMemo(
      (): Selector<FacetCollection> =>
        fields
          ? createFacetCollectionSelector(
              createFitmentSearchResponseFacetsSelector(isolatedKey),
              (facets: FacetCollection) => facets.filter((facet) => fields.includes(facet.field)),
            )
          : createFitmentSearchResponseFacetsSelector(isolatedKey),
      [fields, isolatedKey],
    ),
  );
}

function useCollapsing(
  rootRef: RefObject<HTMLElement>,
  isVehicleSelected: boolean,
  layoutState: layoutType,
  initCollapsed: boolean,
  aboutToChange: boolean,
) {
  const disableCollapse = layoutState !== layoutType.column;
  const [collapsed, toggleCollapsed] = useCollapsible(
    rootRef,
    null,
    aboutToChange ? false : isVehicleSelected || initCollapsed,
    {
      disableCollapse,
    },
  );

  const wasVehicleSelectedRef = useRef(isVehicleSelected);
  useEffect(
    function collapseDropdownsWhenVehicleIsSelected() {
      if (
        !wasVehicleSelectedRef.current &&
        !fitmentSearchConfig.isAutoRedirectDisabled &&
        isVehicleSelected &&
        !collapsed
      ) {
        toggleCollapsed();
      }
      wasVehicleSelectedRef.current = isVehicleSelected;
    },
    [collapsed, isVehicleSelected, toggleCollapsed, wasVehicleSelectedRef],
  );

  return { collapsed, toggleCollapsed, withCollapsing: !disableCollapse };
}
