import { combineReducers } from 'redux';

import { replaceVehicleValue } from 'Core/actions/fitmentSearch/index.js';
import { createIsolatedReducer } from 'Core/reducers/common.ts';
import { fitmentResponseReducer, fixRequestDueToResponse } from 'Core/reducers/fitmentSearch/common.js';
import fields from 'Core/reducers/fitmentSearch/fields.js';
import isolatedKey from './isolatedKey.js';
import request from './request.js';

const baseIsolatedHeader = createIsolatedReducer(
  isolatedKey,
  combineReducers({
    request,
    response: fitmentResponseReducer,
    fields,
  }),
);

const isolatedHeader = (state, action) =>
  baseIsolatedHeader(fixRequestDueToResponse(state, action.type === replaceVehicleValue.type), action);

export default isolatedHeader;
