import _kebabCase from 'lodash-es/kebabCase.js';

import { cloneSafe } from 'Utils/components.ts';

export default function DropdownItem({ templateFunc, item, query, template, onClick }) {
  const props = {
    appendedClasses: `item ${_kebabCase(template)}-item nav-item`,
    tabIndex: -1,
    onClick,
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        onClick();
        e.stopPropagation();
      }
    },
  };

  const component = templateFunc.call({
    ...item.params,
    template,
    query,
  });
  return cloneSafe(component, null, props);
}
