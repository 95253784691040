import { showVehicleDialogSelector } from 'Core/selectors/fitmentSearch/fitmentSearch.js';

export const extractorDataSelector = (state) => state.dialog.extractorData;

const dialogOpenedSelectors = {
  'vehicle-dialog': showVehicleDialogSelector,
};
export function createDialogOpenedSelector(name) {
  if (!dialogOpenedSelectors[name]) {
    dialogOpenedSelectors[name] = (state) => state.dialog.opened.includes(name);
  }
  return dialogOpenedSelectors[name];
}
