import { dialogClosed, dialogOpened } from 'Core/actions/dialog.ts';
import {
  forceVehicleSelection,
  forceVehicleSelectionLater,
  replaceVehicleValue,
  setFitmentTableResponse,
  vehicleDiscardingRequested,
  vehicleSelected,
  vehicleSpecificationNeeded,
} from 'Core/actions/fitmentSearch/index.js';
import { resetRequest } from 'Core/actions/request';
import { setResponse } from 'Core/actions/response';
import { createReducer } from 'Core/reducers/common.ts';
import { getVehicleFromUri } from 'Modules/converter/index.js';

export default {
  isSpecifyDialogOpened: createReducer(
    {
      [dialogClosed]: () => false,
      [vehicleSelected]: () => false,
      [vehicleSpecificationNeeded]: () => true,
    },
    false,
  ),
  isVehicleSelectionForced: createReducer(
    {
      [forceVehicleSelection]: () => true,
      [vehicleSelected]: () => false,
      [forceVehicleSelectionLater]: () => false,
    },
    false,
  ),
  fitmentTableResponse: createReducer(
    {
      [setFitmentTableResponse]: (_, { payload }) => payload,
    },
    null,
  ),
  showDialog: createReducer(
    {
      [dialogOpened]: (state, { payload }) => payload === 'vehicle-dialog' || state,
      [dialogClosed]: (state, { payload }) => (payload === 'vehicle-dialog' ? false : state),
      [forceVehicleSelection]: () => true,
      [vehicleSelected]: () => false,
      [vehicleSpecificationNeeded]: (state, { meta: { isolatedKey } }) => isolatedKey === null || state,
    },
    false,
  ),
  vehicleFromUri: createReducer(
    {
      [setResponse]: (state) => (state.length ? getVehicleFromUri().selection : []),
      [vehicleSelected]: () => [],
      [vehicleDiscardingRequested]: () => [],
      [replaceVehicleValue]: () => [],
      [resetRequest]: () => [],
    },
    getVehicleFromUri().selection,
  ),
};
