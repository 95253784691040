import type { ReduxStoreType } from 'Core/store.ts';

export type AddonAPI = {
  initialize?: (store: ReduxStoreType, extraArgs: Record<string, unknown>) => void;
};

export type Feature = {
  enabled: boolean;
  name: string;
} & AddonAPI;

export type Addon = {
  name: string;
  features: string[];
} & AddonAPI;

export function combineAPI(...apiList: AddonAPI[]): AddonAPI {
  const getFromAPIsByKey = <K extends keyof AddonAPI>(key: K): Required<AddonAPI>[K][] =>
    apiList.map((api) => api[key]).filter(Boolean) as Required<AddonAPI>[K][];

  const initializers = getFromAPIsByKey('initialize');
  return {
    initialize: initializers.length
      ? (store, extraArgs) => initializers.forEach((init) => init(store, extraArgs))
      : undefined,
  };
}

export function createAddon(name: string, coreAPI: AddonAPI, ...features: Feature[]): Addon {
  const enabledFeatures = features.filter((f) => f.enabled);
  return {
    name,
    features: enabledFeatures.map((f) => f.name),
    ...combineAPI(coreAPI, ...enabledFeatures),
  };
}
