import { combineReducers } from 'redux';

import { SearchItemDataCollection } from 'Models/index.ts';
import { createReducersFromShorthands, fromAction } from 'Core/reducers/common.ts';
import { loadCompared, loadProductsHistory, loadQueriesHistory } from 'Utils/userStorage.js';

const initStates = {
  compared: new SearchItemDataCollection(loadCompared(), 'compared'),
  productsHistory: loadProductsHistory(),
  queriesHistory: loadQueriesHistory(),
};

export default combineReducers(
  createReducersFromShorthands(
    {
      compared: {
        SET_COMPARED: fromAction('compared'),
      },
      productsHistory: {
        CLEAR_HISTORY: () => [],
      },
      queriesHistory: {
        SET_QUERIES_HISTORY: fromAction('queriesHistory'),
        CLEAR_QUERIES_HISTORY: () => [],
        CLEAR_HISTORY: () => [],
      },
    },
    initStates,
  ),
);
