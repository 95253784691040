import { combineReducers } from 'redux';

import { dialogClosed } from 'Core/actions/dialog.ts';
import {
  clearVehicleDialogRequest,
  replaceVehicleValue,
  setVehicleDialogSelection,
  vehicleSelected,
  vehicleDiscardingRequested,
} from 'Core/actions/fitmentSearch/index.js';
import { createReducersFromShorthands } from 'Core/reducers/common.ts';
import { updateVehicleSelection } from '../common.js';

const initStates = {
  selection: [],
};

export default combineReducers(
  createReducersFromShorthands(
    {
      selection: {
        [clearVehicleDialogRequest]: () => initStates.selection,
        [dialogClosed]: () => initStates.selection,
        [replaceVehicleValue]: (state, { payload, meta: { fitmentFields } }) =>
          updateVehicleSelection(state, payload, fitmentFields),
        [setVehicleDialogSelection]: (_state, { payload }) => payload,
        [vehicleSelected]: () => initStates.selection,
        [vehicleDiscardingRequested]: () => [],
      },
    },
    initStates,
  ),
);
