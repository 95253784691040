import { createSelector } from '@reduxjs/toolkit';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { goBackInHistory } from 'Core/actions/redirect.ts';
import { retryRequest, resetRequest } from 'Core/actions/request.js';
import {
  createFitmentSearchResponseSelectionSelector,
  isNoVehicleSpecificResultsSelector,
  notFilteredByVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import {
  searchOriginalQuerySelector,
  searchQuerySelector,
  searchResponseErrorSelector,
  searchResponseMessagesSelector,
  searchResponseStateSelector,
  searchRequestSelectionSelector,
  totalHitsSelector,
} from 'Core/selectors/search.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { keysSelector, getUriFromRequest } from 'Modules/converter/index.js';

export default function Message({ template, requestAction = undefined }) {
  const dispatch = useDispatch();
  const clearTimeoutRef = useRef();

  const goBack = () => dispatch(goBackInHistory());
  const retry = () => dispatch(retryRequest(requestAction));
  const startOver = () =>
    dispatch(resetRequest({ discardUserPreselection: true, allowSearchSameAsUri: true }));

  const responseState = useSelector(searchResponseStateSelector);

  useEffect(() => {
    if (clearTimeoutRef.current) {
      clearTimeoutRef.current();
    }
    const timeout = uiConfig.noResultsRedirectTimeout;
    if (responseState === 'nothing' && timeout) {
      const prevHref = window.location.href;
      const timeoutId = setTimeout(() => {
        if (window.location.href === prevHref) {
          startOver();
        }
        clearTimeoutRef.current = null;
      }, timeout);
      clearTimeoutRef.current = () => clearTimeout(timeoutId);
    } else {
      clearTimeoutRef.current = null;
    }
  });

  const isFacetsSelected = !!useSelector(searchRequestSelectionSelector).length;
  const isNoVehicleSpecificResults = useSelector(isNoVehicleSpecificResultsSelector);
  const notFilteredByVehicle = useSelector(notFilteredByVehicleSelector);
  const messages = useSelector(searchResponseMessagesSelector);

  const vehicleSelection = useSelector(createFitmentSearchResponseSelectionSelector(null));

  const isVehicleSelected = vehicleSelection?.length > 0;

  const vehicleString = vehicleSelection?.map((v) => v.value).join(' ');
  const searchByVehicleUrl =
    vehicleSelection?.length && getUriFromRequest({ selection: vehicleSelection }, { goToSearchPage: true });

  return template.call({
    retry,
    startOver,
    goBack,
    isFacetsSelected,
    hasMessage: (message) => messages.includes(message),
    pageType: uiConfig.pageType,
    isNoVehicleSpecificResults,
    notFilteredByVehicle,
    isVehicleSelected,
    searchByVehicleUrl,
    vehicleString,
    ...useMessageParams(responseState),
  });
}

const emptyState = {};
const errorKeysSelector = keysSelector('status', 'userId', 'timestamp', 'instance');
const errorParamsSelector = createSelector(searchResponseErrorSelector, (error) => ({
  error: errorKeysSelector(error),
}));
const queryWithTotalHitsParamSelector = createSelector(
  searchQuerySelector,
  totalHitsSelector,
  (query, totalHits) => ({
    query,
    totalHits,
  }),
);
const responseStateToSelectorMap = {
  'badRequest': errorParamsSelector,
  'corrected': createSelector(searchQuerySelector, searchOriginalQuerySelector, (query, originalQuery) => ({
    query,
    originalQuery,
  })),
  'nothing': queryWithTotalHitsParamSelector,
  'partial': createSelector(searchQuerySelector, searchOriginalQuerySelector, (query, originalQuery) => ({
    query,
    originalQuery,
  })),
  'unknown': errorParamsSelector,
  'timeout': errorParamsSelector,
  'cancelled': errorParamsSelector,
  'networkError': errorParamsSelector,
  'normal': queryWithTotalHitsParamSelector,
  '': () => emptyState, // initial state
};

function useMessageParams(responseState) {
  const params = useSelector(responseStateToSelectorMap[responseState]);
  return { template: responseState, ...params };
}
