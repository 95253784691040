import requestConfig from 'Models/uiConfig/requestConfig.js';
import { convertibleFromObject } from 'Modules/converter/index.js';
import { GET } from 'Modules/serverApi/common/httpClient.js';

import type { ServerModel } from 'Modules/serverApi/types';

const { requestDefaults } = requestConfig;

export default async function recommend({ itemId, count }: { itemId: string; count: number }) {
  const extra = convertibleFromObject({ itemId, count, ...requestDefaults.extra });

  return await GET('recommend.json', { extra })
    .then((response) => {
      return response as ServerModel.SearchResponse;
    })
    .catch(async (err) => {
      throw `[Convermax]: The recommend request failed: ${err?.message}`;
    });
}
