import _uniqBy from 'lodash-es/uniqBy.js';

import { FacetValue } from 'Models/index.ts';
import { keysSelector } from 'Modules/converter/index.js';
import { equalBy } from 'Utils/array.js';

import type { ArrayCallback, ArrayPredicate } from 'Core/types.ts';
import type { FacetValueFull, FacetValueBase } from './facetValue.ts';

export type VehicleValue = Pick<FacetValueFull, 'field' | 'term' | 'value' | 'payload'>;

const serializableKeysSelector = keysSelector<VehicleValue, VehicleValue>(
  'field',
  'term',
  'value',
  'payload',
);

export default class Vehicle {
  static null = new Vehicle([], []);

  // the argument may be undefined if one of vehicle values were constructed without value
  static isUsualTerm(term?: string): boolean {
    return !!term && !term.startsWith('__');
  }

  _fields: string[];
  _values: VehicleValue[];
  key: string;

  constructor(values: VehicleValue[] | Record<string, string>, fitmentFields: string[]) {
    let vehicleValues: VehicleValue[];

    if (!Array.isArray(values)) {
      vehicleValues = Object.entries(values).map(([field, value]) => {
        return {
          field,
          value,
          term: FacetValue.escapeTermValue(value),
        };
      }) as VehicleValue[];
    } else {
      vehicleValues = values;
    }

    this._fields = fitmentFields;
    this._values = this._fields
      .flatMap((field) => vehicleValues.filter((v) => v.field === field))
      // vehicle values may have more properties than specified in the type, but we don't want to serialize them
      .map(serializableKeysSelector);
    this.key = this._values.map(makeKey).join('&');
  }

  /* array-like methods */

  filter(predicate: ArrayPredicate<FacetValueBase>): VehicleValue[] {
    return this._values.filter(predicate);
  }

  map<R>(callback: ArrayCallback<FacetValueBase, R>): R[] {
    return this._values.map(callback);
  }

  mapToVehicle(callback: ArrayCallback<VehicleValue, VehicleValue>): Vehicle {
    return new Vehicle(this._values.map(callback), this._fields);
  }

  concat(values: VehicleValue[] | VehicleValue): Vehicle {
    return new Vehicle(this._values.concat(values), this._fields);
  }

  some(predicate: ArrayPredicate<VehicleValue>): boolean {
    return this._values.some(predicate);
  }

  /* vehicle methods */

  get notNull(): boolean {
    return !!this._values.length;
  }

  get selection(): VehicleValue[] {
    return this.filter((v) => Vehicle.isUsualTerm(v.term));
  }

  get serializable(): VehicleValue[] {
    return this._values;
  }

  get fieldMap(): Record<string, string> {
    return Object.fromEntries(this._values.map((v) => [v.field, v.value]));
  }

  get filteredFieldMap(): Record<string, string> {
    return Object.fromEntries(this.selection.map((v) => [v.field, v.value]));
  }

  equals = (vehicle: Vehicle): boolean => equalBy(this._values, vehicle._values, makeKey);

  isMoreSpecificThan = (vehicle: Vehicle): boolean =>
    // we check for repeated values because don't want to compare vehicles if one of them has several years/makes/etc.
    !hasRepeatedValues(this) &&
    !hasRepeatedValues(vehicle) &&
    !this.equals(vehicle) &&
    vehicle._fields.every((field) => {
      const { term } = this._values.find((v) => v.field === field) || {};
      const { term: another } = vehicle._values.find((v) => v.field === field) || {};
      return term === another || specificity(term) > specificity(another);
    });

  merge(vehicle: Vehicle): Vehicle | null {
    if (this._fields !== vehicle._fields) {
      return null;
    }

    if (this.equals(vehicle)) {
      return this;
    }
    // we check for repeated values because don't want to compare vehicles if one of them has several years/makes/etc.
    if (hasRepeatedValues(this) || hasRepeatedValues(vehicle)) {
      return null;
    }
    const thisMap = Object.fromEntries(this.map(({ field, term }) => [field, term]));
    const anotherMap = Object.fromEntries(vehicle.map(({ field, term }) => [field, term]));
    const values = vehicle._fields
      .reduce(
        (result, field) => {
          const thisTerm = thisMap[field];
          const anotherTerm = anotherMap[field];
          if (!result) {
            return result;
          }
          if (thisTerm === anotherTerm || specificity(thisTerm) > specificity(anotherTerm)) {
            return [...result, this._values.find((v) => v.field === field)];
          }
          if (specificity(anotherTerm) > specificity(thisTerm)) {
            return [...result, vehicle._values.find((v) => v.field === field)];
          }
          return null;
        },
        [] as (VehicleValue | void)[] | null,
      )
      ?.filter(Boolean) as VehicleValue[] | void;
    return values ? new Vehicle(values, vehicle._fields) : null;
  }

  toString(): string {
    const fieldMap = Object.fromEntries(getFieldMapEntries(this));
    return this._fields
      .filter((f) => fieldMap[f] && (fieldMap[f] as string[]).every(Vehicle.isUsualTerm))
      .map((f) => (fieldMap[f] as string[]).filter(Vehicle.isUsualTerm).join('/'))
      .join(' ');
  }
}

function makeKey(value: FacetValueBase) {
  return `${value.field}|${value.term}`;
}

function hasRepeatedValues({ _values }: Vehicle) {
  return _values.length !== _uniqBy(_values, 'field').length;
}

function specificity(value) {
  // {non-special value} > __ignored > __inexact > {no value}
  return !value ? 0 : value === '__inexact' ? 1 : value === '__ignored' ? 2 : 3;
}

function getFieldMapEntries(self: Vehicle): [string, string[] | null][] {
  return self._fields.map((field) => {
    const values = self._values.filter((v) => v.field === field);
    return [field, values.length ? values.map((v) => v.value) : null];
  });
}
