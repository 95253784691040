import { dialogClosed } from 'Core/actions/dialog.ts';
import { vehicleDiscardingRequested, vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { fitmentResponseReducer } from 'Core/reducers/fitmentSearch/common.js';
import { createReducer } from 'Core/reducers/common.ts';
import { vehicleWidgetName as garageVehicleWidgetName } from 'Components/fitmentSearch/garage/garage.jsx';

const initState = fitmentResponseReducer(undefined, {});

export default createReducer(
  {
    [dialogClosed]: () => initState,
    [vehicleDiscardingRequested]: () => initState,
    [vehicleSelected]: (state, { meta: { vehicleWidgetName } }) =>
      vehicleWidgetName === garageVehicleWidgetName ? initState : state,
    default: fitmentResponseReducer,
  },
  initState,
);
