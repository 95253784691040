//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-676:_2532,_808,_5144,_8684,_4877,_6540,_6268,_53;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-676')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-676', "_2532,_808,_5144,_8684,_4877,_6540,_6268,_53");
        }
      }catch (ex) {
        console.error(ex);
      }