import _camelCase from 'lodash-es/camelCase.js';
const specialRoles = ['dialog', 'filter'];
export default function cmRoleHandler(e, definedHandlers) {
  const elementWithRole = e.target?.closest?.('[data-cm-role]');
  if (elementWithRole) {
    const { cmRole } = elementWithRole.dataset;
    const specialRole = specialRoles.find((r) => cmRole.startsWith(r));
    const handler = specialRole ? definedHandlers[specialRole] : definedHandlers[_camelCase(cmRole)];
    if (handler) {
      handler(e, cmRole, elementWithRole);
    }
  }
}

export const simpleHandler = (definedHandlers) => (e) => cmRoleHandler(e, definedHandlers);
