import { createContext } from 'react';

interface GarageContext {
  closeGarage?: () => void;
  doNotRedirectOnVehicleSelect?: true;
  isAutoVehicleSelectionDisabled?: boolean;
  isShowVehicleDropdowns?: boolean;
  redirectUrl?: string;
  showVehicleDropdowns?: () => void;
  useNativeDropdown?: false;
  useVehicleWidget?: true;
}

export default createContext<GarageContext>({});
