import { combineReducers } from 'redux';

import { replaceVehicleValue } from 'Core/actions/fitmentSearch/index.js';
import { createIsolatedReducer } from 'Core/reducers/common.ts';
import { fitmentResponseReducer, fixRequestDueToResponse } from '../common.js';
import fields from 'Core/reducers/fitmentSearch/fields.js';
import isolatedKey from './isolatedKey.js';
import request from './request.js';

const baseIsolatedVerifyFitment = createIsolatedReducer(
  isolatedKey,
  combineReducers({
    request,
    response: fitmentResponseReducer,
    fields,
  }),
);

const isolatedVerifyFitment = (state, action) =>
  baseIsolatedVerifyFitment(fixRequestDueToResponse(state, action.type === replaceVehicleValue.type), action);

export default isolatedVerifyFitment;
