import { useSelector } from 'react-redux';

import { totalHitsSelector } from 'Core/selectors/search.js';
import { viewSelector } from 'Core/selectors/show.ts';
import { cloneSafe } from 'Utils/components.ts';
import { track } from 'Modules/serverApi/index.js';
import ComparerCheckBox from './comparerCheckBox.jsx';

export default function SearchItem({ template, item }) {
  const view = useSelector(viewSelector);
  const searchTotalHits = useSelector(totalHitsSelector);

  const isTargetInItemLink = ({ target }) => {
    const link = target.closest('a[href]:not([data-cm-prevent-redirect])');
    if (link) {
      const { origin } = window.location;

      const targetUrl = new URL(link.href, origin);
      targetUrl.hash = '';

      const itemUrl = new URL(item.url, origin);

      return targetUrl.href === itemUrl.href;
    }
    return false;
  };

  const position = item.position ?? -1;

  const onMouseUp = (e) => {
    if ((e.button === 0 || e.button === 1) && isTargetInItemLink(e)) {
      const eventParams = {
        Position: position,
        ProductId: item.id,
      };

      if (item.type === 'related') {
        track('ClickOnRelatedProduct', eventParams);
      } else {
        track('ClickOnSearchResult', { ...eventParams, Total: searchTotalHits });
      }
    }
  };

  const comparerCheckBox = <ComparerCheckBox item={item} />;

  try {
    const component = template.call({
      ...item.params,
      comparerCheckBox,
      view,
      position,
    });
    return cloneSafe(component, null, { onMouseUp });
  } catch (err) {
    console.error(`Failed to run item template for itemId: ${item.id}\n`, err);
    return null;
  }
}
