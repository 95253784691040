import { combineReducers } from 'redux';

import { replaceVehicleValue } from 'Core/actions/fitmentSearch/index.js';
import { createIsolatedReducer } from 'Core/reducers/common.ts';
import { fixRequestDueToResponse } from '../common.js';
import fields from 'Core/reducers/fitmentSearch/fields.js';
import isolatedKey from './isolatedKey.js';
import request from './request.js';
import response from './response.js';

const baseIsolatedDialog = createIsolatedReducer(
  isolatedKey,
  combineReducers({
    request,
    response,
    fields,
  }),
);

const isolatedDialog = (state, action) =>
  baseIsolatedDialog(fixRequestDueToResponse(state, action.type === replaceVehicleValue.type), action);

export default isolatedDialog;
