import FacetValue, { FacetValueBase } from './facetValue.ts';
import SearchItemData from './searchItemData.js';

import type { ServerModel } from 'Modules/serverApi/types.ts';

// workaround to avoid typing of requestConfig
const autocompleteItemTypes = ['text' as const, 'product' as const, 'facetValue' as const];
export type AutocompleteItemType = (typeof autocompleteItemTypes)[number];

export default class AutocompleteItem {
  key: string;
  type: AutocompleteItemType;

  textValue?: { text: string; textHighlight: string };

  searchItem?: {
    params: Record<string, unknown>;
    isRedirectItem?: true;
    redirectUrl?: string;
    suggestionGroup: string;
  };

  facetValue?: FacetValueBase & {
    suggestionGroup: string;
    isRedirectFacetValue?: true;
    imageUrl?: string;
    redirectUrl?: string;
    payload?: string;
    facetValue?: string;
  };

  constructor(item: ServerModel.AutocompleteItem) {
    switch (item.Type) {
      case 'Freetext':
      case 'PopularSearch':
        this.textValue = { text: item.Text, textHighlight: item.TextHighlight };
        this.type = 'text';
        this.key = item.Text;
        break;
      case 'Product': {
        const data = new SearchItemData(item, 'autocomplete');
        this.searchItem = {
          params: data.params,
          isRedirectItem: item.Redirect,
          redirectUrl: data.url,
          suggestionGroup: item.SuggestionGroup,
        };
        this.type = 'product';
        this.key = data.key;
        break;
      }
      case 'Category': {
        this.facetValue = {
          field: item.FieldName,
          term: FacetValue.escapeTermValue(item.FacetValue),
          value: item.Value,
          facetValue: item.FacetValue,
          hitCount: item.HitCount,
          suggestionGroup: item.SuggestionGroup,
          isRedirectFacetValue: item.Redirect,
          imageUrl: item.ImageUrl,
          payload: item.Payload,
          redirectUrl: item.RedirectUrl,
        };
        this.type = 'facetValue';
        this.key = FacetValue.termKey(this.facetValue);
        break;
      }
    }
  }

  get params() {
    return this.textValue || this.searchItem?.params || this.facetValue || {};
  }

  get redirectUrl() {
    return this.searchItem?.isRedirectItem
      ? this.searchItem.redirectUrl
      : this.facetValue?.isRedirectFacetValue
        ? this.facetValue.redirectUrl
        : null;
  }

  get suggestionGroup() {
    return this.facetValue?.suggestionGroup || this.searchItem?.suggestionGroup;
  }
}
