import type { ReduxStateType } from 'Core/store.ts';

const autocompleteSelector = (state: ReduxStateType) => state.autocomplete;

// default suggestions

export const autocompleteDefaultSuggetionsSelector = (state: ReduxStateType) =>
  autocompleteSelector(state).defaultSuggestions;

// request

export const autocompleteRequestQuerySelector = (state: ReduxStateType) =>
  autocompleteSelector(state).request.query;

// response

const autocompleteResponseSelector = (state: ReduxStateType) => autocompleteSelector(state).response;

const autocompleteResponseQuerySelector = (state: ReduxStateType) =>
  autocompleteResponseSelector(state).query;

export const autocompleteResponseItemsSelector = (state: ReduxStateType) =>
  autocompleteResponseSelector(state).items;

export const autocompleteTotalHitsSelector = (state: ReduxStateType) =>
  autocompleteResponseSelector(state).totalHits;

// redirect

const autocompleteRedirectSelector = (state: ReduxStateType) => autocompleteSelector(state).redirect;

export const autocompleteRedirectWaitingForResponseSelector = (state: ReduxStateType) =>
  autocompleteRedirectSelector(state).waitingForResponse;

export const autocompleteRedirectPreventedSearchSelector = (state: ReduxStateType) =>
  autocompleteRedirectSelector(state).havePreventedSearch;

// combined

export const autocompleteResponseReceivedSelector = (state: ReduxStateType) =>
  autocompleteRequestQuerySelector(state) === autocompleteResponseQuerySelector(state);
