import { usePopup } from 'Core/hooks/index.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import ShowResultsButton from './showResultsButton.js';

export default function ContextDialog({ context, name, template, closeOnClickOutside }) {
  const { opened, popupParams, popupRoles } = usePopup(name, closeOnClickOutside);

  if (!opened) {
    return null;
  }

  const onClick = simpleHandler(popupRoles);
  const showResultsButton = (templ) => <ShowResultsButton template={templ} key="show-results-button" />;

  const component = template.call({
    ...context,
    ...popupParams,
    showResultsButton,
  });

  return cloneSafe(component, null, { onClick });
}
