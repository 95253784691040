import _forIn from 'lodash-es/forIn.js';

export default function queryParamsFromObject(obj, prefix = '') {
  return paramsArrayFromObject(obj, prefix).join('&').replace(/\.=/g, '=');
}

function paramsArrayFromObject(obj, prefix) {
  const resArr = [];
  if (obj) {
    if (Array.isArray(obj)) {
      obj.forEach((innerObj) => resArr.push(...paramsArrayFromObject(innerObj, prefix)));
    } else if (typeof obj === 'object') {
      _forIn(obj, (value, key) => resArr.push(...paramsArrayFromObject(value, `${prefix}${key}.`)));
    } else {
      resArr.push(`${prefix}=${obj}`);
    }
  }
  return resArr;
}
