import history from 'Core/history.js';

import {
  assignLocation,
  goBackInHistory,
  pushToHistory,
  replaceInHistory,
  replaceLocation,
} from 'Core/actions/redirect.ts';
import { epicFromHandlers } from './common.js';

export default epicFromHandlers({
  [assignLocation.type]: ({ action: { payload } }) => window.location.assign(payload),
  [replaceLocation.type]: ({ action: { payload } }) => window.location.replace(payload),
  [goBackInHistory.type]: () => history.back(),
  [pushToHistory.type]: ({
    action: {
      payload: { location, state },
    },
  }) =>
    // sometimes history manipulations force style recalculation so it's better to do it a bit later
    void setTimeout(() => {
      history.push(location, state);
      window.ga?.('send', 'pageview', window.location.pathname + window.location.search);
    }, 0),
  [replaceInHistory.type]: ({
    action: {
      payload: { location, state },
    },
  }) =>
    void setTimeout(() => {
      history.replace(location, state);
    }, 0),
});
