
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function autoSyncVisualizationDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, [_createElement('div', {
            'className': 'cm_vehicle-visualization-container',
            'key': '2410'
        }, this.visualization)], this.isVehicleError ? _createElement('div', {
        'className': 'cm_vehicle-error',
        'key': '350'
    }, 'Failed to visualize the vehicle') : null, this.isWheelError ? _createElement('div', {
        'className': 'cm_wheel-error',
        'key': '450'
    }, 'Failed to visualize the wheel') : null));
}
        export const componentNames = []