import _difference from 'lodash-es/difference.js';
import _uniq from 'lodash-es/uniq.js';

import { dialogClosed } from 'Core/actions/dialog.ts';
import {
  ignoreInexactFields,
  forceVehicleSelection,
  forceVehicleSelectionLater,
  setVehicleDialogSelection,
  vehicleSpecificationNeeded,
} from 'Core/actions/fitmentSearch/index.js';
import { setResponse } from 'Core/actions/response.ts';
import {
  createFilteredFitmentSearchResponseFieldsSelector,
  createFitmentSearchFieldsSelector,
  createFitmentSearchResponseFacetsSelector,
  createFitmentSearchResponseSelectionSelector,
  createResponseVehicleSelector,
  forceVehicleSelectionLaterSelector,
  isNoVehicleSpecificResultsSelector,
  selectedVehicleSelector,
  isVehicleLandingFacetSelectedSelector,
  isVehicleSelectedSelector,
  isVehicleSelectionForcedSelector,
  vehicleInexactSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { getVehicleFromUri } from 'Modules/converter/index.js';
import { epicFromHandlers } from '../common.js';

export default epicFromHandlers({
  [dialogClosed]: ({ state, action: { payload } }) => {
    if (payload === 'vehicle-dialog') {
      if (vehicleInexactSelector(state)) {
        return ignoreInexactFields();
      }

      if (isVehicleSelectionForcedSelector(state)) {
        return forceVehicleSelectionLater();
      }
    }
  },
  [setResponse.type]({
    state,
    action: {
      meta: { isolatedKey, onInit },
    },
  }) {
    if (isolatedKey !== null || !requestConfig.hasSearchResults || getVehicleFromUri().isVehicleNew) {
      return;
    }

    const isNoVehicleSpecificResults = isNoVehicleSpecificResultsSelector(state);
    const isVehicleLandingFacetSelected = isVehicleLandingFacetSelectedSelector(state);
    const { isReferrerInUrl } = requestConfig;

    if (isNoVehicleSpecificResults || isVehicleLandingFacetSelected || isReferrerInUrl) {
      return;
    }

    const isVehicleSelected = isVehicleSelectedSelector(state);
    if (isVehicleSelected) {
      const selectedVehicle = selectedVehicleSelector(state);
      const responseVehicle = createResponseVehicleSelector(isolatedKey)(state);

      if (fitmentSearchConfig.isVehicleWidgetInPartialMode || !selectedVehicle.equals(responseVehicle)) {
        return;
      }

      const responseFields = createFilteredFitmentSearchResponseFieldsSelector(isolatedKey)(state);
      const fitmentFields = createFitmentSearchFieldsSelector(isolatedKey)(state);
      const responseFitmentFields = responseFields.filter((f) => fitmentFields.includes(f));

      const selectedFields = createFitmentSearchResponseSelectionSelector(isolatedKey)(state).map(
        (sel) => sel.field,
      );

      const ignoredFields = selectedVehicle.filter((v) => v.term === '__ignored').map((v) => v.field);

      const missingFields = _difference(responseFitmentFields, _uniq([...selectedFields, ...ignoredFields]));

      if (selectedFields.length && missingFields.length) {
        return vehicleSpecificationNeeded(missingFields, isolatedKey);
      }
    } else {
      const shouldForceVehicleSelection =
        fitmentSearchConfig.forceVehicleSelection && onInit && !forceVehicleSelectionLaterSelector(state);

      const inVehicleSelectionProcess = createFitmentSearchResponseFacetsSelector(isolatedKey)(
        state,
      )._facets.some((f) => f.selection.length > 0);

      if (!inVehicleSelectionProcess && shouldForceVehicleSelection) {
        return forceVehicleSelection();
      }
    }
  },
  [vehicleSpecificationNeeded.type]: ({ state, action: { meta } }) =>
    !meta.isolatedKey && setVehicleDialogSelection(selectedVehicleSelector(state).selection),
});
