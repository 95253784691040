import WeightedColor from './weightedColor.ts';

import { DisplayMode, ServerModel } from 'Modules/serverApi/types.ts';

export interface FacetValueBase {
  field: string;
  term: string;
  hitCount?: number;
  displayMode?: DisplayMode;
  isSelected?: boolean;
  isUnique?: boolean;
  name?: string;
  payload?: string | null;
  treeLevel?: number | null;
  value?: string;
  url?: string | null;
  imageUrl?: string | null;
}

export type FacetValueFull = Required<FacetValueBase>;

export default {
  create,
  createManyFromSearchResponse,
  createRangedTerm,
  equal,
  escapeTermValue,
  isColor,
  isInCollection,
  termKey,
  valueKey,
};

function create(arg: FacetValueBase): FacetValueFull {
  return {
    hitCount: 0,
    displayMode: DisplayMode.Default,
    isSelected: false,
    isUnique: false,
    name: arg.field,
    payload: null,
    treeLevel: null,
    value: arg.term,
    url: null,
    imageUrl: null,
    ...arg,
  };
}

function createManyFromSearchResponse(
  values: ServerModel.Facet[],
  facet: ServerModel.FacetCategory,
  baseTreeLevel = 0,
): FacetValueFull[] {
  return (values || []).map((v) =>
    create({
      field: facet.FieldName,
      name: facet.DisplayName,
      term:
        facet.DisplayMode === DisplayMode.Slider || facet.DisplayMode === DisplayMode.Ranges
          ? removeRangedValueBrackets(v.Term)
          : v.Term,
      value:
        facet.DisplayMode === DisplayMode.Slider || facet.DisplayMode === DisplayMode.Ranges
          ? removeRangedValueBrackets(v.Value)
          : v.Value,
      payload: v.Payload,
      isSelected: v.Selected ?? false,
      hitCount: v.HitCount,
      displayMode: facet.DisplayMode,
      isUnique: determineUniqueness(v.Value, facet),
      url: v.Url,
      imageUrl: v.ImageUrl,
      /*
        We need to mark PREselected values with a negative 'treeLevel' to remove
        them from the FacetPanel using 'Facet._facetedValuesPredicates'.
      */
      treeLevel: facet.IsTree ? v.Term.split(facet.TreeSeparator).length - 1 - baseTreeLevel : null,
    }),
  );
}

function createRangedTerm(from: string, to: string): string {
  return `${from} TO ${to}`;
}

function equal(value: FacetValueBase | null, another: FacetValueBase | null): boolean {
  return value && another ? valueKey(value) === valueKey(another) : value === another;
}

function isColor(value: FacetValueBase): boolean {
  return value.field === '_Color';
}

function isInCollection(value: FacetValueBase, collection: FacetValueBase[]): boolean {
  return collection.some((v) => equal(value, v));
}

function termKey(value: FacetValueBase): string {
  return constructKey(value, value.term);
}

function valueKey(value: FacetValueBase): string {
  const keyValue = isColor(value) ? new WeightedColor(value.term).color : value.term;
  return constructKey(value, keyValue);
}

function constructKey({ field }: FacetValueBase, keyValue: string): string {
  return `${field}|${keyValue}`;
}

function determineUniqueness(value: string, { Ranges }: ServerModel.FacetCategory): boolean {
  return !Ranges || !Ranges.length || Ranges.every((r) => r !== value);
}

function removeRangedValueBrackets(value) {
  // [30001 TO 50000) -> 30001 TO 50000
  return value.replace(/^[[(](-?[\d.*]+[ -]TO[ -]-?[\d.*]+)[\])]$/, '$1');
}

function escapeTermValue(value: string): string {
  // This code was copied from Convermax.Browser.FacetedField.EscapeTermValue method.
  // We have to track its changes and keep these to methods synchronized to prevent bugs.
  const escapedCharsReg = /[ /&:?\\%]/g;
  const startsWithDash = value.startsWith('-');
  return `${startsWithDash ? '-' : ''}${value
    .replace(/-/g, ' ')
    .split(escapedCharsReg)
    .filter(Boolean)
    .join('-')}`;
}
