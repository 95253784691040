import getAnalytics from 'Modules/analytics.js';
import { sendBeacon } from './common/httpClient.js';

const analytics = getAnalytics();

export default function track(type, eventParams) {
  if (!analytics.isAnalyticsEnabled) {
    return;
  }

  const bodyFormData = new URLSearchParams();
  bodyFormData.append('UserID', analytics.userId);
  bodyFormData.append('SessionID', analytics.sessionId);
  bodyFormData.append('EventType', type);

  for (const eventParam in eventParams) {
    bodyFormData.append(eventParam, eventParams[eventParam]);
  }

  try {
    sendBeacon('track', bodyFormData);
  } catch (err) {
    console.warn(`Convermax /track failed: ${err?.message}`);
  }
}
