import { GET, GETBACKUP, POST, POSTBACKUP } from './common/httpClient.js';

export async function saveGarageToAccount(
  customerId: string,
  garage: Record<string, string>[],
  selectedVehicle: Record<string, string>,
) {
  try {
    return await POST('garage.json', { customerId }, JSON.stringify({ garage, selectedVehicle }), {
      headers: { 'Content-Type': 'application/json' },
      keepalive: true,
    });
  } catch (err) {
    if (err.message !== 'Failed to fetch') {
      console.warn(err || "Failed to save the customer's garage");
      // general network error with no status code can be handled here
    }

    try {
      return await POSTBACKUP('garage.json', { customerId }, JSON.stringify({ garage, selectedVehicle }), {
        headers: { 'Content-Type': 'application/json' },
        keepalive: true,
      });
    } catch (err) {
      console.warn(err || "Failed to save the customer's garage");
    }
  }
}

export async function loadGarageFromAccount(customerId: string) {
  try {
    return await GET(
      'garage.json',
      { customerId },
      {
        headers: { 'Content-Type': 'application/json' },
        keepalive: true,
      },
    );
  } catch (err) {
    if (err.message !== 'Failed to fetch') {
      console.warn(err || "Failed to load the customer's garage");
      // general network error with no status code can be handled here
    }

    try {
      return await GETBACKUP(
        'garage.json',
        { customerId },
        {
          headers: { 'Content-Type': 'application/json' },
          keepalive: true,
        },
      );
    } catch (err) {
      console.warn(err || "Failed to load the customer's garage");
    }
  }
}
