import type { AnyAction, Dispatch, Middleware } from 'redux';
import type { ReduxStateType, Selector } from 'Core/store.ts';

export type EpicArg = {
  state: ReduxStateType;
  dispatch: Dispatch;
  stateChangedBy: ReturnType<typeof createStateChangedBy>;
  action: AnyAction;
};

export default (
    epics: ((arg: EpicArg) => Promise<AnyAction | void>)[],
  ): Middleware<Record<string, unknown>, ReduxStateType> =>
  (store) =>
  (next) =>
  (action) => {
    const { dispatch } = store;
    const prevState = store.getState();
    const result = next(action);
    const state = store.getState();

    const stateChangedBy = createStateChangedBy(prevState, state);

    epics.forEach(async (epic) => {
      const nextAction = await epic({ state, dispatch, stateChangedBy, action });
      if (nextAction) {
        dispatch(nextAction);
      }
    });

    return result;
  };

function createStateChangedBy(prevState: ReduxStateType, state: ReduxStateType) {
  const cache = new Map<Selector<unknown>, boolean>();
  return (selector: Selector<unknown>): boolean => {
    let result: boolean;
    if (cache.has(selector)) {
      // type conversion is necessary
      // https://github.com/microsoft/TypeScript/issues/13086
      result = cache.get(selector) as boolean;
    } else {
      result = selector(prevState) !== selector(state);
      cache.set(selector, result);
    }
    return result;
  };
}
