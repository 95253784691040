import { useSelector } from 'react-redux';

import type { FunctionComponent } from 'react';
import type { Selector } from 'Core/store.ts';

interface Props {
  selector?: Selector<boolean>;
  loading?: boolean;
  extraClass?: string;
}

const Loading: FunctionComponent<Props> = ({ selector = () => null, loading: loadingFromProps = null }) => {
  const loadingFromState = useSelector(selector);
  if (loadingFromState === null && loadingFromProps === null) {
    console.error('Some Loading has not received neither selector nor loading value');
  }
  return loadingFromState || loadingFromProps ? (
    <div className="cm_loading">
      <div className="loader-wrapper">
        <div className="dots">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </div>
  ) : null;
};

export default Loading;
