import { createAddon } from 'Addons/combine.ts';

export default createAddon('colorSearch', {
  initialize,
});

function initialize(): void {
  window.document.body.appendChild(createImageBuffer());
}

function createImageBuffer(): HTMLInputElement {
  const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'bmp'];
  const input = window.document.createElement('input');

  input.id = CONVERMAX_IMAGE_BUFFER_ID;
  input.type = 'file';
  input.accept = validExtensions.map((ext) => `.${ext}`).join(',');
  input.style.display = 'none !important';
  return input;
}
