import { useContext } from 'react';

import { vehicleWidgetName } from '../garage.jsx';
import GarageContext from '../garageContext.ts';
import VehicleWidget from '../../vehicleWidget/index.ts';

export default function VehicleDropdowns({ template }) {
  const {
    closeGarage,
    doNotRedirectOnVehicleSelect,
    columnBreakpoint,
    isAutoVehicleSelectionDisabled,
    redirectUrl,
    useNativeDropdown,
  } = useContext(GarageContext);

  const vehicleWidget = (templ) => {
    const props = {
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      isAutoVehicleSelectionDisabled,
      columnBreakpoint,
      name: vehicleWidgetName,
      onSelectVehicle: () => closeGarage(),
      doNotRedirectOnVehicleSelect,
      redirectUrl,
      useNativeDropdown,
      template: templ,
    };
    return <VehicleWidget {...props} key="VehicleWidget" />;
  };

  return template.call({ vehicleWidget, template: 'vehicleDropdowns' });
}
