import productConfig from 'Models/uiConfig/productConfig.js';
import { createOnImageError, setImageSize } from 'Utils/components.ts';

const {
  searchResultsImageWidth,
  searchResultsImageHeight,
  crop: defaultCrop,
  dropdownImageWidth,
  dropdownImageHeight,
  noImageSrc,
  fields,
} = productConfig;

export default class SearchItemData {
  static getFromRaw(item, key) {
    return get({ _item: item }, key);
  }

  _item;
  type;
  position;
  onImageError;

  constructor(item, type, position) {
    this._item = item;
    this.type = type;
    this.position = position;
    this.onImageError = createOnImageError();
  }

  get id() {
    return get(this, 'id');
  }

  get key() {
    return get(this, 'key');
  }

  get params() {
    return {
      ...this._item,
      formatPrice: productConfig.formatPrice,
      calcDiscount,
      resizeImage: createImageResizer(this.type),
      setImageSize,
      onImageError: this.onImageError,
      removeHTML,
      breakWordOnSlash,
      imageOrDefault,
      getVehicleSearchUrl,
      toKebabCase,
    };
  }

  get plainObject() {
    return this._item;
  }

  get url() {
    return get(this, 'url');
  }
}

const imageResizerCache = {};
function createImageResizer(type) {
  const key = ['autocomplete', 'history'].includes(type) ? 'dropdown' : 'normal';
  if (!imageResizerCache[key]) {
    const [defaultWidth, defaultHeight] =
      key === 'dropdown'
        ? [dropdownImageWidth, dropdownImageHeight]
        : [searchResultsImageWidth, searchResultsImageHeight];
    imageResizerCache[key] = (
      image,
      { width = defaultWidth, height = defaultHeight, crop = defaultCrop } = {},
    ) => setImageSize(image, width, height, crop);
  }
  return imageResizerCache[key];
}

function removeHTML(value) {
  const valueToReplace = Array.isArray(value) ? (value.length ? value[0] : '') : value;
  return valueToReplace?.replace(/<\/?b>/g, '');
}

function breakWordOnSlash(value) {
  const valueToReplace = Array.isArray(value) ? (value.length ? value[0] : '') : value;
  return valueToReplace?.replace(/(\w\/)(\w)/g, '$1​$2');
}

function calcDiscount(price, compareAtPrice) {
  return compareAtPrice > 0 && compareAtPrice > price
    ? Math.floor(((Math.round(compareAtPrice * 100) - Math.round(price * 100)) / compareAtPrice).toFixed(2))
    : 0;
}

function imageOrDefault(image) {
  return image || noImageSrc;
}

function get({ _item }, key) {
  return _item[fields[key]];
}

function getVehicleSearchUrl(url) {
  const vehicleSearchSelection = window.Convermax.getVehicleSearchParams();
  return vehicleSearchSelection ? `${url}?${vehicleSearchSelection}` : url;
}

function toKebabCase(string) {
  return string?.toLowerCase()?.replace(' ', '-');
}
