import { useDispatch, useSelector } from 'react-redux';

import { toggleCompared } from 'Core/actions/localStorage.js';

export default function ComparerCheckBox({ item }) {
  const checked = useSelector((state) => state.localStorage.compared.some(({ key }) => key === item.key));

  const dispatch = useDispatch();
  const onChange = () => dispatch(toggleCompared(item));

  const props = {
    checked,
    className: 'cm_comparer-checkbox',
    title: checked ? 'Remove from compared' : 'Select products to compare',
    type: 'checkbox',
    onChange,
  };
  return <input {...props} />;
}
