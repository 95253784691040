import _transform from 'lodash-es/transform.js';

import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { uriDecode, uriDecodeTerm } from './uriEncoding.js';

const { defaultSort, defaultPageSize } = requestConfig;
export const defaultRequest = {
  catalog: '',
  pageNumber: 0,
  pageSize: defaultPageSize,
  filterQuery: '',
  query: '',
  selection: [],
  sort: defaultSort,
};

export default function getRequestFromUri(location) {
  const parser = uiConfig.customUriParser;

  const request = parser.parsingNeeded(uiConfig.pageType)
    ? parser.parse(uiConfig.pageType)
    : doDefaultParsing(location);

  return { ...defaultRequest, ...request };
}

function doDefaultParsing(location) {
  const { rawSelection, params } = parseUri(location);
  let selectionFromParams = '';

  const pageNumberKey = uiConfig.pageNumberParamName.toLowerCase();

  const processors = {
    facet: [null, (v) => (selectionFromParams = v)],
    filterquery: ['filterQuery', uriDecode],
    sort: ['sort', decodeURIComponent],
    [pageNumberKey]: ['pageNumber', (v) => v - 1],
    pagesize: ['pageSize', (v) => (isNaN(v) ? v : +v)],
    query: ['query', uriDecode],
    catalog: ['catalog', uriDecode],
  };
  processors.page = processors[pageNumberKey]; // back compatibility
  processors.pagenumber = processors[pageNumberKey]; // back compatibility
  processors.showed = processors.pagesize; // back compatibility

  const request = _transform(
    params,
    (req, rawValue, name) => {
      if (processors[name]) {
        const [key, processor] = processors[name];
        const value = processor(rawValue);
        if (key) {
          req[key] = value;
        }
      }
    },
    {},
  );

  request.selection = prepareSelection([rawSelection, selectionFromParams].filter(Boolean).join('/'));
  return request;
}

function parseUri({ search, hash }) {
  function parse(s) {
    const split = s.replace(/[?#]/, '').split('&');

    const rawSelection = looksLikeSelection(split[0]);
    if (rawSelection) {
      split.shift();
    }
    const params = split.reduce((p, s) => {
      const [key, value] = s.split('=');
      if (key) {
        p[key.toLowerCase()] = value;
      }
      return p;
    }, {});
    return [rawSelection, params];
  }
  const [sRawSelection, sParams] = parse(search || '');
  const [hRawSelection, hParams] = parse(hash || '');
  const rawSelection = sRawSelection || hRawSelection || rawSelectionFromHash(hParams);

  return {
    rawSelection,
    params: { ...sParams, ...hParams },
  };
}

function looksLikeSelection(param) {
  if (param.includes('/') && !param.includes('=')) {
    return param;
  }
  const decoded = decodeURIComponent(param);
  if (decoded.includes('/') && (!decoded.includes('=') || decoded.endsWith('='))) {
    return decoded.replace('=', '');
  }
  return '';
}

function rawSelectionFromHash(hParams) {
  const facets = [];
  for (let i = 0; ; i++) {
    const field = hParams[`fld${i}`];
    const sel = hParams[`sel${i}`];
    if (field && sel) {
      facets.push(`${field}/${sel}`);
    } else {
      break;
    }
  }
  return facets.join('/');
}

function prepareSelection(raw) {
  const selection = [];

  const split = raw.split('/').filter(Boolean);
  for (let i = 0; i < split.length; i += 2) {
    const field = uriDecode(split[i]);
    const term = uriDecodeTerm(split[i + 1], field);

    selection.push({ field, term });
  }
  return selection;
}
