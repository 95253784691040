import _pick from 'lodash-es/pick.js';

type Key<K extends string = string> = K | Key<K>[];

export default function keysSelector<O, R extends Partial<O>>(
  ...args: Key<Extract<keyof O, string>>[]
): (obj: O) => R {
  return args.length ? (obj) => _pick<O>(obj, ...keysToPaths(args)) as R : () => ({}) as R;
}

function keysToPaths(keys: Key[], prefix = ''): string[] {
  return keys.reduce<string[]>((result, key) => {
    if (Array.isArray(key)) {
      const [nextKey, ...nextKeys] = key;
      return [...result, ...keysToPaths(nextKeys, `${prefix}${nextKey}.`)];
    }
    return [...result, `${prefix}${key}`];
  }, []);
}
