import { useDispatch } from 'react-redux';

import { scrollToBase } from 'Core/actions/show.ts';

import type { FC } from 'react';
import type { TemplateFunction } from 'Components/types.ts';

interface Params {
  scrollToTop: () => void;
}

interface Props {
  template: TemplateFunction<Params>;
}

const ScrollController: FC<Props> = ({ template }) => {
  const dispatch = useDispatch();
  const scrollToTop = () => dispatch(scrollToBase());
  return template.call({ scrollToTop });
};

export default ScrollController;
