import { createReducersFromShorthands, fromAction, fromPayload } from 'Core/reducers/common.ts';
import requestConfig from 'Models/uiConfig/requestConfig.js';

const { defaultSort, defaultPageSize, maxCountByField } = requestConfig;

const initStates = {
  action: 'set',
  catalog: '',
  filterQuery: '',
  maxCountByField,
  pageNumber: 0,
  pageSize: defaultPageSize,
  query: '',
  sort: defaultSort,
};

export default createReducersFromShorthands(
  {
    action: {
      LOAD_MORE: () => 'add',
      RETRY_REQUEST: fromAction('action'),
      default: () => initStates.action,
    },
    catalog: {
      SET_CATALOG: fromAction('catalog'),
    },
    filterQuery: {
      FILTER_QUERY_CHANGED: fromPayload,
      FILTER_QUERY_RESET_REQUESTED: () => initStates.filterQuery,
      RESET_REQUEST: () => initStates.filterQuery,
      REPLACE_REQUEST: fromAction('filterQuery', ''),
    },
    maxCountByField: {
      REMOVE_MAX_COUNT_BY_FIELD: (state, { field }) => ({ ...state, [field]: undefined }),
      RESET_MAX_COUNT_BY_FIELD: (state, { field }) => ({ ...state, [field]: maxCountByField[field] }),
    },
    pageNumber: {
      LOAD_MORE: (state) => state + 1,
      REPLACE_REQUEST: fromAction('pageNumber', initStates.pageNumber),
      RESET_REQUEST: () => initStates.pageNumber,
      RETRY_REQUEST: (state) => state,
      SET_NEXT_PAGE: (state) => state + 1,
      SET_PAGE_NUMBER: fromAction('pageNumber'),
      SET_PREV_PAGE: (state) => state - 1,
      default(state, action) {
        // TODO: remove when sendRequest will be fully moved to action.meta
        const sendRequest = action.sendRequest ?? action.meta?.sendRequest;
        return sendRequest ? initStates.pageNumber : state;
      },
    },
    pageSize: {
      REPLACE_REQUEST: fromAction('pageSize'),
      SET_PAGE_SIZE: fromAction('pageSize'),
    },
    query: {
      DISCARD_QUERY: () => initStates.query,
      REPLACE_REQUEST: fromAction('query', initStates.query),
      SET_RESPONSE: (state, action) => action.payload.Query,
      RESET_REQUEST: () => initStates.query,
    },
    sort: {
      REPLACE_REQUEST: fromAction('sort'),
      RESET_REQUEST: () => initStates.sort,
      SET_SORT: fromAction('sort'),
    },
  },
  initStates,
);
