import { useRef, useEffect, useState, useCallback, type FC } from 'react';
import { useSelector } from 'react-redux';

import type { TemplateFunction } from 'Components/types.ts';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import { Vehicle } from 'Models/index.ts';

export interface Props {
  template: TemplateFunction<Params>;
  apiKey: string;
  config?: AutoSyncConfig;
  wheelMpn?: string;
  classModificator?: string;
  extraParams?: ExtraParams;
}

export type Params = {
  visualization: JSX.Element;
  isVehicleError: boolean;
  isWheelError: boolean;
} & ExtraParams;

type ExtraParams = Record<string, unknown>;

export interface AutoSyncConfig {
  el?: HTMLElement | null;
  height?: string;
  vehicleImageSize?: number;
  vehicleAngles?: number[];
  showColorPicker?: boolean;
  widthAdjustment?: string;
}

interface AutoSyncVisualizer {
  loadVehicleByDescription: (vehicleDesc: string) => Promise<void>;
  loadWheelByPn: (wheelMpn: string) => Promise<void>;
  setHeight: (height: string) => void;
}

const visualizerVehicleFields = ['Year', 'Make', 'Model', 'Submodel'];

// docs: https://vvs.autosyncstudio.com/express/docs/reference/classes/VvsExpress.VvsExpress.html

const AutoSyncVisualization: FC<Props> = ({
  template,
  apiKey,
  config,
  wheelMpn,
  classModificator,
  extraParams,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const visualizerRef = useRef<AutoSyncVisualizer>();

  const [isVehicleError, setIsVehicleError] = useState(false);
  const [isWheelError, setIsWheelError] = useState(false);
  const [isVisualizationHidden, setIsVisualizationHidden] = useState(false);

  const selectedVehicle = useSelector(selectedVehicleSelector);

  const vehicleString = new Vehicle(
    selectedVehicle.filter((v) => visualizerVehicleFields.includes(v.field)),
    visualizerVehicleFields,
  ).toString();

  const visualizeVehicle = useCallback(() => {
    if (!apiKey) {
      console.error('No api key found');
      return;
    }

    if (!visualizerRef.current) {
      visualizerRef.current = globalThis.initVvsExpress?.({
        apiUrl: 'https://api.autosyncstudio.com/',
        apiKey,
        el: containerRef.current,
        ...config,
      });
    }

    if (!visualizerRef.current) {
      return;
    }

    const visualizedClassName = classModificator
      ? `cm_vehicle-visualization__${classModificator}__vehicle-visualized`
      : null;

    if (visualizedClassName) {
      visualizedClassName && window.document.body.classList.remove(visualizedClassName);
    }

    if (!vehicleString) {
      setIsVisualizationHidden(true);
      return;
    }

    visualizerRef.current
      .loadVehicleByDescription(vehicleString)
      .then(() => {
        setIsVehicleError(false);
        setIsVisualizationHidden(false);
        if (visualizedClassName) {
          window.document.body.classList.add(visualizedClassName);
        }
      })
      .catch((err: Error) => {
        setIsVehicleError(true);
        setIsVisualizationHidden(true);
        console.warn(`Failed to visualize the '${vehicleString}' vehicle. ${err.message}`);
      });

    if (wheelMpn) {
      visualizerRef.current
        .loadWheelByPn(wheelMpn)
        .then(() => {
          setIsWheelError(false);
        })
        .catch((err: Error) => {
          setIsWheelError(true);
          console.warn(`Failed to visualize the '${wheelMpn}' wheel. ${err.message}`);
        });
    }
  }, [apiKey, config, vehicleString, wheelMpn, classModificator]);

  useEffect(() => {
    try {
      visualizeVehicle();
    } catch (error) {
      console.error('Failed to visualize a vehicle', error);
    }
  }, [visualizeVehicle]);

  useEffect(() => {
    const height = isVisualizationHidden ? '0px' : config?.height ?? '150px';
    try {
      visualizerRef.current?.setHeight(height);
    } catch (error) {
      console.error('Failed to set height', error);
    }
  }, [isVisualizationHidden, config?.height]);

  const visualization = (
    <div
      ref={containerRef}
      className={[
        'cm_vehicle-visualization',
        classModificator && `cm_vehicle-visualization__${classModificator}`,
        isVehicleError && 'cm_hide',
      ]
        .filter(Boolean)
        .join(' ')}
    ></div>
  );

  return template.call({ visualization, isVehicleError, isWheelError, ...extraParams });
};

export default AutoSyncVisualization;
