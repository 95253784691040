import { createContext } from 'react';

interface FacetPanelConfig {
  facetPanelName: string;
  valuesCountToShowFilterInput: number;
  showAllAlways?: true;
  disableCollapse?: true;
  initCollapsed?: boolean;
  initExpandedFacets?: string[];
}

export default createContext<FacetPanelConfig>({ facetPanelName: '', valuesCountToShowFilterInput: 30 });

export const FacetDialogName = 'FacetDialog';
