import { useRef, useLayoutEffect, useState, type RefObject } from 'react';

export type UseScroll = {
  scrollLeft: () => void;
  scrollRight: () => void;
  isLeftBorder: boolean;
  isRightBorder: boolean;
};

export default function useScroll(rootRef: RefObject<HTMLElement | undefined>): UseScroll {
  const scrollRef = useRef<Element>();

  const [isLeftBorder, setIsLeftBorder] = useState(true);
  const [isRightBorder, setIsRightBorder] = useState(true);

  useLayoutEffect(() => {
    const root = rootRef.current;
    if (root) {
      const elem = root.matches('[data-cm-scroll-container]')
        ? root
        : root.querySelector('[data-cm-scroll-container]');

      if (elem && elem instanceof HTMLElement) {
        if (scrollRef.current !== elem) {
          scrollRef.current = elem;
        }

        const update = () => {
          setIsLeftBorder(elem.scrollLeft === 0);
          setIsRightBorder(elem.scrollLeft + elem.clientWidth >= elem.scrollWidth);
        };

        update();
        elem.addEventListener('scroll', update);
        return () => elem.removeEventListener('scroll', update);
      }
    }
  });

  const scrollLeft = () =>
    scrollRef.current?.scrollBy({ left: -scrollRef.current.clientWidth, behavior: 'smooth' });
  const scrollRight = () =>
    scrollRef.current?.scrollBy({ left: scrollRef.current.clientWidth, behavior: 'smooth' });

  return { scrollLeft, scrollRight, isLeftBorder, isRightBorder };
}
