import { createSelector } from '@reduxjs/toolkit';

import pageType from 'Addons/search/pageType.ts';
import { havePreventedSearchChanged, waitingForAutocompleteChanged } from 'Core/actions/autocomplete.ts';
import { assignLocation, replaceLocation } from 'Core/actions/redirect.ts';
import {
  autocompleteRedirectPreventedSearchSelector,
  autocompleteRedirectWaitingForResponseSelector,
  autocompleteResponseItemsSelector,
  autocompleteResponseReceivedSelector,
} from 'Core/selectors/autocomplete.ts';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';

const redirectUrlSelector = createSelector(
  autocompleteResponseItemsSelector,
  (items) => items?.find((item) => item.redirectUrl)?.redirectUrl ?? null,
);

export const shouldSearch = {
  forbid: 'do-not-search',
  allow: 'as-you-wish',
  force: 'search',
};

export default function tryAutocompleteRedirect({
  action: { type, checkAutocompleteRedirect, goToSearchPage },
  state,
}) {
  // if a redirect has already been started
  if (type === assignLocation.type || type === replaceLocation.type) {
    // prevent next search request
    return { shouldSearch: shouldSearch.forbid, actions: [] };
  }
  if (!requestConfig.doAutocompleteRedirect) {
    return { shouldSearch: shouldSearch.allow, actions: [] };
  }

  const haveBeenWaiting = autocompleteRedirectWaitingForResponseSelector(state);

  if (!(haveBeenWaiting || checkAutocompleteRedirect)) {
    return { shouldSearch: shouldSearch.allow, actions: [] };
  }

  const autocompleteResponseReceived = autocompleteResponseReceivedSelector(state);
  const havePreventedSearch = autocompleteRedirectPreventedSearchSelector(state);
  const willGoToSearchPage = !!(goToSearchPage && uiConfig.pageType !== pageType.search);

  if (!autocompleteResponseReceived) {
    return {
      shouldSearch: willGoToSearchPage ? shouldSearch.forbid : shouldSearch.allow,
      actions: haveBeenWaiting
        ? []
        : [
            waitingForAutocompleteChanged(true),
            willGoToSearchPage && havePreventedSearchChanged(willGoToSearchPage),
          ],
    };
  }

  const stopWaitingAction = haveBeenWaiting ? waitingForAutocompleteChanged(false) : null;

  const redirectUrl = redirectUrlSelector(state);
  if (redirectUrl) {
    return {
      shouldSearch: shouldSearch.forbid,
      actions: [
        stopWaitingAction,
        (haveBeenWaiting && !havePreventedSearch ? replaceLocation : assignLocation)(redirectUrl),
      ],
    };
  }
  if (havePreventedSearch) {
    return {
      shouldSearch: shouldSearch.force,
      actions: [stopWaitingAction, havePreventedSearchChanged(false)],
    };
  }

  return { shouldSearch: shouldSearch.allow, actions: [stopWaitingAction] };
}
