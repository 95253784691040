
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetsRT () {
    function repeatFacets1(themeAssetsUrl, facets, facetsIndex) {
        return [facets(function () {
                function onClick1() {
                    console.log(this);
                }
                function repeatShowAlwaysValues2(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                    return [ShowAlwaysValues(function () {
                            function mergeProps(inline, external) {
                                var res = Object.assign({}, inline, external);
                                if (inline.hasOwnProperty('style')) {
                                    res.style = _.defaults(res.style, inline.style);
                                }
                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                    res.className = external.className + ' ' + inline.className;
                                }
                                return res;
                            }
                            return _createElement('div', {}, _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount), _createElement('div', { 'className': 'cm_tooltip-container' }, _createElement('button', {
                                'onClick': e => window.Convermax.showTooltip(e),
                                'onMouseOver': e => window.Convermax.showTooltip(e),
                                'onMouseOut': e => window.Convermax.hideTooltip(e),
                                'className': 'cm_tooltip-btn'
                            }, '\n            ?\n          '), _createElement('div', mergeProps({ 'className': 'cm_tooltip-content cm_hide' }, { dangerouslySetInnerHTML: { __html: this.payload } })))));
                        }, { count: undefined })];
                }
                function repeatValues3(Values, ValuesIndex) {
                    return [Values(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatValues4(Values, ValuesIndex) {
                    return [Values(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), !this.isCustomRange ? [
                                _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem',
                                    'key': '1541'
                                }, _createElement('span', {}, this.value)),
                                _createElement('div', {
                                    'className': 'facetentryval',
                                    'key': '1543'
                                }, this.hitCount)
                            ] : null, this.isCustomRange ? [_createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem',
                                    'key': '3901'
                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                        }, { count: undefined })];
                }
                function scopeInchMm5() {
                    var inch = [].includes(this.field) ? '"' : '';
                    var mm = [
                        'wheel_bore',
                        'wheel_offset'
                    ].includes(this.field) ? 'mm' : '';
                    return _createElement('div', {
                        'className': 'cmTemplate_sliderFacet',
                        'key': '13834'
                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Min',
                                            'className': 'cm_inputMin'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                'className': 'separator',
                                'key': '334'
                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Max',
                                            'className': 'cm_inputMax'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, _createElement('button', {
                                'type': 'button',
                                'className': 'cm_btn cm_button cm_button__secondary',
                                'onClick': this.setCustomRange
                            }, '\n    GO\n  '));
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }
                function repeatShowAlwaysValues6(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                    return [ShowAlwaysValues(function () {
                            function scopeStarFill1(i, iIndex) {
                                var starFill = this.value - i;
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                });
                            }
                            function repeatI2(i, iIndex, starFill) {
                                return scopeStarFill1.apply(this, [
                                    i,
                                    iIndex
                                ]);
                            }
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, _createElement.apply(this, [
                                'span',
                                { 'className': 'cm_review-stars' },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI2.bind(this))
                            ]), this.value !== '5' ? _createElement('span', {
                                'className': 'cm_and-up',
                                'key': '499'
                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatValues7(Values, ValuesIndex) {
                    return [Values(function () {
                            function scopeStarFill1(i, iIndex) {
                                var starFill = this.value - i;
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                });
                            }
                            function repeatI2(i, iIndex, starFill) {
                                return scopeStarFill1.apply(this, [
                                    i,
                                    iIndex
                                ]);
                            }
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                'type': 'checkbox',
                                'className': 'cm_facet_checkbox',
                                'readOnly': true,
                                'checked': this.isSelected
                            }), _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, _createElement.apply(this, [
                                'span',
                                { 'className': 'cm_review-stars' },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI2.bind(this))
                            ]), this.value !== '5' ? _createElement('span', {
                                'className': 'cm_and-up',
                                'key': '499'
                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                        }, { count: undefined })];
                }
                function repeatShowAlwaysValues8(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                    return [ShowAlwaysValues(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                'className': 'facetimg' + (this.field === 'product_type' ? ' facetimg-product_type' : ''),
                                'src': this.field === 'vendor' ? this.imageUrl : themeAssetsUrl + `part-${ this.term.toLowerCase() }.png`,
                                'onError': event => event.target.removeAttribute('src')
                            }), _createElement('div', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value));
                        }, { count: undefined })];
                }
                function repeatValues9(Values, ValuesIndex) {
                    return [Values(function () {
                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                'className': 'facetimg' + (this.field === 'product_type' ? ' facetimg-product_type' : ''),
                                'src': this.field === 'vendor' ? this.imageUrl : themeAssetsUrl + `part-${ this.term.toLowerCase() }.png`,
                                'onError': event => event.target.removeAttribute('src')
                            }), _createElement('div', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, this.value));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                    _createElement('div', {
                        'className': 'facettitle',
                        'data-cm-role': 'toggle-facet',
                        'tabIndex': '0',
                        'key': '551'
                    }, _createElement('span', { 'onClick': onClick1.bind(this) }, this.name), this.isCollapsed ? [_createElement('svg', {
                            'className': 'cm_icon cm_icon-angle left',
                            'height': '20px',
                            'role': 'img',
                            'viewBox': '39 30 565 565',
                            'aria-hidden': 'true',
                            'key': '2730'
                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                            'className': 'cm_icon cm_icon-angle down',
                            'height': '20px',
                            'role': 'img',
                            'viewBox': '39 30 565 565',
                            'aria-hidden': 'true',
                            'key': '5940'
                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null),
                    _createElement('div', {
                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                        'role': 'list',
                        'key': '553'
                    }, this.template === 'simpleFacet' ? _createElement('div', {
                        'className': 'cmTemplate_simpleFacet',
                        'key': '1120'
                    }, this.showFilterInput ? _createElement('div', {
                        'className': 'filter-input',
                        'key': '1206'
                    }, [this.filterInput(function () {
                            return _createElement('div', {
                                'className': 'input  cm_filterInput',
                                'placeholder': 'Enter'
                            });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })], this.inputNotEmpty ? _createElement('span', {
                        'className': 'filter-input_clear-container',
                        'onClick': this.clearInput,
                        'key': '1526'
                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                            'className': 'facetdiv',
                            'key': '16951'
                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues2.bind(this))
                    ]), _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_Values' },
                        _map(this.Values, repeatValues3.bind(this))
                    ]), this.needShowMore ? _createElement('div', {
                        'className': 'facetdiv cm_show-all-container',
                        'key': '4581'
                    }, _createElement('a', {
                        'className': 'cm_show-all',
                        'data-cm-role': 'toggle-show-more',
                        'tabIndex': '0'
                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                        'className': 'cmTemplate_selectFacet',
                        'key': '4909'
                    }, [this.select(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3596'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3798'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '62'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8031'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8034'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2892'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null));
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                        'className': 'cmTemplate_priceFacet',
                        'key': '10891'
                    }, !this.ranges.length ? [
                        _createElement('div', { 'key': '109751' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                        '\n  ',
                        this.slider,
                        '\n  ',
                        _createElement('div', {
                            'className': 'cm_flex',
                            'key': '109753'
                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                            'div',
                            {
                                'className': 'cmRepeater_Values',
                                'key': '112391'
                            },
                            _map(this.Values, repeatValues4.bind(this))
                        ])] : null, [this.Inputs(function () {
                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                    return _createElement('div', {
                                        'placeholder': 'Min price',
                                        'className': 'cm_inputMin'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                    return _createElement('div', {
                                        'placeholder': 'Max price',
                                        'className': 'cm_inputMax'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], _createElement('button', {
                                'type': 'button',
                                'className': 'cm_btn cm_button cm_button__secondary',
                                'onClick': this.setCustomRange
                            }, '\n    GO\n  '));
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]) : null, this.template === 'sliderFacet' ? scopeInchMm5.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                        'className': 'cmTemplate_reviewFacet',
                        'key': '15603'
                    }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues6.bind(this))
                    ]), _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_Values' },
                        _map(this.Values, repeatValues7.bind(this))
                    ])) : null, this.template === 'tilesFacet' ? _createElement('div', {
                        'className': 'cmTemplate_tilesFacet',
                        'key': '18724'
                    }, this.showFilterInput ? _createElement('div', {
                        'className': 'filter-input',
                        'key': '18808'
                    }, [this.filterInput(function () {
                            return _createElement('div', {
                                'className': 'input  cm_filterInput',
                                'placeholder': 'Enter'
                            });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })], this.inputNotEmpty ? _createElement('span', {
                        'className': 'filter-input_clear-container',
                        'onClick': this.clearInput,
                        'key': '19128'
                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                            'className': 'facetdiv',
                            'key': '192971'
                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues8.bind(this))
                    ]), _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_Values' },
                        _map(this.Values, repeatValues9.bind(this))
                    ]), this.needShowMore ? _createElement('div', {
                        'className': 'facetdiv cm_show-all-container',
                        'key': '21304'
                    }, _createElement('a', {
                        'className': 'cm_show-all',
                        'data-cm-role': 'toggle-show-more'
                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null)
                ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                    'className': 'cmTemplate_toggleFacet',
                    'key': '21646'
                }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                    'className': 'cm_facet-toggle_input',
                    'type': 'checkbox',
                    'checked': this.isToggled,
                    'onClick': this.toggleFacet
                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
            }, { count: undefined })];
    }
    function scopeThemeAssetsUrl2() {
        var themeAssetsUrl = window.Convermax.config.themeAssetsUrl;
        return _createElement.apply(this, [
            'div',
            { 'className': 'cmRepeater_facets' },
            _map(this.facets, repeatFacets1.bind(this, themeAssetsUrl))
        ]);
    }
    return scopeThemeAssetsUrl2.apply(this, []);
}
        export const componentNames = ["cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:filterInput"]