import { equalByMany } from 'Utils/array.js';
import Facet from './facet.ts';
import FacetValue from './facetValue.ts';

import type { ArrayCallback, ArrayPredicate } from 'Core/types.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';
import type { FacetValueFull } from './facetValue.ts';

export default class FacetCollection {
  _facets: Facet[];

  constructor(
    responseFacets: ServerModel.FacetCategory[] = [],
    valuesFilter: ArrayPredicate<FacetValueFull> = () => true,
    baseTreeLevelMap: Record<string, number> = {},
  ) {
    this._facets = responseFacets.map((f) => new Facet(f, valuesFilter, baseTreeLevelMap[f.FieldName]));
  }

  get selection(): FacetValueFull[] {
    return this._facets.flatMap((f) => f.facetedSelection);
  }

  sameFacetsAsIn = (collection: FacetCollection): boolean => {
    if (!this.length && !collection.length) {
      return true;
    }
    return equalByMany(
      this._facets,
      collection._facets,
      ['selection', FacetValue.termKey],
      ['values', FacetValue.termKey, 'hitCount'],
    );
  };

  get length(): number {
    return this._facets.length;
  }

  filter(predicate: ArrayPredicate<Facet>, thisArg?: unknown): FacetCollection {
    const newCollection = new FacetCollection();
    newCollection._facets = this._facets.filter(predicate, thisArg);
    return newCollection;
  }

  get = (field: string): Facet | undefined => this._facets.find((f) => f.field === field);

  map<R>(func: ArrayCallback<Facet, R>, thisArg?: unknown): R[] {
    return this._facets.map(func, thisArg);
  }

  at = (index: number): Facet | undefined => this._facets[index];
}
