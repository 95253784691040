import { combineReducers } from 'redux';

import { dialogClosed, dialogOpened } from 'Core/actions/dialog.ts';
import { createReducersFromShorthands } from 'Core/reducers/common.ts';

const initStates = {
  extractorData: null,
  opened: [],
};

const extractedDialogNames = ['vehicle-dialog'];
const ignore = (name) => extractedDialogNames.includes(name);

export default combineReducers(
  createReducersFromShorthands(
    {
      opened: {
        [dialogOpened]: (state, { payload }) =>
          state.includes(payload) || ignore(payload) ? state : [...state, payload],
        [dialogClosed]: (state, { payload }) => (ignore(payload) ? state : state.slice(0, -1)),
      },
      extractorData: {
        [dialogOpened]: (state, { meta: { extractorData } }) => extractorData ?? state,
        default: () => initStates.extractorData,
      },
    },
    initStates,
  ),
);
