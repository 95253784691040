import { createAction } from '@reduxjs/toolkit';

export type ExtractorData = { filename: string; src: string } | null;
export type OpenDialogMetadata = {
  extractorData?: ExtractorData;
  vehicleWidgetIncluded?: boolean;
  withCurrentVehicle?: boolean;
};

export const dialogOpened = createAction('DIALOG_OPENED', (name: string, meta: OpenDialogMetadata = {}) => ({
  payload: name,
  meta,
}));

export const dialogClosed = createAction<string | undefined>('DIALOG_CLOSED');
