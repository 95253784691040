import _uniq from 'lodash-es/uniq.js';
import { cloneElement } from 'react';

import productConfig from 'Models/uiConfig/productConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';

import type { ReactNode, FormEvent, Ref, SyntheticEvent } from 'react';
import type { TemplateElementProps, TemplateResult } from 'Components/types.ts';
import type { ForwardedProps } from 'Utils/rtHmrTemplate.ts';

export type CloneSafeOptionsType = {
  appendedClasses?: string | (string | false | undefined)[];
  appendedChildren?: ReactNode | ReactNode[] | null;
};

// ref argument was added to remind to add ref or intentionally omit it
export function cloneSafe(
  elem: TemplateResult,
  ref: Ref<HTMLElement> | null,
  { appendedClasses, appendedChildren = null, ...props }: TemplateElementProps & CloneSafeOptionsType = {},
): TemplateResult {
  if (elem === null) {
    return null;
  }

  if (module.hot && elem.props.hmrable) {
    const forwardedProps: ForwardedProps = {
      delayedCloneSafe: (innerElem, innerRef) =>
        cloneSafe(innerElem, innerRef, { appendedClasses, appendedChildren, ...props }),
      ref,
    };
    return cloneElement(elem, forwardedProps);
  }

  const elemClassList = (elem.props.className || '').split(' ') as (string | false | undefined)[];
  const classList = elemClassList.concat(appendedClasses).filter(Boolean) as string[];
  const className = _uniq(classList).join(' ');

  const children = ([] as (ReactNode | undefined)[])
    .concat(elem.props.children, appendedChildren)
    .filter(Boolean);

  return cloneElement(elem, { ...props, className, ref }, ...children);
}

export function createOnImageError(): (
  e: SyntheticEvent<HTMLImageElement>,
  key: string | number | null,
) => void {
  const handledErrors = {};
  return (e, key = null) => {
    if (!handledErrors[`${key}`]) {
      e.currentTarget.src = productConfig.noImageSrc;
      handledErrors[`${key}`] = true;
    }
  };
}

// https://www.shopify.com/partners/blog/img-url-filter Crop section
type ShopifyCrop = 'top' | 'center' | 'bottom' | 'left' | 'right';
export function setImageSize(
  image: string | null,
  width?: number,
  height?: number,
  crop?: ShopifyCrop,
): string | null {
  if (uiConfig.platform === 'shopify' && image) {
    const url = new URL(image);

    if (width) {
      url.searchParams.set('width', width.toString());
    }
    if (height) {
      url.searchParams.set('height', height.toString());
    }
    if (crop) {
      url.searchParams.set('crop', crop);
    }

    return url.href;
  }

  return image;
}

export function preventFormSubmission(e: FormEvent<'form'>): void {
  e.preventDefault();
  e.stopPropagation();
}
