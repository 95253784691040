export function discard(value, options = {}) {
  return { type: 'DISCARD', sendRequest: true, value, ...options };
}

export function discardField(field, options = {}) {
  return { type: 'DISCARD_FIELD', sendRequest: true, ...options, field };
}

export function replaceValue(value, options = {}) {
  return { type: 'REPLACE_VALUE', sendRequest: true, ...options, value };
}
export function discardManyFields(fields, options = {}) {
  return { type: 'DISCARD_MANY_FIELDS', sendRequest: true, ...options, fields };
}

export function replaceManyValues(values, options = {}) {
  return { type: 'REPLACE_MANY_VALUES', sendRequest: true, ...options, values };
}

export function toggle(value, options = {}) {
  return { type: 'TOGGLE', sendRequest: true, ...options, value };
}

export function replaceColor(value, options = {}) {
  return { type: 'REPLACE_COLOR', sendRequest: true, ...options, value };
}

export function toggleColor(value, options = {}) {
  return { type: 'TOGGLE_COLOR', sendRequest: true, ...options, value };
}

export function loadMore() {
  return { type: 'LOAD_MORE', sendRequest: true };
}

export function setPageNumber(pageNumber) {
  return { type: 'SET_PAGE_NUMBER', sendRequest: true, pageNumber };
}

export function setPageSize(pageSize) {
  return { type: 'SET_PAGE_SIZE', sendRequest: true, pageSize };
}

export function setPrevPage() {
  return { type: 'SET_PREV_PAGE', sendRequest: true };
}

export function setNextPage() {
  return { type: 'SET_NEXT_PAGE', sendRequest: true };
}

export function setSort(sort) {
  return { type: 'SET_SORT', sendRequest: true, sort };
}

export function discardQuery(options = {}) {
  return { type: 'DISCARD_QUERY', sendRequest: true, ...options };
}

export function filterQueryResetRequested(options = {}) {
  return { type: 'FILTER_QUERY_RESET_REQUESTED', sendRequest: true, ...options };
}

export function filterQueryChanged(value) {
  return { type: 'FILTER_QUERY_CHANGED', payload: value, sendRequest: true };
}

export function replaceRequest(options) {
  return { type: 'REPLACE_REQUEST', sendRequest: true, ...options };
}

export function resetRequest(options) {
  return { type: 'RESET_REQUEST', sendRequest: true, ...options };
}

export function retryRequest(requestAction) {
  const action = { type: 'RETRY_REQUEST', sendRequest: true, allowSearchSameAsUri: true };
  return requestAction ? { ...action, action: requestAction } : action;
}

export function skipRequest() {
  return { type: 'SKIP_REQUEST' };
}

export function removeMaxCountByField(field) {
  return { type: 'REMOVE_MAX_COUNT_BY_FIELD', field, sendRequest: true, allowSearchSameAsUri: true };
}

export function resetMaxCountByField(field) {
  return { type: 'RESET_MAX_COUNT_BY_FIELD', field, sendRequest: true, allowSearchSameAsUri: true };
}
