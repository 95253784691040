import useEventListener from '@use-it/event-listener';
import { FC, useCallback, useRef } from 'react';
import { AutoFocusInside } from 'react-focus-lock';
import { useDispatch, useSelector } from 'react-redux';

import { setAutocompleteQuery } from 'Core/actions/autocomplete.ts';
import { autocompleteRequestQuerySelector } from 'Core/selectors/autocomplete.ts';

import type { RefObject } from 'react';
import type { TemplateFunction } from 'Components/types.ts';

export type Params = {
  name: string;
};

type Props = {
  template: TemplateFunction<Params>;
  name: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  rootRef: RefObject<HTMLElement>;
};

const SearchInput: FC<Props> = ({ template, name, onChange, onFocus, rootRef }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const query = useSelector(autocompleteRequestQuerySelector);

  // hide keyboard on mobile when we scroll dropdown
  useEventListener(
    'touchmove',
    useCallback(() => inputRef.current?.blur(), []),
    rootRef.current?.querySelector<HTMLElement>('.cm_autocomplete'),
  );

  const component = template.call({ name });
  if (!component) {
    return null;
  }

  const { className: templateClassName, children: templateChildren, ...templateProps } = component.props;

  if (typeof templateChildren !== 'string' && templateChildren !== undefined) {
    console.error(
      'searchInput children expected to be either a string or nothing. Received:',
      templateChildren,
    );
    return null;
  }

  const props = {
    type: 'text',
    autoComplete: 'off',
    ...templateProps,
    className: [templateClassName, 'nav-root'].filter(Boolean).join(' '),
    children: null,
    placeholder: templateChildren,
    value: query,
    onChange: ({ target: { value } }) => {
      dispatch(setAutocompleteQuery(value));
      onChange(value);
    },
    onFocus,
    ref: inputRef,
  };

  if (name === 'SearchBoxDialog') {
    return (
      <AutoFocusInside>
        <input {...props} />
      </AutoFocusInside>
    );
  }

  return <input {...props} />;
};

export default SearchInput;
