export function animate(draw, duration) {
  const start = Date.now();
  let run = true;
  const cancel = () => (run = false);

  const promise = new Promise((res) => {
    requestAnimationFrame(function frame() {
      const progress = (Date.now() - start) / duration;
      if (run) {
        if (progress >= 1) {
          draw(1);
          res();
        } else {
          draw(progress);
          requestAnimationFrame(frame);
        }
      }
    });
  });

  return { promise, cancel };
}

export function animateLinear(from, to, draw, duration) {
  return animate((progress) => draw(from + (to - from) * progress), duration);
}
