import productConfig from 'Models/uiConfig/productConfig.js';
import { keysSelector, getRequestFromUri, getUriFromRequest } from 'Modules/converter/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import Subtile from './subtile.ts';

import type { FC } from 'react';
import type { RepeaterFunctionInvoker, TemplateFunction, TemplateFunctionInvoker } from 'Components/types.ts';
import type { FacetRequest, FacetValueFull } from 'Models/index.ts';

export interface SubvalueParams {
  value: string;
  href?: string;
  getImageOrDefault: (imageUrl: string) => string;
  isMultiLevel?: boolean;
  vehicleWidget?: TemplateFunctionInvoker<unknown>;
}

export interface Params extends SubvalueParams {
  currentTab: string | null;
  subvalues: RepeaterFunctionInvoker<SubvalueParams>;
  setCurrentTab: (tile: string) => void;
  isMultiLevel?: boolean;
}

const baseParams = ['value' as const, 'term' as const];
type FacetValue = Pick<FacetValueFull, (typeof baseParams)[number]>;

type Props = {
  template: TemplateFunction<Params>;
  value: FacetValueFull;
  subvalues: FacetValueFull[];
  currentTab: string | null;
  vehicleFromUriSelection: FacetRequest[];
  vehicleWidget: TemplateFunctionInvoker<unknown>;
  setCurrentTab: (tile: string) => void;
  isMultiLevel?: boolean;
};

const Tile: FC<Props> = ({
  template,
  value,
  subvalues,
  currentTab,
  isMultiLevel,
  vehicleFromUriSelection,
  vehicleWidget,
  setCurrentTab,
}) => {
  const subvaluesMap = subvalues.map((subvalue) => (templ) => {
    const props = {
      template: templ,
      subvalue,
      vehicleFromUriSelection,
      vehicleWidget,
    };

    return <Subtile {...props} key={subvalue.term} />;
  }) as RepeaterFunctionInvoker<SubvalueParams>;

  return template.call({
    ...getParams(value, vehicleFromUriSelection),
    subvalues: subvaluesMap,
    currentTab,
    isMultiLevel,
    getImageOrDefault,
    setCurrentTab,
    vehicleWidget,
  });
};

export function getImageOrDefault(imageUrl: string) {
  return imageUrl || productConfig.noImageSrc;
}

export function getParams(
  value: FacetValueFull,
  vehicleFromUriSelection?: FacetRequest[],
): FacetValue & { imageUrl: FacetValueFull['imageUrl']; href: string; payload: string } {
  const { url: valueUrl, imageUrl, payload } = value;
  const url = valueUrl ? (valueUrl.includes('/') ? valueUrl : `${window.location.href}/${valueUrl}`) : '';

  let href = '';

  if (vehicleFromUriSelection?.length) {
    if (url) {
      let { selection } = getRequestFromUri(new URL(url, window.location.origin));

      if (
        !selection.some(
          (f: FacetRequest) => f.field === 'Vehicle' || fitmentSearchConfig.fields.includes(f.field),
        )
      ) {
        selection = [...selection, ...vehicleFromUriSelection];
      }

      ({ href } = getUriFromRequest({ selection }, { pathname: new URL(url, window.location.origin).href }));
    } else {
      ({ href } = getUriFromRequest(
        { selection: [value, ...vehicleFromUriSelection] },
        { goToSearchPage: true },
      ));
    }
  } else {
    href = url ? url : getUriFromRequest({ selection: [value] }, { goToSearchPage: true }).href;
  }

  return {
    ...keysSelector<FacetValueFull, FacetValue>(...baseParams)(value),
    href,
    imageUrl,
    payload: payload || '',
  };
}

export default Tile;
