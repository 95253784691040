import { isTermColor, isTermRanged } from 'Modules/termType.ts';

export function uriEncodeTerm(term) {
  return encodeURIComponent(isTermColorOrRanged(term) ? encodeSpacesDashes(term) : term);
}

export function uriDecodeTerm(term) {
  const decoded = decodeURIComponent(term);
  return isTermColorOrRanged(decoded) ? decodeSpacesDashes(decoded) : decoded;
}

function isTermColorOrRanged(term) {
  return isTermColor(term) || isTermRanged(term);
}

export function uriEncode(value) {
  return encodeURIComponent(encodeSpacesDashes(value));
}

export function uriDecode(value) {
  return decodeSpacesDashes(decodeURIComponent(value));
}

function encodeSpacesDashes(value) {
  return value.replace(/([^\s-])(-+)/g, '$1$2-').replace(/([^\s-]) ([^\s-])/g, '$1-$2');
}

function decodeSpacesDashes(value) {
  return value.replace(/([^\s-])-([^\s-])/g, '$1 $2').replace(/([^\s-])-/g, '$1');
}
