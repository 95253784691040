import { CustomError, CustomErrorType } from 'Models/index.ts';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import getAnalytics from 'Modules/analytics.js';
import { queryParamsFromObject } from 'Modules/converter/index.js';

const { backupServerUrl, serverUrl, timeout } = requestConfig;

const analytics = getAnalytics();

export function abortControllerCreator() {
  const abortControllerRef = { current: new AbortController() };
  return () => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current.signal;
  };
}

export async function GET(endpoint, queryParams, config) {
  return await request(serverUrl, endpoint, queryParams, { ...config, method: 'GET' });
}

export async function GETBACKUP(endpoint, queryParams, config) {
  return await request(backupServerUrl, endpoint, queryParams, { ...config, method: 'GET' });
}

export async function POST(endpoint, queryParams, data, config) {
  return await request(serverUrl, endpoint, queryParams, { ...config, method: 'POST', body: data });
}

export async function POSTBACKUP(endpoint, queryParams, data, config) {
  return await request(backupServerUrl, endpoint, queryParams, { ...config, method: 'POST', body: data });
}

export function sendBeacon(endpoint, data) {
  return navigator.sendBeacon(`${serverUrl}/${endpoint}`, data);
}

async function request(serverUrl, endpoint, queryParams, config) {
  const url = `${serverUrl}/${endpoint}${queryParams ? `?${queryParamsFromObject(queryParams)}` : ''}`;

  let timeoutExceeded = false;
  const abortController = handleAbortController(config.signal);
  const timeoutController = setTimeout(() => {
    timeoutExceeded = true;
    abortController.abort();
  }, timeout);

  let requestError = null;

  const headers = {
    ...config?.headers,
  };

  if (analytics.isAnalyticsEnabled) {
    headers['Content-Language'] = `${analytics.userId};${analytics.sessionId}`;
  }

  const response = await window
    .fetch(url, {
      ...config,
      headers,
      signal: abortController.signal,
    })
    .catch((err) => {
      if (timeoutExceeded) {
        requestError = new CustomError(`request timeout exceeded for '${url}'.`, { error: err });
      }
      requestError = new CustomError(`request for '${url}' failed with error: '${err.message}'.`, {
        error: err,
      });
    });

  if (requestError) {
    throw requestError;
  }

  if (response?.ok) {
    clearTimeout(timeoutController);

    const responseText = await response.text();

    if (responseText) {
      try {
        return JSON.parse(responseText);
      } catch (err) {
        return responseText;
      }
    }

    return null;
  }

  if (response) {
    throw new CustomError(`request for '${url}' failed with status: '${response.status}'.`, {
      status: response.status,
    });
  }

  // empty response object with no errors?
}

const createServerInstanceAbortController = abortControllerCreator();
async function getServerInstance() {
  const abortController = handleAbortController(createServerInstanceAbortController());
  const timeoutId = setTimeout(() => {
    abortController.abort();
  }, timeout);

  const response = await window
    .fetch('https://client.convermax.com/status.json', { signal: abortController.signal })
    .catch(() => {
      return 'unknown';
    });

  if (response.ok) {
    clearTimeout(timeoutId);

    const { InstanceId } = await response.json();

    if (InstanceId) {
      return InstanceId;
    }
  }

  return 'unknown';
}

function handleAbortController(parentSignal) {
  const abortController = new AbortController();

  if (parentSignal) {
    followSignal(abortController, parentSignal);
  }

  return {
    abort: () => abortController.abort(),
    signal: abortController.signal,
  };
}

// https://github.com/whatwg/dom/issues/920#issuecomment-726722960
// TODO: replace with AbortSignal.any() when browsers add support https://github.com/shaseley/abort-signal-any
function followSignal(followingAbortController, parentSignal) {
  // 1. If followingSignal’s aborted flag is set, then return.
  if (followingAbortController.signal.aborted) {
    return;
  }
  // 2. If parentSignal’s aborted flag is set, then signal abort on followingSignal.
  if (parentSignal.aborted) {
    followingAbortController.abort();
    return;
  }
  // 3. Otherwise, add the following abort steps to parentSignal:
  const onAbort = () => {
    // 3.1. Signal abort on followingSignal.
    followingAbortController.abort();
  };
  parentSignal.addEventListener('abort', onAbort, { once: true });
  // Non-standard: if followingSignal becomes aborted for some other reason,
  // remove the abort steps from the parentSignal.
  followingAbortController.signal.addEventListener('abort', () => {
    parentSignal.removeEventListener('abort', onAbort);
  });
}

const emptySearchResponse = {
  Actions: [],
  Corrections: [],
  Facets: [],
  Items: [],
  Messages: [],
  OriginalQuery: '',
  Query: '',
  SeeAlsoCategories: [],
  SeeAlsoItems: null,
  TotalHits: 0,
  error: null,
};
export async function getErrorSearchResponse(err) {
  const { type, status } = err || {};

  if (type === CustomErrorType.requestTimeout) {
    return { ...emptySearchResponse, State: 'timeout' };
  }

  if (type === CustomErrorType.requestAborted) {
    return { ...emptySearchResponse, State: 'cancelled' };
  }

  const instance = await getServerInstance();
  const error = { status, ...analytics, timestamp: new Date().toUTCString(), instance };

  if (type === CustomErrorType.failedToFetch) {
    return {
      ...emptySearchResponse,
      State: 'networkError',
      error,
    };
  }

  if (+status >= 400 && +status < 500) {
    return { ...emptySearchResponse, State: 'badRequest', error };
  }

  if (isNaN(status)) {
    console.error('search request failed with unknown error', err);
    return { ...emptySearchResponse, State: 'unknown', error };
  }

  return { ...emptySearchResponse, State: 'unknown', error };
}
