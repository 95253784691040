import { createAction } from '@reduxjs/toolkit';

import { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';

export type FitmentFields = { base: string[]; extra: string[] };

export const setFitmentFields = createAction(
  'SET_FITMENT_FIELDS',
  (fields: FitmentFields, isolatedKey: IsolatedKey) => {
    return {
      payload: fields,
      meta: { isolatedKey },
    };
  },
);
