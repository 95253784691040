import { useDispatch } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';

export default function ToggleFacet({ facet, templateFunc, config, facetRef, commonParams }) {
  const dispatch = useDispatch();

  const { term } = config;
  const { field, facetedValues, facetedSelection } = facet;

  const isTermSelected = !!facetedSelection.find((f) => f.term === term)?.isSelected;

  if (!facetedValues.some((f) => f.term === term)) {
    return null;
  }

  const toggleFacet = () => {
    dispatch(toggle({ field, term }));
  };

  const component = templateFunc.call({ ...commonParams, isToggled: isTermSelected, toggleFacet });

  return cloneSafe(component, facetRef);
}
