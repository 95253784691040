import Cookies from 'universal-cookie';
import requestConfig from 'Models/uiConfig/requestConfig.js';

const cookie = new Cookies();

const fiveYears = 5 * 365 * 24 * 60 * 60 * 1000;
const halfAnHour = 30 * 60 * 1000;

export default function getAnalytics() {
  const { isAnalyticsEnabled } = requestConfig;
  if (isAnalyticsEnabled) {
    return {
      userId: getOrCreateCookie('cmuid', fiveYears),
      sessionId: getOrCreateCookie('cmsid', halfAnHour, { renew: true }),
      isAnalyticsEnabled,
    };
  }

  return {
    userId: '',
    sessionId: '',
    isAnalyticsEnabled,
  };
}

function getOrCreateCookie(name, duration, { renew } = {}) {
  let value = cookie.get(name);

  if ((!value && (value = generateId())) || renew) {
    const date = new Date(Date.now() + duration);
    cookie.set(name, value, { expires: date, path: '/' });
  }

  return value;
}

const idLength = 16;
const alphaNums = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const sample = (arr) => arr[Math.floor(Math.random() * arr.length)];
function generateId() {
  for (let id = ''; ; id += sample(alphaNums)) {
    if (id.length === idLength) {
      return id;
    }
  }
}
