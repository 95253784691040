import { useDispatch, useSelector } from 'react-redux';

import { Message } from 'Components/message/index.js';
import SearchItem from 'Components/searchItem/index.jsx';
import Loading from 'Components/smallComponents/loading.tsx';
import { setSort } from 'Core/actions/request.js';
import { useIdleEffect } from 'Core/hooks/index.js';
import { loadMoreFailedSelector, searchItemsSelector } from 'Core/selectors/search.js';
import { resultsLoadingSelector, viewSelector } from 'Core/selectors/show.ts';
import { cloneSafe } from 'Utils/components.ts';
import LoadMoreController from './loadMoreController.jsx';
import ScrollController from './scrollController.ts';
import './style.scss';

export default function SearchResult({
  template,
  instantInfiniteScroll,
  infiniteScrollDisabled,
  updateCallback = () => {
    /* stub */
  },
}) {
  const dispatch = useDispatch();
  const setSortField = (field) => dispatch(setSort(field));

  const itemsData = useSelector(searchItemsSelector);
  const view = useSelector(viewSelector);

  useIdleEffect(updateCallback);

  const items = itemsData.repeaterComponents(SearchItem);

  const isLoadMoreFailed = useSelector(loadMoreFailedSelector);
  const message = (templ) =>
    isLoadMoreFailed ? <Message template={templ} requestAction="add" key="message" /> : null;

  const loading = <Loading selector={resultsLoadingSelector} key="loading" />;

  const loadMore = (templ) => {
    const loadMoreProps = {
      template: templ,
      isInstant: instantInfiniteScroll,
      isInfiniteScrollDisabled: infiniteScrollDisabled,
      isLoadMoreFailed,
    };
    return <LoadMoreController {...loadMoreProps} key="load-more" />;
  };

  const scrollController = (templ) => <ScrollController template={templ} key="scroll-to-top" />;

  const component = template.call({
    items,
    loadMore,
    message,
    scrollController,
    setSort: setSortField,
    view,
  });

  return cloneSafe(component, null, { appendedChildren: loading });
}
