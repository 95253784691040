import { useContext, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { discardField } from 'Core/actions/request.js';
import { useCollapseFacet, useSwitcher } from 'Core/hooks/index.js';
import { createDialogOpenedSelector } from 'Core/selectors/dialog.js';
import { createSearchFacetHasSelectionSelector } from 'Core/selectors/search.js';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import { FacetsPanelConfig, FacetDialogName } from './common/index.ts';
import facetTypeToComponent from './facet/index.js';
import Tooltip from './tooltip.jsx';

import type { FC, RefObject } from 'react';
import type { TemplateFunctionInvoker, TemplateFunction, TemplateResult } from 'Components/types.ts';
import type { Facet, FacetValueFull } from 'Models/index.ts';

export type FacetConfig = ReturnType<typeof facetsConfig.getFacetConfig>;

export type Params = unknown;

export type CommonParams = {
  template: string;
  isCollapsed: boolean;
  selectedSize: number;
  tooltip: TemplateFunctionInvoker<unknown>;
} & Omit<Facet, 'facetedSelection' | 'facetedValues'>;

export type FacetCommonProps = {
  facet: Facet;
  field: string;
  selection: FacetValueFull[];
  config: FacetConfig;
  templateFunc: TemplateFunction<Params>;
  facetRef: RefObject<HTMLElement>;
  commonParams: CommonParams;
  commonRoles: {
    toggleFacet: () => void;
    resetFacet: () => void;
    nextCase: () => void;
  };
};

type Props = {
  template: TemplateFunction<Params>;
  facet: Facet;
};

const BaseFacet: FC<Props> = ({ facet, facet: { field, name, selection, displayMode }, template }) => {
  const dispatch = useDispatch();
  const hasSelectionSelector = useMemo(() => createSearchFacetHasSelectionSelector(facet), [facet]);
  const hasSelection = useSelector(hasSelectionSelector);
  const hadSelectionRef = useRef(hasSelection);

  const { disableCollapse, initCollapsed, facetPanelName, initExpandedFacets } =
    useContext(FacetsPanelConfig);

  const facetDialogOpened = useSelector(
    useMemo(() => createDialogOpenedSelector(FacetDialogName), []),
  ) as boolean;
  const [switchCase, nextCase] = useSwitcher(facetsConfig.getSwitcherArgs(field, facetPanelName));
  const facetRef = useRef<HTMLElement>(null);
  const [isCollapsed, toggleFacet] = useCollapseFacet(
    facetRef,
    disableCollapse,
    initCollapsed && !initExpandedFacets?.includes(field),
    hasSelection,
    hadSelectionRef,
  );
  const resetFacet = () => dispatch(discardField(field, { mayDiscardValue: true }));

  const tooltip = (templ: TemplateFunction<unknown>) => {
    const props = { template: templ, name, field, key: 'tooltip' };
    return (<Tooltip {...props} />) as TemplateResult;
  };

  const nextCaseSizeFix = () => {
    nextCase();
    if (hasSelection) {
      resetFacet();
    }
  };

  const isInFacetDialog = facetDialogOpened && facetPanelName === FacetDialogName;
  const config = facetsConfig.getFacetConfig(displayMode, field, facetPanelName, switchCase, isInFacetDialog);

  const commonProps: FacetCommonProps = {
    facet,
    field,
    selection,
    config,
    templateFunc: template,
    facetRef,
    commonParams: {
      ...facet,
      template: config.name,
      isCollapsed,
      selectedSize: facet.facetedSelection.length,
      tooltip,
    },
    commonRoles: {
      toggleFacet,
      resetFacet,
      nextCase: nextCaseSizeFix,
    },
  };

  const Facet = facetTypeToComponent[config.type] as FC<FacetCommonProps>;
  return Facet ? <Facet {...commonProps} /> : null;
};

export default BaseFacet;
