import _ceil from 'lodash-es/ceil.js';
import _floor from 'lodash-es/floor.js';

import type { Step, Facet } from 'Models/index.ts';

export type MinMax = [string, string];

export function getRangeFromTerm(term: string): MinMax {
  const termRange = term.split(' TO ');

  // ['68.2']
  if (termRange.length === 1) {
    return [termRange[0], termRange[0]];
  }

  // ['30001', '50000']
  if (termRange.length === 2) {
    return [termRange[0], termRange[1]];
  }

  // unknown
  return ['*', '*'];
}
export function getNormBoundsFromRange(facet: Facet, step: Step): MinMax {
  const ranges = facet.values
    .flatMap((v) => getRangeFromTerm(v.term))
    .filter((v) => v !== '*')
    .map(Number);

  const [min, max] = [
    _ceil(Math.min(...ranges), step.accuracy).toString(),
    _floor(Math.max(...ranges), step.accuracy).toString(),
  ];

  const resultBounds = [
    min === '*' ? '*' : step.fit(+min, 'ceil'),
    max === '*' ? '*' : step.fit(+max, 'floor'),
  ] as MinMax;

  return +resultBounds[0] >= +resultBounds[1] ? [resultBounds[0], resultBounds[0]] : resultBounds;
}

export function getRangeFiniteness(ranges: MinMax): {
  finite: boolean;
  toPosInf: boolean;
  toNegInf: boolean;
} {
  const openMin = ranges[0] === '*';
  const openMax = ranges[1] === '*';

  return {
    finite: !openMin && !openMax,
    toPosInf: !openMin && openMax,
    toNegInf: openMin && !openMax,
  };
}

export function getRangeFromValue(value: string, term: string): MinMax {
  // '¥300,001+'
  if (!value.includes(' TO ') && !value.includes(' - ')) {
    const [from, to] = getRangeFromTerm(term);

    // ['¥300,001+', '*']
    return from === '*' ? [from, value] : [value, to];
  }

  // '¥30,001 - ¥50,000' or '61.9 TO 156.6'
  const valueRange = value.includes(' TO ') ? value.split(' TO ') : value.split(' - ');
  if (valueRange.length === 2) {
    // ['¥30,001', '¥50,000']
    return [valueRange[0], valueRange[1]];
  }

  // unknown
  return ['*', '*'];
}

// TODO: move this to the server, this is only used in rugs stores (find by "step: 'imperial'")
export function convertDecimalToImperial(value: number | string): string {
  const numValue = +value;
  const foot = Math.floor(numValue);
  const inch = Math.round((numValue - foot) * 12);
  return foot ? `${foot}'${inch}"` : `${inch}"`;
}

export function getNumPercent(value: number, [min, max]: MinMax): number {
  return (100 / (+max - +min)) * (+value - +min);
}
