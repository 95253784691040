import { names } from 'Addons';
import { fitmentSearchFieldsSelector, vehicleSelectionSelector } from 'Core/selectors/fitmentSearch/index.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { createFacetValueArraySelector } from 'Modules/selectors.js';

const getSelectors = (map) => names.map((name) => map[name]).filter(Boolean);

const preselectionSelectorsMap = {
  fitmentSearch: vehicleSelectionSelector,
  search: () => requestConfig.localPreselection,
};

export const searchPreselectionSelector = (state) =>
  getSelectors(preselectionSelectorsMap).flatMap((selector) => selector(state));

const autocompleteFieldsSelectorsMap = {
  fitmentSearch: (state) => fitmentSearchFieldsSelector(state),
  search: () => requestConfig.autocomplete.fields,
};

export const autocompletePreselectionSelector = createFacetValueArraySelector(
  // This is a bad fix to make this selector work on fitmentSearch parts page
  // TODO: when extracting of URL handling logic is done,
  //  change this selector back to use only searchPreselectionSelector
  (state) => state.search.request.selection,
  searchPreselectionSelector,
  (state) => getSelectors(autocompleteFieldsSelectorsMap).flatMap((selector) => selector(state)),
  (selection, preselection, fields) =>
    [...selection, ...preselection].filter((sel) => {
      return fields.includes(sel.field);
    }),
);
