import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { replaceValue } from 'Core/actions/request.js';
import { FacetValue, Step } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import {
  getNormBoundsFromRange,
  getRangeFromValue,
  getRangeFromTerm,
  convertDecimalToImperial,
} from 'Utils/ranged.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { Slider, RangedInputs } from '../common/index.ts';

import type { FC, MouseEventHandler } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import type { MinMax } from 'Utils/ranged.ts';
import type { FacetCommonProps, CommonParams } from '../baseFacet.js';
import type { Params as RangedInputsParams } from '../common/rangedInputs.js';

type Params = Omit<CommonParams, 'selection'> & {
  slider: JSX.Element;
  min: number;
  max: number;
  rawMin: string;
  rawMax: string;
  isNullRange: boolean;
  selectedRange: MinMax;
  selectedRawRange: MinMax;
  Inputs: TemplateFunctionInvoker<RangedInputsParams>;
};

type Props = Omit<FacetCommonProps, 'templateFunc'> & {
  templateFunc: TemplateFunction<Params>;
  allowEqualBounds?: true;
};

const RangedSliderFacet: FC<Props> = ({
  facet,
  field,
  allowEqualBounds,
  templateFunc,
  facetRef,
  commonParams,
  commonRoles,
  config: { step: rawStep = '1', slider: { mode = '' } = {} },
}) => {
  const dispatch = useDispatch();
  const step = useMemo(() => new Step(rawStep), [rawStep]);

  const normBounds: MinMax = getNormBoundsFromRange(facet, step);
  const [minNormBound, maxNormBound] = normBounds.map((m) => +m);
  if (minNormBound === maxNormBound && !allowEqualBounds) {
    return null;
  }

  const selectedTerms: MinMax = facet.selection.length
    ? getRangeFromTerm(facet.selection[0].term)
    : ['*', '*'];
  const selectedNormRange = selectedTerms.map((selectedValue, i) => {
    const value = selectedValue.replace(/^\*$/, String([minNormBound, maxNormBound][i]));
    return step.imperial ? convertDecimalToImperial(value) : value;
  }) as MinMax;

  const rawBounds = getRangeFromValue(facet.values[0].value, facet.values[0].term);
  const selectedValues: MinMax = facet.selection.length
    ? getRangeFromValue(facet.selection[0].value, facet.selection[0].term)
    : ['*', '*'];
  const selectedRawRange = selectedValues.map((selectedValue, i) => {
    const value = selectedValue.replace(/^\*$/, rawBounds[i]);
    return step.imperial ? convertDecimalToImperial(value) : value;
  }) as MinMax;

  function normRangedSelection(values: MinMax, [min, max]: MinMax): MinMax {
    return values.map((value, i) => {
      if (value === '*') {
        return [min, max][i];
      }
      if (+value > +max) {
        return max;
      }
      return +value < +min ? min : value;
    }) as MinMax;
  }
  const slider = (
    <Slider
      field={field}
      bounds={normBounds}
      selected={normRangedSelection(selectedTerms, normBounds)}
      step={step}
      mode={mode}
      key={`slider-for-${field}`}
    />
  );

  function handleCustomRange([minValue, maxValue]: MinMax) {
    const term = FacetValue.createRangedTerm(minValue, maxValue);
    const isSelected = !(minValue === '*' && maxValue === '*');
    dispatch(replaceValue({ term, field, isUnique: true, isSelected }, { mayDiscardValue: true }));
  }
  const Inputs = (templ: TemplateFunction<RangedInputsParams>) =>
    (
      <RangedInputs
        template={templ}
        handleCustomRange={handleCustomRange}
        key={`inputs-for-${field}`}
        bounds={normBounds}
        step={step}
        mode={mode}
        selectedValues={selectedTerms}
      />
    ) as TemplateResult;

  const onClick: MouseEventHandler<HTMLElement> = simpleHandler({ ...commonRoles });

  const component = templateFunc.call({
    ...commonParams,
    slider,
    min: minNormBound,
    max: maxNormBound,
    rawMin: rawBounds[0],
    rawMax: rawBounds[1],
    isNullRange: minNormBound === maxNormBound,
    selectedRange: selectedNormRange,
    selectedRawRange,
    Inputs,
  });
  return cloneSafe(component, facetRef, { onClick });
};

export default RangedSliderFacet;
