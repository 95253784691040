import { createReducer } from 'Core/reducers/common.ts';
import { setResponse } from 'Core/actions/response.ts';

const initState = 0;

export default createReducer(
  {
    RETRY_REQUEST: (state) => state + 1,
    [setResponse.type]: (state, { payload }) =>
      state && payload.state === 'timeout' ? state + 1 : initState,
  },
  initState,
);
