import { combineReducers } from 'redux';

import autocomplete from './autocomplete/index.ts';
import dialog from './dialog.js';
import fitmentSearch from './fitmentSearch/index.ts';
import localStorage from './localStorage.js';
import product from './product.ts';
import search from './search/index.js';
import show from './show.js';

export default combineReducers({
  autocomplete,
  dialog,
  fitmentSearch,
  localStorage,
  product,
  search,
  show,
});
