import { useDispatch } from 'react-redux';

import { setNextPage, setPageNumber, setPrevPage } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';

const typeToActionMap = {
  next: setNextPage,
  prev: setPrevPage,
  page: setPageNumber,
  delimiter: null,
};

export default function PaginationButton({
  template,
  type,
  pageLink,
  pageNumber,
  totalPages,
  isActive,
  style,
  hideDropdown,
}) {
  const dispatch = useDispatch();
  const actionCreator = typeToActionMap[type];

  let props = { style };
  let component;
  if (actionCreator) {
    const onClick = (e) => {
      e.preventDefault();
      if (!isActive) {
        if (hideDropdown) {
          hideDropdown();
        }
        dispatch(actionCreator(pageNumber));
      }
    };
    component = template.call({
      template: type,
      page: pageNumber + 1,
      totalPages,
      isActive,
      pageLink,
    });
    props = { ...props, onClick };
  } else {
    component = template.call({ template: type });
  }

  return cloneSafe(component, null, { ...props });
}
