import { FC, MouseEventHandler } from 'react';

import DropdownItem from './dropdownItem.jsx';

import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { AutocompleteItem } from 'Models/index.ts';
import type { AutocompleteItemType } from 'Models/autocompleteItem.ts';

const defaultItemToClickHandler = (item: AutocompleteItem) => {
  console.error('itemToClickHandler for this item was not passed', item);
  return () => {
    /* stub handler */
  };
};

export type Params = {
  sectionTitle?: string;
  query?: string;
  items: RepeaterFunctionInvoker<unknown>;
  vehicleString?: string;
  totalHits?: number;
  browseAllButton?: TemplateFunctionInvoker<unknown>;
};

export type Props = {
  template: TemplateFunction<Params>;
  itemsData: AutocompleteItem[];
  templateName: AutocompleteItemType;
  itemToClickHandler: (item: AutocompleteItem) => MouseEventHandler<HTMLElement>;
  title?: string;
  query?: string;
  vehicleString?: string;
  totalHits?: number;
  browseAllButton?: TemplateFunctionInvoker<unknown>;
};

const Section: FC<Props> = ({
  template,
  title,
  query,
  itemsData,
  templateName,
  vehicleString,
  totalHits,
  itemToClickHandler = defaultItemToClickHandler,
  browseAllButton,
}) => {
  const items: RepeaterFunctionInvoker<unknown> = itemsData.map((item) => (templateFunc) => {
    const props = {
      templateFunc,
      item,
      query,
      template: templateName,
      onClick: itemToClickHandler(item),
      key: item.key,
    };
    return (<DropdownItem {...props} />) as TemplateResult;
  });

  return template.call({
    sectionTitle: title,
    query,
    items,
    vehicleString,
    totalHits,
    browseAllButton,
  });
};

export default Section;
