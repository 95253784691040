import requestConfig from 'Models/uiConfig/requestConfig.js';

export const isolatedHeader = 'isolatedHeader' as const;
export const isolatedDialog = 'isolatedDialog' as const;
export const isolatedVerifyFitment = 'isolatedVerifyFitment' as const;

const widgetNameToKeyMap = {
  DialogVehicleWidget: isolatedDialog,
  GarageVehicleWidget: isolatedDialog,
  HomeVehicleWidget: isolatedHeader,
  VerifyFitment: isolatedVerifyFitment,
};
export type IsolatedKey = typeof isolatedHeader | typeof isolatedDialog | typeof isolatedVerifyFitment | null;

export function getKeyFromWidgetName(name: string): IsolatedKey {
  const [nameRoot] = name.split('_');
  if (nameRoot === 'HeaderVehicleWidget') {
    return !requestConfig.hasSearchResults ? isolatedHeader : null;
  }
  return widgetNameToKeyMap[nameRoot] || null;
}
