import _round from 'lodash-es/round.js';

export type MathOps = 'round' | 'floor' | 'ceil';

export default class Step {
  numerator: number;
  denominator: number;
  accuracy: number;
  numeric: number;
  imperial: boolean;

  constructor(step: string | Step = '1/100') {
    if (step instanceof Step) {
      ({
        numerator: this.numerator,
        denominator: this.denominator,
        accuracy: this.accuracy,
        numeric: this.numeric,
        imperial: this.imperial,
      } = step);
      return;
    }
    let format = step;
    if (step === 'imperial') {
      format = '1/12//2';
      this.imperial = true;
    } else if (!format.includes('/')) {
      // '5'
      format += '/1//0';
    } else if (!format.includes('//')) {
      // '1/12'
      format += '//2';
    }
    [this.numerator, this.denominator, this.accuracy] = format
      .replace('//', '/')
      .split('/')
      .map((v) => +v);
    this.numeric = _round(this.numerator / this.denominator, this.accuracy);
  }

  fit(value: number, method: MathOps = 'round'): string {
    const fitNum =
      (Math[method]((value * this.denominator) / this.numerator) * this.numerator) / this.denominator;
    return fitNum.toFixed(this.accuracy);
  }
}
