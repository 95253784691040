import autocomplete from './autocomplete.ts';
import fitmentSearch from './fitmentSearch/index.ts';
import localStorage from './localStorage.js';
import redirect from './redirect.js';
import requestScroll from './requestScroll.js';
import retrySearch from './retrySearch.js';
import search from './search/index.ts';

export default [
  redirect,
  requestScroll,
  autocomplete,
  search,
  localStorage,
  retrySearch,
  fitmentSearch,
].flat();
