const termRegExps = {
  color: /^[a-fA-F\d]{6}[- ]\d{1,3}(\.\d+)?$/,
  ranged: /^(-?[\d.]+|\*)[- ]TO[- ](-?[\d.]+|\*)$/,
};

export function isTermColor(term: string): boolean {
  return termRegExps.color.test(term);
}

export function isTermRanged(term: string): boolean {
  return termRegExps.ranged.test(term);
}
