import { createSelector } from '@reduxjs/toolkit';

import {
  createFacetSelector,
  totalHitsSelector,
  searchResponseMessagesSelector,
} from 'Core/selectors/search.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import {
  createFitmentSearchRequestSelectionSelector,
  createVehicleFromUriSelector,
  selectedVehicleSelector,
  isVehicleSelectedSelector,
} from './index.js';

export const fitmentSearchSelector = (state) => state.fitmentSearch;

const selectedVehicleSelectionSelector = createSelector(
  selectedVehicleSelector,
  (selectedVehicle) => selectedVehicle.selection,
);

export const vehicleSelectionSelector = (state) => {
  const vehicleFromUriSelection = createVehicleFromUriSelector(state);
  if (vehicleFromUriSelection.length) {
    return vehicleFromUriSelection;
  }

  if (fitmentSearchConfig.isFitmentSearchWidgetsVisible) {
    if (isVehicleSelectedSelector(state)) {
      return selectedVehicleSelectionSelector(state);
    }

    return createFitmentSearchRequestSelectionSelector()(state);
  }

  return [];
};

export const isSpecifyDialogOpenedSelector = (state) => fitmentSearchSelector(state).isSpecifyDialogOpened;
export const isVehicleSelectionForcedSelector = (state) =>
  fitmentSearchSelector(state).isVehicleSelectionForced;
export const showVehicleDialogSelector = (state) => fitmentSearchSelector(state).showDialog;

const fieldsSelector = (state) => fitmentSearchSelector(state).fields;
export const fitmentSearchBaseFieldsSelector = (state) => fieldsSelector(state).base;
export const fitmentSearchExtraFieldsSelector = (state) => fieldsSelector(state).extra;
export const fitmentSearchFieldsSelector = createSelector(
  fitmentSearchBaseFieldsSelector,
  fitmentSearchExtraFieldsSelector,
  (base, extra) => [...base, ...extra],
);

export const isNoVehicleSpecificResultsSelector = createSelector(
  createFacetSelector('Universal'),
  createFacetSelector('Fitment'),
  totalHitsSelector,
  (universalFacet, fitmentFacet, totalHits) => {
    const isAllItemsUniversalTrue =
      universalFacet?.values.find((v) => v.term === 'True')?.hitCount === totalHits;

    const universalCount = fitmentFacet?.values.find((v) => v.term === 'Universal-Fit')?.hitCount || 0;
    const noFitmentDataCount = fitmentFacet?.values.find((v) => v.term === 'No-Fitment-Data')?.hitCount || 0;
    const nonVehicleProductsCount =
      fitmentFacet?.values.find((v) => v.term === 'Non-Vehicle-Products')?.hitCount || 0;

    const isAllUniversalOrNoData =
      universalCount + noFitmentDataCount + nonVehicleProductsCount === totalHits;

    return (isAllItemsUniversalTrue || isAllUniversalOrNoData) && totalHits > 0;
  },
);

export const isNonVehicleProductsMessageSelector = createSelector(
  searchResponseMessagesSelector,
  (messages) => messages.includes('NonVehicleProducts'),
);

export const notFilteredByVehicleSelector = (state) =>
  state.search.request.selection.some((v) => v.field === 'FilterByVehicle' && v.term === 'False');

export const fitmentTableResponse = (state) => fitmentSearchSelector(state).fitmentTableResponse;
