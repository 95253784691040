import SearchItemData from './searchItemData.js';

export default class SearchItemDataCollection {
  _items;

  constructor(responseItems = [], type = '') {
    this._items = responseItems.map((item, index) => new SearchItemData(item, type, index));
  }

  get length() {
    return this._items.length;
  }

  concat({ _items: newItems }) {
    if (!newItems.length) {
      return this;
    }

    const { _items: items } = this;

    if (items.length) {
      const [{ type }] = items;
      const [{ type: typeOfNew }] = newItems;
      if (type !== typeOfNew) {
        console.error('trying to concat SearchItemDataCollections with different types:', type, typeOfNew);
      }
    }

    const newCollection = new SearchItemDataCollection();
    newCollection._items = [...items, ...newItems];
    return newCollection;
  }

  equals({ _items: newItems }) {
    const { _items: items } = this;
    // order is important too
    return items.length === newItems.length && items.every(({ key }, i) => key === newItems[i].key);
  }

  indexOf(item) {
    return this._items.indexOf(item);
  }

  some(cb, thisArg) {
    return this._items.some(cb, thisArg);
  }

  repeaterComponents(Component) {
    return this._items.map((item) => (template) => {
      const props = { template, item, key: item.key };
      return <Component {...props} />;
    });
  }
}
