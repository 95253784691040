import { createReducer } from '@reduxjs/toolkit';
import { setResponse } from 'Core/actions/response.ts';
import { combineReducers } from 'redux';

import { fromPayload } from 'Core/reducers/common.ts';
import { clearAutocompleteQuery, setAutocompleteQuery } from 'Core/actions/autocomplete.ts';

export default combineReducers({
  query: createReducer('', (builder) =>
    builder
      .addCase(clearAutocompleteQuery, () => '')
      .addCase(setAutocompleteQuery, fromPayload)
      .addCase(setResponse, (_state, { payload: { Query } }) => Query),
  ),
});
