import { useCallback, useEffect, useRef, useState } from 'react';

import isMobile from 'Vendor/isMobile.js';

export default function useDropdownNav(rootRef, setVisibility) {
  const itemsRef = useRef([]);
  const [index, setIndex] = useState(-2);

  useEffect(function setRefs() {
    const root = rootRef.current;
    if (root) {
      itemsRef.current = root.querySelectorAll('.nav-item');
      itemsRef.current[-1] = root.querySelector('.nav-root');
    }
  });
  useEffect(
    function addEventListeners() {
      const root = rootRef.current;
      if (root) {
        const onFocusIn = ({ target }) => {
          setIndex([...itemsRef.current].indexOf(target));
        };
        root.addEventListener('focusin', onFocusIn);
        return () => root.removeEventListener('focusin', onFocusIn);
      }
    },
    [rootRef],
  );
  useEffect(
    function indexAndFocusSync() {
      const elem = itemsRef.current[index];
      if (elem) {
        itemsRef.current[index].focus();
      }
    },
    [index],
  );

  const getLastIndex = useCallback(() => itemsRef.current.length - 1, []);

  const next = useCallback((i) => {
    const nextIndex = i + 1;
    return nextIndex < itemsRef.current.length ? nextIndex : -1;
  }, []);

  const previous = useCallback(
    (i) => {
      const prevIndex = i - 1;
      return prevIndex > -2 ? prevIndex : getLastIndex();
    },
    [getLastIndex],
  );

  const keyboardHandler = useCallback(
    (e) => {
      if (!rootRef.current) {
        return;
      }
      const { key, target } = e;
      const indexGetter =
        {
          ArrowDown: next,
          ArrowUp: previous,
          End: homeEndHandler(target, getLastIndex),
          Escape: () => -1,
          Home: homeEndHandler(target, () => -1),
        }[key] || (() => null);

      const focusIndex = indexGetter(index);
      if (focusIndex !== null) {
        setVisibility(true);
        setIndex(focusIndex);
        // prevents scrolling
        e.preventDefault();
      }
    },
    [index, getLastIndex, next, previous, rootRef, setVisibility],
  );

  const resetNav = useCallback(() => {
    if (isMobile) {
      const focused = itemsRef.current[index];
      if (focused) {
        focused.blur();
      }
    } else {
      setIndex(-1);
    }
  }, [index]);

  return { keyboardHandler, resetNav };
}

const homeEndIgnoreInputTypes = ['text', 'search'];
function homeEndHandler({ type }, getter) {
  return homeEndIgnoreInputTypes.includes(type) ? () => null : getter;
}
