import uiConfig from 'Models/uiConfig/uiConfig.js';

export default function initialize(): void {
  const { body } = window.document;

  ['cm_body', `cm_${uiConfig.pageType}`].forEach((bodyClass) => body.classList.add(bodyClass));

  if (uiConfig.scrollDetection) {
    const { threshold, higher, lower } = uiConfig.scrollDetection;
    const handler = () => {
      const overThreshold = window.pageYOffset >= threshold;

      if (higher) {
        body.classList.toggle(higher, !overThreshold);
      }
      if (lower) {
        body.classList.toggle(lower, overThreshold);
      }
    };
    window.document.addEventListener('scroll', handler);
    handler();
  }
}
