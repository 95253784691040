import { createSelector } from '@reduxjs/toolkit';

import { searchResponseSelectionSelector } from 'Core/selectors/search.js';
import { FacetValue, WeightedColor } from 'Models/index.ts';

export function createSelectedColorSelector(color) {
  return createSelector(searchResponseSelectionSelector, (selection) => {
    const selectedValue = selection.find(
      (sel) => FacetValue.isColor(sel) && new WeightedColor(sel.term).color === color,
    );
    return selectedValue && new WeightedColor(selectedValue.term);
  });
}
