import { useSelector } from 'react-redux';

import uiConfig from 'Models/uiConfig/uiConfig.js';
import { keysSelector } from 'Modules/converter/index.js';

const {
  customMessage: { paramsKeys = [], visibleIf = () => false },
} = uiConfig;

export default function CustomMessage({ template }) {
  const visible = useSelector((state) => visibleIf(keysSelector(...paramsKeys)(state)));

  return visible ? template() : null;
}
