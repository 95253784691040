import pageType from 'Addons/search/pageType.ts';

export default {
  searchPageUrl: '/pages/search',
  page: { getPageType },
};

const shopifyToConvermaxPageTypeMap = {
  home: pageType.home,
  collection: pageType.category,
  vendors: pageType.brand,
  product: pageType.product,
};

function getPageType() {
  const sa = window.ShopifyAnalytics;
  if (sa) {
    return shopifyToConvermaxPageTypeMap[sa.meta.page.pageType] || pageType.unknown;
  }
  console.warn('window.ShopifyAnalytics not found');
  return pageType.unknown;
}

getPageType.defaults = { getter: getPageType };
