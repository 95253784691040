import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { havePreventedSearchChanged, waitingForAutocompleteChanged } from 'Core/actions/autocomplete.ts';
import { fromPayload } from 'Core/reducers/common.ts';

export default combineReducers({
  waitingForResponse: createReducer(false, (builder) =>
    builder.addCase(waitingForAutocompleteChanged, fromPayload),
  ),
  havePreventedSearch: createReducer(false, (builder) =>
    builder.addCase(havePreventedSearchChanged, fromPayload),
  ),
});
