import { useState, type FC } from 'react';
import { useDispatch } from 'react-redux';

import Select from 'Components/smallComponents/select';
import { assignLocation } from 'Core/actions/redirect';

import type { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type { TemplateFunction } from 'Components/types.ts';
import uiConfig from 'Models/uiConfig/uiConfig';

export type Params = {
  yearsSelect: (templ: TemplateFunction<SelectParams>) => JSX.Element;
  selectVehicle: (pathname?: string) => void;
};

type Props = {
  template: TemplateFunction<Params>;
  years: Record<string, string>;
};

const VehicleSelector: FC<Props> = ({ template, years }) => {
  const dispatch = useDispatch();

  const yearValues = Object.keys(years);

  const [selectedYearLink, setSelectedYearLink] = useState(Object.values(years)[0]);

  const yearsSelect = (templ: TemplateFunction<SelectParams>) => {
    const props = {
      template: templ,
      field: 'Year',
      title: 'Year',
      entries: yearValues.map((year) => ({
        value: year,
        term: year,
        selected: years[year] === selectedYearLink,
      })),
      key: `fitment-table-select-${yearValues[0]}-${yearValues.at(-1)}`,
      onChange: (year: string) => setSelectedYearLink(years[year]),
    };
    return <Select {...props} />;
  };

  const selectVehicle = () => dispatch(assignLocation(`${uiConfig.searchPageUrl}?${selectedYearLink}`));

  return template.call({ yearsSelect, selectVehicle });
};

export default VehicleSelector;
