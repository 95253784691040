import _forIn from 'lodash-es/forIn.js';
import isMobile from 'Vendor/isMobile.js';

function alterBodyStyle() {
  const { document, document: { body } = {} } = window || {};
  if (body) {
    body.classList.add(isMobile ? 'cm_mobile' : 'cm_desktop');
  }
}

function injectCss() {
  const { document } = window;

  const cssStyleElement = document.createElement('style');
  cssStyleElement.innerHTML = '.cm_hide { display: none }';
  document.getElementsByTagName('head')[0].appendChild(cssStyleElement);

  const jsUrl =
    document.currentScript instanceof HTMLScriptElement
      ? document.currentScript.src
      : 'https://localhost:3000/no-current-script-src-detected.js';

  const cssUrl = jsUrl.replace(/(\.min)?\.js(\?.*)?$/, '.css$2');

  const selectors = [
    'link[href*=".css"][href*="search"][href*="convermax.com"]',
    'link[href*="convermax.css"]',
  ];

  const existingCssSelector = selectors.join(', ');
  if (document.querySelector(existingCssSelector)) {
    return;
  }

  const cssLinkElement = document.createElement('link');

  _forIn({ rel: 'stylesheet', type: 'text/css', href: cssUrl }, (value, name) =>
    cssLinkElement.setAttribute(name, value),
  );

  const cssOverrideElement = document.querySelector('link[data-cm-override]');
  const { parentElement } = cssOverrideElement || {};
  if (cssOverrideElement && parentElement) {
    parentElement.insertBefore(cssLinkElement, cssOverrideElement);
  } else {
    document.getElementsByTagName('head')[0].appendChild(cssLinkElement);
  }
}

alterBodyStyle();
injectCss();
