import { cloneSafe } from 'Utils/components.ts';
import { getParams, getImageOrDefault } from './tile.tsx';

import type { FC } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker } from 'Components/types.ts';
import type { FacetRequest, FacetValueFull } from 'Models/index.ts';
import type { SubvalueParams } from './tile.tsx';

type Props = {
  template: TemplateFunction<SubvalueParams>;
  subvalue: FacetValueFull;
  vehicleFromUriSelection: FacetRequest[];
  isMultiLevel?: boolean;
  vehicleWidget?: TemplateFunctionInvoker<unknown>;
};

const Subtile: FC<Props> = ({ template, subvalue, isMultiLevel, vehicleFromUriSelection, vehicleWidget }) => {
  const component = template.call({
    ...getParams(subvalue, vehicleFromUriSelection),
    isMultiLevel,
    getImageOrDefault,
    vehicleWidget,
  });

  return cloneSafe(component, null);
};

export default Subtile;
