import RangedListFacet from './rangedListFacet.tsx';
import RangedSliderFacet from './rangedSliderFacet.tsx';

import type { FC } from 'react';
import type { FacetCommonProps } from '../baseFacet.js';
import { DisplayMode } from '../../../modules/serverApi/types.ts';

const RangedFacet: FC<FacetCommonProps> = ({ facet, ...props }) => {
  if (facet.displayMode === DisplayMode.Slider) {
    return <RangedSliderFacet facet={facet} {...props} />;
  } else if (facet.displayMode === DisplayMode.Ranges) {
    return <RangedListFacet facet={facet} {...props} />;
  }

  return null;
};

export default RangedFacet;
