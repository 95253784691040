export enum CustomErrorType {
  failedToFetch = 'Failed to fetch',
  requestAborted = 'Request Aborted',
  requestTimeout = 'Request Timeout',
  unknown = 'Unknown',
}

export default class CustomError extends Error {
  type: CustomErrorType;
  status?: number;

  constructor(message: string, params: { error?: Error; status?: number } = {}) {
    super(message);

    const { error, status } = params;

    const errorName = error?.name;

    this.status = status;

    this.type = message.includes('request timeout')
      ? CustomErrorType.requestTimeout
      : error
        ? errorName == 'AbortError'
          ? CustomErrorType.requestAborted
          : message.includes('request timeout')
            ? CustomErrorType.requestTimeout
            : error.message === 'Failed to fetch'
              ? CustomErrorType.failedToFetch
              : CustomErrorType.unknown
        : CustomErrorType.unknown;
  }
}
