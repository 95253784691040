import { configureStore } from '@reduxjs/toolkit';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import createEpicsMiddleware from 'Core/middlewares/epics.ts';
import epics from 'Core/epics/index.js';
import reducer from './reducers/index.ts';

import type { Selector as GenericStateSelector, Store } from '@reduxjs/toolkit';

const store = configureStore({
  reducer,
  // TODO: get rid of non-serializable values in the state and use getDefaultMiddleware().
  //  Then we'll be able to exclude explicit notion of thunk and redux-freeze.
  //  The middleware that will crush the client is the "Serializability check middleware".
  // https://redux-toolkit.js.org/api/getDefaultMiddleware#development
  middleware: () =>
    [promise, thunk, createEpicsMiddleware(epics), CONVERMAX_IS_DEV_BUILD && require('redux-freeze')].filter(
      Boolean,
    ),
  devTools: CONVERMAX_IS_DEV_BUILD,
});

export default store;

export type ReduxStateType = ReturnType<typeof reducer>;

export type ReduxStoreType = Store<ReduxStateType>;

export type Selector<R> = GenericStateSelector<ReduxStateType, R>;
