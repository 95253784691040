import { useSelector } from 'react-redux';

import { names } from 'Addons';
import { default as FacetPanel } from 'Components/facetPanel/index.ts';
import { FacetDialogName } from 'Components/facetPanel/common/index.ts';
import VehicleWidget from 'Components/fitmentSearch/vehicleWidget/vehicleWidget';
import SortSelect from 'Components/searchHeader/sortSelect';
import { usePopup } from 'Core/hooks/index.js';
import { createFitmentSearchExtraFieldsSelector } from 'Core/selectors/fitmentSearch/index.js';
import { totalHitsSelector } from 'Core/selectors/search.js';

import type { FC } from 'react';
import type { TemplateFunction, TemplateResult } from 'Components/types.ts';
import type { Props as FacetPanelProps } from 'Components/facetPanel/facetPanel.tsx';
import type { Params as VehicleWidgetParams } from 'Components/fitmentSearch/vehicleWidget/vehicleDropdowns.tsx';

const FacetDialog: FC<FacetPanelProps> = (props) => {
  const { opened, close } = usePopup(FacetDialogName);
  const totalHits = useSelector(totalHitsSelector);
  const extraFitmentFields = useSelector(createFitmentSearchExtraFieldsSelector());

  if (!opened) {
    return null;
  }

  const vehicleSpecs = (template: TemplateFunction<VehicleWidgetParams>) => {
    const vehicleSpecsProps = {
      template,
      name: 'VehicleSpecs_facet-dialog',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      fields: extraFitmentFields,
      columnBreakpoint: undefined, // to avoid TS-JS problem
    };
    return <VehicleWidget {...vehicleSpecsProps} />;
  };

  const sortSelect = (
    <SortSelect className="cm_sort-select__dialog" sortEntries={props.sortEntries} key="sort" />
  );

  const facetPanelProps = {
    ...props,
    name: FacetDialogName,
    initCollapsed: props.initCollapsed ?? true,
    showAllAlways: true as const,
    extraParams: {
      opened,
      close,
      totalHits,
      vehicleSpecs,
      sortSelect,
      withColorSearch: names.includes('colorSearch'),
    },
  };

  return (<FacetPanel {...facetPanelProps} />) as TemplateResult;
};

export default FacetDialog;
